<template>
  <div class="spendings">
    <div class="info-container">
      <info-block class="item" borderless>
        <template #title>$ 43.543,93</template>
        <template #descr>last 30 days</template>
      </info-block>
      <info-block class="item" borderless>
        <template #title>$ 34,99</template>
        <template #descr>today</template>
      </info-block>
    </div>

    <finances-chart class="chart-block" :chartData="[]"/>
  </div>
</template>

<script>

// import { mapGetters, mapActions } from "vuex";
import FinancesChart from "@/components/Charts/Finances"

export default {
  components: { FinancesChart },
  created() {
    // this.changeParams({
    //   device_group_uid: this.$route.params.id
    // });
    // this.fetchEarnings();
  },
  methods: {
    // ...mapActions({
    //   fetchEarnings: "gatewayGroupStat/finances/fetch",
    //   changeParams: "dashboard/finances/changeParams",
    // }),
  },
  computed: {
    financesDashData() {
      return [];
    }

    // ...mapGetters({
    //   financesDashData: "dashboard/finances/data",
    // })
  }
}
</script>

<style scoped lang="scss">

.spendings {
  display: flex;
  // margin-bottom: 14px;

  .info-container {
    max-width: 100px;
    margin-right: 8px;
    .item {
      margin-bottom: 5px;
    }
  }

  .chart-block {
    width: 100%;
    min-height: 110px;
  }
}

</style>
