export default class DeviceModel {
  constructor(obj) {
    this.id = null;
    this.blockchain_uid = null;
    this.hash = null;
    this.manufacturer = null;
    this.owner_key = null;
    this.device_group_uid = null;
    this.created_at = null;
    this.updated_at = null;
    this.device_group = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      hash: String(this.hash),
      manufacturer: String(this.manufacturer),
      device_group_uid: String(this.device_group_uid),
      status: Number(this.status)
    };
  }
}
