<template>
  <highcharts
    v-waypoint="wayPointConfig"
    :options="config"
    ref="chart"
    class="inline-graph"
  />
</template>

<script>
import Moment from "moment";
import Axios from "axios";
import DeviceGroupsApi from "@/api/device_groups";
import { lineConfigInline } from "@/helpers/chartConfigs.js";

import { mapActions } from "vuex";
import { debounce } from "debounce";

export default {
  props: {
    deviceGroupUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      initialized: false,
      CancelTokenSource: null,
      requestUID: Math.random().toString(36).substring(7),
      config: {
        ...lineConfigInline,
        ...{
          chart: {
            width: 90,
            ...lineConfigInline.chart,
          },
        },
        series: [
          {
            type: "spline",
            enableMouseTracking: false,
            data: [],
          },
        ],
      },
      wayPointConfig: {
        active: true,
        callback: debounce(this.onWaypoint, 200),
        callback: this.onWaypoint,
        options: {
          intersectionOptions: {
            root: null,
            rootMargin: "0px 0px 0px 0px",
            threshold: [0.4, 0.6], // [0.25, 0.75] if you want a 25% offset!
          }, // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API,
        },
      },
    };
  },

  methods: {
    ...mapActions({
      pushError: "errors/pushError",
    }),

    drawPoints(data, xKey, yKey) {
      for (let dataItem of data) {
        this.$refs.chart.chart.series[0].addPoint({
          x: parseInt(Moment(dataItem[xKey]).format("x")),
          y: parseInt(dataItem[yKey]),
        });
      }
    },

    init() {
      if (this.initialized) {
        return;
      }

      const params = {
        id: this.deviceGroupUid,
        from: Moment().subtract(1, "week").startOf("day").toISOString(),
        to: Moment().toISOString(),
        detalisation: "5hours",
      };

      const CancelToken = Axios.CancelToken;
      this.CancelTokenSource = CancelToken.source();

      DeviceGroupsApi.getChart2(params, this.CancelTokenSource)
        .then(({ data }) => {
          this.drawPoints(data, "from", "count");

          this.initialized = true;
        })
        .catch((error) => {
          this.pushError({ id: "inline-chart-error", error: error.message });
        });
    },

    onWaypoint: function ({ going, direction }) {
      if (going === "in") {
        this.init();
      } else if (going === "out") {
        if (this.CancelTokenSource) {
          this.CancelTokenSource.cancel();
        }
      }
    },
  },

  // beforeUpdate() {
  //   this.updateGraphColor();
  // },

  // async mounted() {},

  // beforeDestroy() {
  // this.CancelTokenSource.cancel();
  // },
};
</script>

<style lang="scss" scoped>
.inline-graph {
  height: 30px;
  position: absolute;
}
</style>
