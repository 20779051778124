export const mixin = {
  data() {
    return {
      table: {
        gridTemplate: [
          ".7fr",
          "2.5fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          ".5fr",
          "1fr",
          "1fr"
        ],
        columns: [
          { key: "status", title: "On/Off", sortable: true },
          {
            key: "name",
            title: "Group name",
            type: "bold",
            sortable: true,
            bold: true
          },
          { key: "confirmed", title: "Confirmed", sortable: true },
          { key: "data", title: "Data" },
          { key: "group_type", title: "Group type", sortable: true },
          { key: "active_devices", title: "Active sensors", sortable: true }, //sorting not implemented yet
          {
            key: "inactive_devices",
            title: "Inactive sensors",
            sortable: true //sorting not implemented yet
          },
          { key: "data_month", title: "Data /mo", sortable: true }, //sorting not implemented yet
          { key: "spent_month", title: "Spent /mo", sortable: true }, //sorting not implemented yet
          { key: "low_battery", icon: "battery", sortable: true }, //sorting not implemented yet
          { key: "updated_at", title: "Last modified", sortable: true },
          { key: "action" }
        ],
        data: []
      },
    }
  }
}
