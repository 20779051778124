<template>
  <div class="blocks-grid" v-if="gatewayGroup">
    <info-block borderless>
      <template #title>{{ formatDate(gatewayGroup.created_at) }}</template>
      <template #descr>Group added</template>
    </info-block>

    <info-block borderless>
      <template #title>{{ formatDate(gatewayGroup.updated_at) }}</template>
      <template #descr>Last modified</template>
    </info-block>

    <div />
    <info-block borderless>
      <template #title>
        <div class="group-type-title">
          <div class="static-title">
            {{ getGateGroupType(gatewayGroup.group_type) }}
          </div>
        </div>
      </template>
      <template #descr>Group type</template>
    </info-block>
  </div>
</template>

<script>
import Moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      settings: "constants/settings",
      gatewayGroup: "gatewayGroup/item",
    }),
  },
  methods: {
    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY");
    },
    getGateGroupType(gateGroupId) {
      if (!gateGroupId) return "Wrong type";

      const { types } = this.settings.gatewayGroups;

      let gateGroupType = Object.entries(types).find(
        (item) => item[1].id == gateGroupId
      );

      return gateGroupType
        ? Object.entries(gateGroupType)[1][1].name
        : "Wrong type";
    },
  },
};
</script>

<style lang="scss">
.group-type-title {
  .static-title {
    display: flex;
    align-items: center;
  }

  .type-selector {
    display: flex;
    align-items: center;
    .input {
      min-width: 150px;
      max-width: 150px;
    }

    .cancel-button {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>
