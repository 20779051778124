<template>
  <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.571 0H1.98966C1.25917 0 0.666992 0.596954 0.666992 1.33333V6.66667C0.666992 7.40305 1.25917 8 1.98966 8H12.571C13.3015 8 13.8937 7.40305 13.8937 6.66667V5.86409C14.483 5.64696 14.8886 5.19234 14.8886 4.66667V3.33333C14.8886 2.80766 14.483 2.35304 13.8937 2.13591V1.33333C13.8937 0.596954 13.3015 0 12.571 0ZM2.44466 6.66667V1.33333H11.682V6.66667H2.44466ZM5.1811 2.04926H3.40332V6.17424H5.1811V2.04926Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "battery-low-icon",
  props: {
    fill: {
      default: "#6B6E8D",
      type: String
    }
  }
};
</script>
