<template>
  <div class="logout-container">
    <div class="logout">
      <h3>Signing out...</h3>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "logout-view",

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },

  mounted() {
    this.logout().then(() => {
      this.$router.push({
        name: "redirect-to-sign-in",
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.logout-container {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  .logout {
    box-shadow: $defaultShadow;
    border-radius: 6px;
    width: 300px;
    height: 200px;
    display: flex;
    background: $white;
    align-items: center;
    justify-content: center;

    h3 {
      @include h3;
      color: $notBlack;
    }
  }
}
</style>
