<template>
  <div class="blocks-grid" v-if="battery">
    <info-block borderless>
      <template #title>
        <battery-full-icon class="inline-icon" />
        {{ battery.full }}
      </template>
    </info-block>
    <info-block borderless>
      <template #title>
        <battery-medium-icon class="inline-icon" />
        {{ battery.good }}
      </template>
    </info-block>
    <info-block borderless tint="red">
      <template #title>
        <battery-low-icon class="inline-icon" fill="currentColor" />
        {{ battery.empty }}
      </template>
    </info-block>
    <info-block borderless tint="red">
      <template #title>
        <battery-empty-icon class="inline-icon" fill="currentColor" />
        {{ battery.dead }}
      </template>
    </info-block>
    <info-block borderless tint="red">
      <template #title>
        <battery-no-data class="inline-icon" fill="currentColor" />
        {{ battery.noData }}
      </template>
    </info-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BatteryFullIcon from "@/components/Icons/BatteryFull";
import BatteryMediumIcon from "@/components/Icons/BatteryMedium";
import BatteryLowIcon from "@/components/Icons/BatteryLow";
import BatteryEmptyIcon from "@/components/Icons/BatteryEmpty";
import BatteryNoData from "@/components/Icons/BatteryNoData";

export default {
  components: {
    BatteryFullIcon,
    BatteryMediumIcon,
    BatteryLowIcon,
    BatteryEmptyIcon,
    BatteryNoData,
  },
  async created() {
    const deviceGroupUid = this.$route.params.id;

    try {
      await this.getBattery({ id: deviceGroupUid });
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000,
      });
    }
  },
  computed: {
    ...mapGetters({
      battery: "deviceGroupStat/battery/item",
    }),
  },
  methods: {
    ...mapActions({
      getBattery: "deviceGroupStat/battery/getItem",
    }),
  },
};
</script>

<style scoped lang="scss">
.inline-icon {
  margin-right: 4px;
}

.blocks-grid {
  grid-template-columns: repeat(5, 1fr) !important;
}
</style>
