<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4273 4C16.0316 4 16.5551 4.2394 16.9751 4.66268L19.3374 7.02501C19.761 7.4486 19.9982 7.96841 19.9982 8.57092C19.9982 9.16954 19.7628 9.79054 19.3374 10.216L10.7309 18.8184C10.1326 19.5086 9.28442 19.9326 8.29255 20H5.14273H4V18.8573L4.00371 15.6155C4.07654 14.714 4.49564 13.8758 5.12212 13.3246L13.7828 4.66394C14.2078 4.23675 14.8278 4 15.4273 4ZM6.68721 14.9886C6.45232 15.1963 6.307 15.487 6.28546 15.7075V17.7156L8.21186 17.7174C8.518 17.6957 8.80297 17.5532 9.05943 17.2618L14.0667 12.2546L11.7449 9.93274L6.68721 14.9886ZM13.3612 8.31696L15.6828 10.6385L17.7008 8.6205L15.3796 6.29932L13.3612 8.31696Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "pencil-icon",
  props: {
    fill: {
      default: "#474B6D",
      type: String
    }
  }
};
</script>
