import Request from '@/helpers/request';

export default class AccountApi {
  static updatePersonalInfo(data) {
    return Request.client.put("/account/personal_info", data);
  }

  static getNotifications() {
    return Request.client.get("/account/notifications");
  }

  static updateNotifications(data) {
    return Request.client.put("/account/notifications", data);
  }

  static updateSecurity(data) {
    return Request.client.put("/account/security", data);
  }
}
