<template>
  <div class="sub-header">
    <div class="services-sub-header" v-if="edit">
      <span class="sub-header-item title">{{ (service.new) ? 'Create service' : 'Edit service' }}</span>

      <span class="sub-header-item remove" @click="$emit('remove')" v-if="!service.new">
        <remove-icon class="remove-icon" />Delete service
      </span>
    </div>
  </div>
</template>
<script>
import RemoveIcon from "@/components/Icons/Remove";
export default {
  components: {
    RemoveIcon
  },

  props: {
    service: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.services-sub-header {
  // background: blue;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .sub-header-item {
    display: flex;
    align-items: center;

    &.title {
      font-weight: 500;
    }

    &.remove {
      cursor: pointer;
      color: $accentRed;
    }

    .remove-icon {
      margin-right: 4px;
      width: 20px;
    }
  }
}
</style>