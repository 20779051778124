<template>
  <div class="characteristic" v-if="characteristic">
    <name
      v-bind="{value: characteristic.name, edit}"
      @input="nameChanged"
      @remove="$emit('remove')"
    />

    <tags v-bind="{value: characteristic, edit}" @input="tagsChanged" />
  </div>
</template>

<script>
import Name from "./Name";
import Tags from "./Tags";

export default {
  components: { Name, Tags },
  props: ["value", "edit"],
  data() {
    return {
      characteristic: JSON.parse(JSON.stringify(this.value))
    };
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.reset();
      }
    }
  },

  methods: {
    reset() {
      this.characteristic = JSON.parse(JSON.stringify(this.value));
    },

    nameChanged(name) {
      this.characteristic.name = name;
      this.$emit("input", this.characteristic);
    },

    tagsChanged(tags) {
      this.characteristic.tags = tags;
      this.$emit("input", this.characteristic);
    }
  }
};
</script>



<style lang="scss" scoped>
.characteristic {
  margin-top: 16px;
}
</style>
