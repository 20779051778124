<template>
  <div class="wrapper">
    <moe-block v-bind="{ loading }" heading="Deposit" class="deposit-block">
      <template #content>
        <info-block class="balance-block">
          <template #title>$ {{ balance }}</template>
          <template #descr>Account Balance</template>
          <template #container>
            <!-- :primary="false" -->

            <button-input class="deposit-button" @click.native="open">
              Go to payment
            </button-input>
          </template>
        </info-block>
      </template>
    </moe-block>

    <DepositFunds v-bind="popup" @close="close" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DepositFunds from "./DepositFunds";

export default {
  name: "payout-now-block",

  components: {
    DepositFunds,
  },

  data() {
    return {
      value: "",
      stripeKey: process.env.VUE_APP_STRIPE_PK,

      popup: {
        opened: false,
      },

      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      data: "dashboard/finances/data",
    }),

    balance() {
      if (this.data && this.data[0]) {
        let balance = this.data[0].accountBalance;
        return balance ? balance : 0;
      }

      return 0;
    },
  },

  methods: {
    open() {
      this.popup.opened = true;
    },

    close() {
      this.popup.opened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit-button {
  width: 200px !important;
}
</style>
