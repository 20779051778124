import DeviceGroupsStatApi from "@/api/device_groups_stat";

export default {
  namespaced: true,
  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async getItem(ctx, params) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);

      try {
        const id = params.id;
        const result = await DeviceGroupsStatApi.getActivityStat({ id });
        // const result = await DeviceGroupsStatApi.getItem(params);
        ctx.commit("setItem", result.data[0]);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "device_group_activity", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data
    },
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item
  }
};
