import Request from "@/helpers/request";

export default class ConstantsApi {
  static getList(query) {
    return Request.client.get("/constants", {
      params: ConstantsApi.prepareParams(query)
    });
  }

  static prepareParams(params) {

    if (!params) {
      return {}
    }

    return {
      __order: (params.orderBy) ? `${params.orderBy.value} ${params.orderBy.desc ? "desc" : "asc"}` : null,
      __count: (params.limit) ? `${params.limit.count}` : null,
      __offset: (params.limit) ? `${params.limit.offset}` : null,
    };
  }
}
