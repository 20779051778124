<template>
  <div class="welcome-screen-container">
    <div
      class="welcome-screen"
      :class="{ dragover }"
      @drop.prevent="onDrop"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <div class="inner">
        <h1 class="header">Drag and Drop files here</h1>
        <p class="sub-header">or</p>

        <button-input
          :primary="true"
          :disabled="disabled"
          @click.native="addBtnClicked"
        >
          <plus-icon slot="icon" fill="currentColor" />
          <template>{{ title }}</template>
          <import @import="onImport" ref="import" :type="importType" />
        </button-input>

        <p>Supported files: {{ importType == "csv" ? "CSV" : "JSON" }}</p>
      </div>
    </div>

    <button-input
      v-if="altTitle"
      :primary="false"
      :disabled="disabled"
      @click.native="altAddBtnClicked"
    >
      <plus-icon slot="icon" fill="currentColor" />
      <template>{{ altTitle }}</template>
    </button-input>
  </div>
</template>

<script>
import PlusIcon from "@/components/Icons/Plus";
import Import from "@/components/Inputs/Import";

export default {
  components: { Import, PlusIcon },

  props: {
    title: {
      type: String,
      required: true,
    },

    altTitle: {
      type: String,
    },

    importType: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      dragover: false,
    };
  },

  methods: {
    addBtnClicked() {
      this.$refs.import.$refs.uploadFileInput.click();
    },

    altAddBtnClicked() {
      this.$emit("alt-button-click");
    },

    onImport(formData) {
      this.$emit("import", formData);
    },

    onDrop(ev) {
      ev.dataTransfer.files[0]
        .text()
        .then((result) => {
          this.$emit("import", JSON.parse(result));
        })
        .catch(() => {
          this.$toast.error("File format is wrong!");
        });

      this.dragover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-screen-container {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 80px;

  .welcome-screen {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 500px;
    height: 300px;
    border-radius: 6px;
    border: 3px dashed $grey50;

    margin-bottom: 20px;

    &.dragover {
      box-shadow: 0px 0px 17px 1px #eeeff8;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > * {
        margin: 5px 0;
      }

      .header {
        color: $notBlack;
        font-size: 24px;
      }

      .sub-header {
        color: $grey70;
      }

      &:last-child {
        color: $grey70;
      }
    }
  }
}
</style>
