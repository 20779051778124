<template>
  <div :key="requestUID" v-waypoint="wayPointConfig">{{ lowBatteryCount }}</div>
</template>

<script>
import Axios from "axios";
import DeviceGroupsStatApi from "@/api/device_groups_stat";

export default {
  props: {
    deviceGroupUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      lowBatteryCount: 0,
      requestUID: Math.random().toString(36).substring(7),

      initialized: false,

      CancelTokenSource: null,

      wayPointConfig: {
        active: true,
        callback: this.onWaypoint,
        options: {
          intersectionOptions: {
            root: null,
            rootMargin: "0px 0px 0px 0px",
            threshold: [0.4, 0.6],
          },
        },
      },
    };
  },

  methods: {
    init() {
      if (this.initialized) {
        return;
      }

      const params = {
        id: this.deviceGroupUid,
      };

      const CancelToken = Axios.CancelToken;
      this.CancelTokenSource = CancelToken.source();

      DeviceGroupsStatApi.getBatteryStat(params, this.CancelTokenSource)
        .then(({ data }) => {
          this.lowBatteryCount = data[0].dead;
          this.initialized = true;
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    },

    onWaypoint: function ({ going, direction }) {
      if (going === "in") {
        this.init();
      } else if (going === "out") {
        if (this.CancelTokenSource) {
          this.CancelTokenSource.cancel();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
