<template>
  <div>
    <highcharts :options="config" ref="chart" class="inline-graph" />
  </div>
</template>

<script>

import Moment from "moment";
import Axios from "axios"
import GatewayGroupsApi from "@/api/gateway_groups";
import { lineConfigInline } from "@/helpers/chartConfigs.js";

export default {
  props: {
    gatewayGroupUid: {
      type: String,
      required: true
    },

    data: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    lineColor() {
      if (this.disabled) {
        return "#CED0E4";
      } else if (this.tint == "red") {
        return "#DE3C63";
      } else if (this.tint == "green") {
        return "#37CB95";
      } else {
        return "#555FB9";
      }
    },

    formattedValue() {
      return this.value.toLocaleString("en");
    }
  },

  data() {
    return {
      CancelTokenSource: null,
      requestUID: Math.random().toString(36).substring(7),
      config: {
        ...lineConfigInline,
        ...{
          chart: {
            width: 75,
            ...lineConfigInline.chart
          }
        },
        series: [
          {
            type: "spline",
            enableMouseTracking: false,
            data: []
          }
        ]
      }
    };
  },

  methods: {
    drawPoints(data, xKey, yKey) {
      for (let dataItem of data) {
        this.$refs.chart.chart.series[0].addPoint({
          x: parseInt(Moment(dataItem[xKey]).format('x')),
          y: parseInt(dataItem[yKey])
        });
      }
    }
  },

  async mounted() {
    const CancelToken = Axios.CancelToken;
    this.CancelTokenSource = CancelToken.source();

    const params = {
      id: this.gatewayGroupUid,
      from: Moment().subtract(1, 'week').startOf('day').toISOString(),
      to: Moment().toISOString(),
      detalisation: '5hours'
    };

    GatewayGroupsApi.getChart(params, this.CancelTokenSource)
    .then(({ data }) => {
      this.drawPoints(data, 'from', 'count')
    });
  },

  beforeUpdate() {
    this.updateGraphColor();
  },

  beforeDestroy() {
    const msg = 'Component is destroyed; Canceling GET /graph api request: ' + this.requestUID;
    this.CancelTokenSource.cancel(msg);
  }
};
</script>

<style lang="scss" scoped>

.inline-graph {
  height: 20px;
  position: absolute;
}

</style>
