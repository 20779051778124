<template>
  <div class="devices-table-heading" v-if="deviceGroup">
    <faucet-filter
      v-bind="addTagsPopUp"
      v-on:close="closeAddTagsPopUp"
      v-on:apply-filters="applyTags"
      v-on:toggle-filter="toggleAddTagsItem"
      v-on:disable-filter="disableTags"
    >
      <template #header-title>Add tags</template>

      <template #actions>
        <div class="add-tags-actions">
          <button-input
            class="action"
            :primary="false"
            :disabled="isDisabled"
            @click.native="onCreateTagClick"
          >
            <span>Create new tag</span>
          </button-input>

          <button-input
            class="action"
            @click.native="onAddTagsClick"
            :disabled="!addTagsPopUp.selected.length || isDisabled"
          >
            <span>Add tags</span>
          </button-input>
        </div>
      </template>
    </faucet-filter>

    <move-devices
      v-bind="moveDevices"
      :deviceGroups="deviceGroups"
      @close="closeMoveDevices"
      @move="onMove"
    ></move-devices>

    <update-devices
      v-bind="updateDevices"
      :deviceGroups="deviceGroups"
      @close="closeUpdateDevices"
      @update="onUpdate"
    ></update-devices>

    <faucet-filter
      v-bind="filter"
      v-on:close="closeFilter"
      v-on:apply-filters="applyFilters"
      v-on:toggle-filter="toggleFilter"
      v-on:disable-filter="disableFilter"
    >
      <template #header-title>Filter by tag</template>
    </faucet-filter>

    <!-- <search-input class="search-input" ref="search" @search="onSearch" /> -->

    <div class="actions">
      <search-input class="search-input" ref="search" @search="onSearch" />

      <div v-if="showInteraction" class="interaction-actions">
        <button-input
          linear
          class="filter-button"
          :class="{ active: filter.opened, enabled: filter.applied.length }"
          @click.native="openMoveDevices"
          v-title="`Move sensors to other group`"
          title-placement="bottom"
          ref="moveDevices"
        >
          <move-tag-icon slot="icon" fill="currentColor" />
          <template #default>
            <span v-if="filter.applied.length">{{ filter.applied.length }}</span>
          </template>
        </button-input>
      </div>

      <div v-if="showInteraction" class="interaction-actions">
        <button-input
          linear
          class="filter-button"
          :class="{ active: filter.opened, enabled: filter.applied.length }"
          @click.native="openUpdateDevices"
          v-title="`Update downlinks`"
          title-placement="bottom"
          ref="updateDevices"
        >
          <pencil-icon slot="icon" fill="currentColor" />
          <template #default>
            <span v-if="filter.applied.length">{{ filter.applied.length }}</span>
          </template>
        </button-input>
      </div>
    </div>

    <div class="default-actions">
      <select-input
        class="period-filter"
        placeholder="Select period"
        v-model="period.value"
        :options="period.options"
        :searchable="false"
        @input="onPeriodChanged"
      >
        <template slot="single-label" slot-scope="props">
          <calendar-icon class="calendar" />
          <span class="option__desc">
            <span class="option__title">{{ props.option }}</span>
          </span>
        </template>
      </select-input>
    </div>
  </div>
</template>

<script>
import MoveTagIcon from "@/components/Icons/MoveTag";
import CalendarIcon from "@/components/Icons/Calendar";
import PencilIcon from "@/components/Icons/Pencil";
import FaucetFilter from "@/components/Popups/Filters/FaucetFilter";
import MoveDevices from "@/components/Popups/MoveDevices";
import UpdateDevices from "@/components/Popups/UpdateDevices";

import { mapActions, mapGetters } from "vuex";
import { defaultPeriods, periodToISOString } from "@/helpers/timePeriods";

export default {
  name: "devices-table-heading",

  components: {
    MoveTagIcon,
    CalendarIcon,
    PencilIcon,
    FaucetFilter,
    MoveDevices,
    UpdateDevices,
  },

  props: {
    showInteraction: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      deviceGroup: "deviceGroup/item",
      deviceGroups: "deviceGroups/list",
      params: "devices/params",
    }),

    isDisabled() {
      if (!this.deviceGroup) {
        return false;
      }

      // Disabled if not confirmed
      return this.deviceGroup.confirmed != 1;
    },
  },

  async mounted() {
    if (!this.deviceGroups.length) {
      try {
        await this.fetchDeviceGroups();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    }
    this.setHashToSearch(this.params.hash);
  },

  methods: {
    ...mapActions({
      fetchDeviceGroups: "deviceGroups/getList",
      getList: "devices/getLazyList",
      resetLazyList: "devices/resetLazyList",
      changeParams: "devices/changeParams",
    }),

    async fetch() {
      try {
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onSearch(hash) {
      this.resetLazyList();
      this.changeParams({ hash });
      this.fetch();
    },

    onPeriodChanged(periodValue) {
      let { periodStart, periodEnd } = periodToISOString(periodValue);

      if (periodStart) {
        this.resetLazyList();
        this.changeParams({
          period_start: periodStart,
          period_end: periodEnd,
        });
        this.fetch();
      }
    },

    // ========== tags =============
    onCreateTagClick() {
      const name = prompt("Enter tag name");
      const length = this.addTagsPopUp.options.length;
      const lastId = this.addTagsPopUp.options[length - 1].id;
      this.addTagsPopUp.options.push({
        id: lastId + 1,
        name,
        selected: false,
      });
    },

    onAddTagsClick() {
      // console.log("add selected tags");
    },

    openAddTags(ev) {
      Object.assign(this.addTagsPopUp, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    toggleAddTagsItem(filter) {
      filter.selected = !filter.selected;
      if (filter.selected) {
        this.addTagsPopUp.selected.push(filter);
      } else {
        const indx = this.addTagsPopUp.selected.findIndex(
          (el) => el.id == filter.id
        );
        this.addTagsPopUp.selected.splice(indx, 1);
      }
    },

    closeAddTagsPopUp() {
      this.addTagsPopUp.opened = false;
    },

    applyTags() {
      this.addTagsPopUp.applied = this.addTagsPopUp.options.filter(
        (o) => o.selected
      );
    },

    disableTags() {
      this.filter.selected = false;
      this.applyFilters();
    },

    // =============== move devices

    openMoveDevices(ev) {
      Object.assign(this.moveDevices, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    onMove(deviceGroup) {
      this.closeMoveDevices();
      this.$emit("move", deviceGroup);
    },

    closeMoveDevices() {
      this.moveDevices.opened = false;
    },

    // =============== update devices

    openUpdateDevices(ev) {
      Object.assign(this.updateDevices, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    onUpdate(params) {
      this.closeUpdateDevices();
      this.$emit("update", params);
    },

    closeUpdateDevices() {
      this.updateDevices.opened = false;
    },

    // ======================================
    openFilter(ev) {
      Object.assign(this.filter, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    closeFilter() {
      this.filter.opened = false;
    },

    applyFilters() {
      this.filter.applied = this.filter.options.filter((o) => o.selected);
    },

    disableFilter(filter) {
      filter.selected = false;
      this.applyFilters();
    },

    toggleFilter(filter) {
      filter.selected = !filter.selected;
    },
    setHashToSearch(hash) {
      if (hash) {
        this.$set(this.$refs["search"].$children[0]._data, "query", hash);
      }
    },
  },

  data() {
    return {
      localLoading: false,

      searchInput: null,

      period: {
        options: defaultPeriods().map((period) => period.label),
        value: "Last year",
      },

      moveDevices: {
        width: 248,
        opened: false,
        arrowUp: true,
        assignTo: this.$refs.moveDevices,
        applied: [],
        selected: [],
        options: [
          { id: 1, selected: false, name: "Trackers" },
          { id: 2, selected: false, name: "Berlin" },
          { id: 3, selected: false, name: "People counters" },
        ],
      },

      updateDevices: {
        width: 330,
        opened: false,
        arrowUp: true,
        assignTo: this.$refs.updateDevices,
        applied: [],
        selected: [],
      },

      addTagsPopUp: {
        width: 248,
        opened: false,
        arrowUp: true,
        assignTo: this.$refs.addTags,
        applied: [],
        selected: [],
        options: [
          { id: 1, selected: false, name: "Trackers" },
          { id: 2, selected: false, name: "Berlin" },
          { id: 3, selected: false, name: "People counters" },
        ],
      },

      filter: {
        width: 248,
        opened: false,
        arrowUp: true,
        assignTo: null,
        applied: [],
        options: [
          { id: 1, selected: false, name: "Trackers" },
          { id: 2, selected: false, name: "Berlin" },
          { id: 3, selected: false, name: "People counters" },
          { id: 4, selected: false, name: "June" },
          { id: 5, selected: false, name: "Moscow" },
          { id: 6, selected: false, name: "Milan" },
          { id: 7, selected: false, name: "Air quality" },
          { id: 8, selected: false, name: "Val 8" },
          { id: 9, selected: false, name: "Val 9" },
          { id: 10, selected: false, name: "Val 10" },
          { id: 11, selected: false, name: "Val 11" },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.add-tags-actions {
  .action {
    width: 100%;
    &:first-child {
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
.devices-table-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions {
    display: flex;

    .search-input {
      max-width: 300px;
      min-width: 250px;
      margin-left: 0;
      margin-right: 20px;
      flex-shrink: 1;
    }

    .interaction-actions,
    .default-actions {
      display: flex;
      align-items: center;

      > * {
        margin: 0 4px;
      }
    }    
  }

  .filter-button {
    min-width: 40px;
    max-width: 64px;
  }

  .period-filter {
    max-width: 200px;

    .calendar {
      margin-right: 8px;
    }
  }
}
</style>
