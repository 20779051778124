<template>
  <div class="device-group" v-if="deviceGroup">
    <device-group-stats />

    <moe-block :loading="genericLoading" overflowless>
      <template #heading>
        <segmented-control
          :segments="segments"
          @input="handle"
          v-model="selectedSegment"
        >
          <template #segment="{ segment }">
            <router-link :to="{ name: segment.route }" class="segment-link">
              {{ segment.name }}
            </router-link>
          </template>
        </segmented-control>
      </template>

      <template #actions>
        <button-input
          :primary="false"
          @click.native="addBtnClicked"
          :disabled="isDisabled"
          v-if="ifAddButtonVisible"
        >
          <plus-icon slot="icon" fill="currentColor" />
          <template>
            {{ selectedSegment ? selectedSegment.btnTitle : "" }}
          </template>
          <import type="json" @import="onImport" ref="importDevices" />
        </button-input>
      </template>

      <template #content>
        <router-view />
      </template>
    </moe-block>

    <!-- Opens when we click "import" button at top panel button ( shows when we have at least 1 sensor in group) -->
    <import-devices-popup v-bind="importData" @close="closeImportPopUp" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DeviceGroupStats from "./DeviceGroupStats";
import Import from "@/components/Inputs/Import";
import ImportDevicesPopup from "@/components/Popups/ImportDevicesPopup";
import PlusIcon from "@/components/Icons/Plus";

export default {
  name: "devices-view",

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  components: {
    ImportDevicesPopup,
    DeviceGroupStats,
    PlusIcon,
    Import,
  },

  data() {
    return {
      importResultOpened: true,

      importData: {
        opened: false,
        devices: [],
      },

      segments: [
        // {
        //   id: 0,
        //   name: "Services",
        //   route: "device-group-services",
        //   btnTitle: "Add service",
        // },
        {
          id: 1,
          name: "Sensors",
          route: "device-group-devices",
          btnTitle: "Add sensors",
        },
        {
          id: 2,
          name: "Settings",
          route: "device-group-settings",
        },
      ],
      selectedSegment: null,
    };
  },

  computed: {
    ...mapGetters({
      deviceGroup: "deviceGroup/item",
      services: "deviceGroup/services",
      devices: "devices/list",
      loading: "deviceGroup/loading",
      deviceLoading: "device/loading",
      devicesLoading: "devices/loading",
    }),

    isDisabled() {
      // Disabled if not confirmed

      if (!this.deviceGroup) {
        return false;
      }

      return this.deviceGroup.confirmed;
    },

    ifAddButtonVisible() {
      const settingsSegment = this.selectedSegment.name === "Settings";
      const devicesSegment = this.selectedSegment.name === "Sensors";
      const servicesExists = this.services.length != 0;
      const devicesExists = this.devices.length != 0;

      return (
        (servicesExists && !settingsSegment) ||
        (devicesExists && devicesSegment)
      );
    },

    genericLoading() {
      return this.loading || this.devicesLoading || this.deviceLoading;
    },
  },

  created() {
    this.fetchDeviceGroup();

    const thisRoute = this.segments.find(
      (segment) => segment.route == this.$route.name
    );

    this.selectedSegment = thisRoute ? thisRoute : this.segments[0];

    this.handle();
  },

  beforeRouteLeave(to, from, next) {
    this.setDeviceGroup({});
    next();
  },

  methods: {
    ...mapActions({
      getDeviceGroup: "deviceGroup/getItem",
      getDevicesList: "devices/getList",
      devicesChangeParams: "devices/changeParams",
      importList: "devices/importList",
    }),

    ...mapMutations({
      setDeviceGroup: "deviceGroup/setItem",
      addService: "deviceGroup/addService",
    }),

    async fetchDeviceGroup() {
      try {
        await this.getDeviceGroup(this.id);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onImport(devices) {
      this.importData.devices = devices;
      this.importData.opened = true;
    },

    openFileDialog() {},

    closeImportPopUp() {
      this.importData.devices = [];
      this.importData.opened = false;
      this.$refs.importDevices.$refs.uploadFileInput.value = null;

      try {
        this.devicesChangeParams({
          limit: {
            offset: 0,
            count: 100,
          },
        });

        this.getDevicesList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    handle() {
      if (this.selectedSegment) {
        if (this.$route.name !== this.selectedSegment.route) {
          this.$router.replace({ name: this.selectedSegment.route });
        }
      }
    },

    addBtnClicked() {
      if (this.selectedSegment.route === "device-group-devices") {
        this.$refs.importDevices.$refs.uploadFileInput.click();
      }
      if (this.selectedSegment.route === "device-group-services") {
        this.addService();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.device-group {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 8px;
  align-items: flex-start;

  .segment-link {
    padding: 0 32px 0 32px;
    text-decoration: none;
    color: currentColor;
  }
}
</style>
