import DashboardView from "@/views/Dashboard";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard"
    },
    component: DashboardView
  }
];
