<template>
  <div class="gateway-group-create">
    <moe-block heading="Gateway groups" :loading="loading">
      <template #content>
        <div class="form">
          <div class="input-wrapper name">
            <span>Group name</span>
            <text-input
              placeholder="Please enter group name"
              v-model="gatewayGroupName"
            />
          </div>

          <div class="input-wrapper group-type">
            <span>Gateway group type</span>
            <select-input
              id="group_type"
              v-model="gateGroupsType"
              :options="gateGroupsTypes"
            >
              <template #single-label>{{ gateGroupsType.name }}</template>
              <template #option="{ option }">{{ option.name }}</template>
            </select-input>
          </div>

          <div class="actions">
            <button-input :primary="false" @click.native="cancel"
              >Cancel</button-input
            >
            <button-input @click.native="addGroup">Add group</button-input>
          </div>
        </div>
      </template>
    </moe-block>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      gatewayGroupName: null,
      gateGroupsType: null,
    };
  },

  computed: {
    ...mapGetters({
      loading: "gatewayGroup/loading",
      settings: "constants/settings",
      gatewayGroup: "gatewayGroup/item",
    }),

    gateGroupsTypes() {
      if (!this.settings || !this.settings.gatewayGroups) return [];

      const gateTypes = Object.entries(this.settings.gatewayGroups.types);

      const formatted = gateTypes.map((item) => {
        return {
          name: item[1].name,
          value: item[1].id,
        };
      });

      return formatted;
    },
  },

  methods: {
    ...mapActions({
      createGatewayGroup: "gatewayGroup/createItem",
    }),

    async addGroup() {
      try {
        await this.createGatewayGroup({
          name: this.gatewayGroupName,
          group_type: this.gateGroupsType.name,
        });

        this.$router.push({
          name: "gateway-group",
          params: {
            id: this.gatewayGroup.blockchain_uid,
          },
        });
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    cancel() {
      this.$router.push({ name: "gateway-groups" });
    },
  },
};
</script>

<style lang="scss" scoped>
.gateway-group-create {
  flex: 1;

  .form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border: 1px solid $grey50;
    border-radius: 4px;
    padding: 14px 24px 18px 24px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      &.fixed-width {
        min-width: 150px;
        max-width: 150px;
      }

      span {
        @include text-body;
        color: $grey70;
        line-height: 24px;
        margin-bottom: 8px;
      }

      &.name {
        width: 360px;
      }

      &.group-type {
        width: 200px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      > * {
        margin-right: 8px;
      }
    }
  }
}
</style>
