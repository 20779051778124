<template>
  <popup v-bind="$attrs" disableFooterPadding @close="close">
    <div slot="header" class="header">
      <span class="title">Update downlinks</span>
      <cross-icon @click.native="close" />
    </div>

    <div slot="content" class="content">
      <slot name="content">
        <div class="settings-segment" v-if="true">
          <div class="form">
            <div class="inputs wide">
              <label>
                Free command
                <text-input v-model="form.freeCommand" :disabled="!!form.t1 || !!form.t2" />
              </label>
            </div>

            <div class="inputs">
              <label>
                Time 1
                <text-input v-model="form.t1" :disabled="!!form.freeCommand" />
              </label>

              <label>
                Time 2
                <text-input v-model="form.t2" :disabled="!!form.freeCommand" />
              </label>
            </div>
        </div>
      </div>

      </slot>
    </div>
    <template slot="actions">
      <slot name="actions">
        <div class="actions-buttons">
          <button-input class="button" @click.native="close" :primary="false">
            <span>Cancel</span>
          </button-input>

          <button-input class="button" @click.native="moveDevices">
            <span>Update</span>
          </button-input>
        </div>
      </slot>
    </template>
  </popup>
</template>

<script>
import CrossIcon from "@/components/Icons/Cross";

export default {
  name: "update-devices",

  components: {
    CrossIcon
  },

  created() {},

  methods: {

    close() {
      this.$emit("close");
    },

    moveDevices() {
      this.$emit("update", this.form);
    },

  },

  data() {
    return {
      form: {
        freeCommand: null,
        t1: null,
        t2: null,
      },
    };
  }
};
</script>

<style lang="scss" scoped>

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }
}

.content {
  max-height: 320px;

  .device-group-item {
    display: flex;
    padding: 5px;
    

    .item:first-child {
      margin-right: 10px;
    }

    .device-group-label {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.actions-buttons {
  display: flex;
  padding: 10px;
  .button {
    &:first-child {
      margin-right: 5px;
    }
  }
}

.settings-segment {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form {
    flex: 1;
    flex-direction: column;

    width: 300px;
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 22px;
      margin-bottom: 14px;
      &.wide {
        grid-template-columns: 1fr;
      }

      label {
        @include text-body;
        color: $secondaryText;
      }
    }
  }

  .actions {
    .apply-button {
      margin-left: auto;
    }
  }
}
</style>
