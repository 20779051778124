<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6007 1.87014C22.0168 1.46554 23.4928 2.28552 23.8974 3.70161C23.9655 3.93985 24 4.18642 24 4.4342V6.66656H26.6667C28.1394 6.66656 29.3333 7.86047 29.3333 9.33323V25.3332C29.3333 26.806 28.1394 27.9999 26.6667 27.9999H5.33332C3.86056 27.9999 2.66666 26.806 2.66666 25.3332H2.70226C2.67859 25.1898 2.66666 25.0446 2.66666 24.8989V9.00563C2.66666 7.81501 3.45593 6.76865 4.60073 6.44156L20.6007 1.87014ZM13.5201 25.3332H26.6667V14.6666H24V20.3275C24 21.5181 23.2107 22.5645 22.0659 22.8916L13.5201 25.3332ZM24 9.33323H26.6667V11.9999H24V9.33323ZM5.33332 9.00559V24.8989L21.3333 20.3275V4.43416L5.33332 9.00559ZM18.6667 11.9999C18.6667 12.7363 18.0697 13.3332 17.3333 13.3332C16.5969 13.3332 16 12.7363 16 11.9999C16 11.2635 16.5969 10.6666 17.3333 10.6666C18.0697 10.6666 18.6667 11.2635 18.6667 11.9999Z" fill="#555FB9"/>
  </svg>
</template>

<script>
export default {
  name: "key-icon",
  props: {
    fill: {
      default: "#474B6D",
      type: String
    }
  }
};
</script>
