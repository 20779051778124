<template>
  <div class="moe-block" :class="{ compressed }" :style="(height) ? `height: ${height};` : ``">
    <div v-if="loading">
      <div class="loader-background"></div>
      <preloader2 />
    </div>

    <div v-if="hasHeader" class="moe-block-header" :class="{ compressed }">
      <slot name="heading">
        <h1>{{ heading }}</h1>
      </slot>
      <div v-if="hasActions" class="moe-block-header-actions">
        <slot name="actions" />
      </div>
    </div>

    <div
      v-if="hasContent"
      class="moe-block-container"
      :class="{ scrollable, transparent, overflowless, paddingless }"
    >
      <slot name="content" />
    </div>

    <div v-if="hasFooter" class="moe-block-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import Preloader2 from "@/components/Preloader2";

export default {
  name: "moe-block",
  components: { Preloader2 },
  props: {
    heading: {
      type: String,
      required: false
    },

    height: {
      type: String,
      required: false
    },

    compressed: {
      type: Boolean,
      required: false,
      default: false
    },

    transparent: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    },

    overflowless: {
      type: Boolean,
      default: false
    },

    paddingless: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasHeader() {
      return this.hasHeadingSlot || this.heading || this.hasActions;
    },

    hasHeadingSlot() {
      return !!this.$slots.heading;
    },

    hasActions() {
      return !!this.$slots.actions;
    },

    hasContent() {
      return !!this.$slots.content;
    },

    hasFooter() {
      return !!this.$slots.footer;
    }
  }
};
</script>

<style lang="scss" scoped>
.moe-block {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  border-radius: 4px;
  overflow-y: hidden;
  flex: 1;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(71, 75, 109, 0.6);

  position: relative;

  &.gateway-owner {
    box-shadow: 0px 1px 4px rgba(162, 164, 189, 0.2);
  }

  .loader-background {
    position: absolute;
    background: blue;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0.1;
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .moe-block-header {
    width: 100%;
    height: 64px;
    min-height: 64px;
    background: #EEEFF8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px $grey50;

    h1 {
      @include h2;
      flex: 1;
      color: $notBlack;
    }
  }

  &.compressed {
    .moe-block-header {
      min-height: 48px;
      height: 48px;
    }
  }

  .moe-block-container {

    display: flex;
    flex-direction: column;
    flex: 1;
    background: $white;
    padding: 16px;
    overflow-y: auto;

    &.scrollable {
      overflow-y: scroll;
    }

    &.overflowless {
      overflow-y: hidden;
    }

    &.transparent {
      background: $grey10;
    }

    &.paddingles {
      padding: 0;
    }
  }

  .moe-block-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $white;
    padding: 16px;
    border-top: 1px solid $grey50;
  }
}
</style>
