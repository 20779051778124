<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0162 14.0479L13.9834 25.9774H17.2346L19.2691 14.0479H16.0162Z" :fill="fill" />
    <path
      d="M11.2573 14.0611L8.07292 22.1967L7.73341 20.9683C7.10541 19.4882 5.32258 17.3625 3.2295 16.0229L6.14124 25.9715L9.58147 25.9656L14.7017 14.0586L11.2573 14.0611Z"
      :fill="fill"
    />
    <path
      d="M6.49914 14.915C6.31016 14.1883 5.76243 13.9717 5.08258 13.9458H0.0418113L0 14.1833C3.92273 15.1349 6.51838 17.4278 7.59543 20.1849L6.49914 14.915Z"
      :fill="fill"
    />
    <path
      d="M25.8586 16.3347C26.9223 16.318 27.6933 16.5505 28.292 16.7913L28.5855 16.9293L29.0254 14.3437C28.3815 14.102 27.3722 13.8428 26.1128 13.8428C22.9 13.8428 20.6355 15.4609 20.618 17.7797C20.5971 19.4931 22.231 20.4498 23.4653 21.0209C24.7322 21.6063 25.157 21.9784 25.1511 22.5011C25.1411 23.2996 24.141 23.6659 23.2069 23.6659C21.9049 23.6659 21.2134 23.4861 20.1455 23.0413L19.7266 22.8506L19.2691 25.5207C20.0301 25.8543 21.4341 26.1411 22.8917 26.1562C26.3093 26.1562 28.5303 24.5573 28.5537 22.0796C28.568 20.7241 27.7008 19.6897 25.8218 18.8409C24.6845 18.2873 23.9888 17.9194 23.9955 17.3608C23.9955 16.8649 24.5859 16.3347 25.8586 16.3347Z"
      :fill="fill"
    />
    <path
      d="M37.3692 14.061H34.8572C34.077 14.061 33.4967 14.2734 33.1538 15.0511L28.3263 25.9839H31.7406C31.7406 25.9839 32.2975 24.5139 32.4238 24.1919C32.7976 24.1919 36.1149 24.1969 36.5874 24.1969C36.6844 24.6134 36.9837 25.9839 36.9837 25.9839H40L37.3692 14.061ZM33.3595 21.7501C33.6271 21.0653 34.6549 18.4161 34.6549 18.4161C34.6373 18.4487 34.9199 17.7262 35.088 17.2772L35.3071 18.3057C35.3071 18.3057 35.9301 21.1539 36.0605 21.7501H33.3595Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "visa-icon",
  props: {
    fill: {
      default: "#FFFFFF",
      type: String
    }
  }
};
</script>