<template>
  <div class="redirect-container">
    <div class="redirect">
      <h3>Redirect to sign-in page...</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "redirect-to-sign-in-view",

  async mounted() {
    // let { _startLocation } = this.$router.history;
    // _startLocation = _startLocation ? _startLocation : "";
    // window.location.href = `${process.env.VUE_APP_AUTH}?redirectTo=${process.env.VUE_APP_URL}/${_startLocation}`;
    const debug = process.env.VUE_APP_DEBUG_AUTH_REDIRECT === "true";

    if (!debug) {
      window.location.href = `${process.env.VUE_APP_AUTH_REDIRECT}?redirectTo=${process.env.VUE_APP_URL}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.redirect-container {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  .redirect {
    box-shadow: $defaultShadow;
    border-radius: 6px;
    width: 300px;
    height: 200px;
    display: flex;
    background: $white;
    align-items: center;
    justify-content: center;

    h3 {
      @include h3;
      color: $notBlack;
    }
  }
}
</style>
