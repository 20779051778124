<template>
  <div class="left-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: "left-panel",
};
</script>

<style lang="scss" scoped>
.left-panel {
  height: calc(100vh - 50px);
  width: 88px;

  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;

  &.device-owner {
    background: $accentBlue;
  }

  &.gateway-owner {
    background: $notBlack;
  }

  .logo {
    top: 20px;
    left: 24px;
    right: 24px;
    position: absolute;
  }

  .dark {
    opacity: 0.2;
  }
}
</style>
