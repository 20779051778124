<template>
  <div class="settings-segment" v-if="deviceGroup">
    <div class="form">
      <div class="inputs wide">
        <label>
          Sensor group name
          <text-input v-model="form.name" :disabled="isDisabled" />
        </label>
      </div>

      <!-- <div class="inputs">
        <label>
          Uplink lifetime (minutes)
          <text-input v-model="form.uplink" :disabled="isDisabled" />
        </label>
        <label>
          Downlink lifetime (minutes)
          <text-input v-model="form.downlink" :disabled="isDisabled" />
        </label>
      </div>

      <div class="inputs">
        <label>
          Next transition timeout (seconds)
          <text-input v-model="form.trx_timeout" :disabled="isDisabled" />
        </label>
      </div> -->

      <div class="inputs">
        <label>
          Current tariff
          <text-input :value="currentTarif" :disabled="isDisabled" />
        </label>

        <label for="tariffs">
          Tariff since {{ currentDate }}
          <select-input
            id="tariffs"
            :disabled="isDisabled"
            v-model="tariffsTypes.value"
            :options="tariffsTypes.options"
          >
            <template #single-label>{{ tariffsTypes.value.name }}</template>
            <template #option="option">{{ option.option.name }}</template>
          </select-input>
        </label>
      </div>
    </div>

    <div class="actions">
      <button-input
        @click.native="update"
        class="apply-button"
        :disabled="isDisabled"
        >Apply</button-input
      >
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        name: null,
        uplink: null,
        downlink: null,
        trx_timeout: null,
      },

      currentTarif: null, // TODO: make computed

      // TODO: Move to vuex
      tariffsTypes: {
        value: null,
        options: [
          { name: "Per transition", value: "TARIF_PER_TRX" },
          { name: "Per sensor", value: "TARIF_PER_SENSOR" },
          { name: "Per sensor smart", value: "TARIF_PER_SENSOR_SMART" },
        ],
      },
    };
  },

  watch: {
    loading: function () {
      if (this.loading == false) {
        this.initSettings();
      }
    },
  },

  created() {
    if (this.deviceGroup) {
      this.initSettings();
    }
  },

  computed: {
    ...mapGetters({
      deviceGroup: "deviceGroup/item",
      loading: "deviceGroup/loading",
    }),

    isDisabled() {
      if (!this.deviceGroup) {
        return false;
      }

      // Disabled if not confirmed
      return this.deviceGroup.confirmed;
    },

    currentDate() {
      return Moment().format("DD/MM/YYYY");
    },
  },

  methods: {
    ...mapActions({
      updateItem: "deviceGroup/updateItem",
    }),

    initSettings() {
      const foundTarif = this.tariffsTypes.options.find(
        (el) => el.value == this.deviceGroup.tarif_str
      );

      if (foundTarif) {
        this.currentTarif = foundTarif.name;
      }

      this.form.name = this.deviceGroup.name;
      this.form.uplink = this.deviceGroup.uplink_lifetime;
      this.form.downlink = this.deviceGroup.downlink_lifetime;
      this.form.trx_timeout = this.deviceGroup.trx_timeout;
    },

    async update() {
      const data = {
        name: this.form.name,
        uplink_lifetime: this.form.uplink,
        downlink_lifetime: this.form.downlink,
        trx_timeout: this.form.trx_timeout,
        tarif_str: this.tariffsTypes.value
          ? this.tariffsTypes.value.value
          : null,
      };

      try {
        await this.updateItem(data);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-segment {
  height: 100%;
  display: flex;
  flex-direction: column;

  // user-select: none;
  .form {
    flex: 1;
    flex-direction: column;

    margin-left: 64px;
    margin-top: 64px;
    width: 430px;
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 22px;
      margin-bottom: 14px;
      &.wide {
        grid-template-columns: 1fr;
      }

      label {
        @include text-body;
        color: $secondaryText;
      }
    }
  }

  .actions {
    .apply-button {
      margin-left: auto;
    }
  }
}
</style>
