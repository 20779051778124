import DashboardApi from "@/api/dashboard";

export default {
  namespaced: true,

  state: {
    data: [],
    meta: {},
    loading: false,
    error: null
  },

  actions: {
    async fetch(ctx) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        const { data, meta } = await DashboardApi.fetchDataMetaInfo();
        ctx.commit("setData", data);
        ctx.commit("setMeta", meta);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "dashboard_data_metainfo", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setData(state, data) {
      state.data = data;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },
  },

  getters: {
    loading: state => state.loading,
    error: state => state.error,
    data: state => state.data,
    meta: state => state.meta
  }
};
