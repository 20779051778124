<template>
  <div class="balance-changes-table-container">
    <table-component
      :data="days"
      :columns="columns"
      :gridTemplate="gridTemplate"
      class="balance-changes-table"
    >
      <template #header-date="{ column }">
        <div class="info-heading">
          <h2>{{ column.title }}</h2>
        </div>
      </template>
      <template #header-items="{ column }">
        <div class="info-heading">
          <h2>{{ column.value }}</h2>
          <p>{{ column.title }}</p>
        </div>
      </template>
      <template #header-deposited="{ column }">
        <div class="info-heading">
          <h2>{{ column.value }}</h2>
          <p>{{ column.title }}</p>
        </div>
      </template>
      <template #header-spent="{ column }">
        <div class="info-heading">
          <h2>
            {{ column.value }}
            <span>{{ column.tip }}</span>
          </h2>
          <p>{{ column.title }}</p>
        </div>
      </template>

      <template #row-date="props">
        <h3>{{ getDateString(props).format("MMM DD") }}</h3>
      </template>

      <template #row-details="props">
        <router-link :to="getDetailsLink(props)">Details</router-link>
      </template>
    </table-component>
  </div>
</template>

<script>
import Moment from "moment";
import { Table as TableComponent } from "@moeco-platform/moeco-components";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "balance-changes-table",

  components: {
    TableComponent,
  },

  watch: {
    loading: function () {
      if (!this.loading) {
        this.prepareColumns();
      }
    },
  },

  computed: {
    ...mapGetters({
      list: "finances/list",
      days: "finances/days",
      loading: "finances/loading",
      params: "finances/params",
    }),

    currentDate() {
      if (!this.params) {
        return "";
      }
      const month = this.params.month;
      const year = this.params.year;
      return Moment(`${year}-${month + 1}`, "YYYY-MM");
    },
  },

  methods: {
    ...mapActions({
      getList: "finances/getList",
    }),

    getDateString(props) {
      // TODO: reuse `currentDate` ?
      const year = props.item.year;
      const month = props.item.month;
      const day = props.item.day;
      const date = `${year}-${month + 1}-${day}`;
      return Moment(date, "YYYY-MM-DD");
    },

    getDetailsLink(props) {
      let data = this.getDateString(props);
      return {
        name: "finances-day-details",
        params: {
          date: data.toISOString(),
        },
      };
    },

    // TODO: probably duplicates at `blocks/MonthSpending` component
    prepareColumns() {
      let totalDeposited = 0;
      let totalSpent = 0;
      let totalTxs = 0;

      for (let day of this.days) {
        totalDeposited += parseFloat(day.deposited);
        totalSpent += parseFloat(day.spent);
        totalTxs += parseFloat(day.items);
      }

      totalDeposited = totalDeposited.toFixed(2);
      totalSpent = totalSpent.toFixed(2);

      const month = Moment(this.params.month, "M").format("MMMM");

      this.columns[0].title = month
        ? month + " " + Moment().format("YYYY")
        : "";
      this.columns[1].value = totalTxs;
      this.columns[2].value = "$ " + totalDeposited;
      this.columns[3].value = "$ " + totalSpent;
    },
  },

  data() {
    return {
      gridTemplate: ["2fr", "1fr", "1fr", "1fr", "1fr"],
      columns: [
        { key: "date", title: Moment().format("MMMM YYYY"), value: "" },
        { key: "items", title: "Transactions", value: "" },
        { key: "deposited", title: "Deposited", value: "" },
        { key: "spent", title: "Spent", value: "" },
        { key: "details", title: null },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.balance-changes-table-container {
  flex: 1;
  margin-top: 20px;
  box-shadow: $defaultShadow;
  background: $white;
  border-radius: 6px;

  .balance-changes-table {
    border-radius: 6px;

    .info-heading {
      width: 100%;

      h2 {
        @include h2;
        display: flex;
        justify-content: space-between;

        span {
          font-weight: normal;
          color: $grey50;
          margin-left: 8px;
        }
      }

      p {
        @include text-body;
        color: $grey70;
      }
    }

    h3 {
      @include h3;
    }

    ::v-deep th,
    ::v-deep td {
      padding: 10px 24px;
      height: 40px;
    }

    ::v-deep th {
      height: 64px;
      color: $notBlack;
    }
  }
}
</style>
