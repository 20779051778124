<template>
  <highcharts :options="config" ref="chart" class="bar-chart" />
</template>

<script>
import Moment from "moment";
import { barConfigDefault } from "@/helpers/chartConfigs.js";

export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },

    multiplier: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data() {
    return {
      config: {
        ...barConfigDefault,

        chart: {
          marginBottom: 20,
        },

        xAxis: {
          ...barConfigDefault.xAxis,
          type: "datetime",
          min: parseInt(Moment().subtract(11, "months").format("x")),
          max: parseInt(Moment().format("x")),
          tickWidth: 0,
          tickInterval: 30 * 24 * 60 * 60 * 1000 * this.multiplier, // month
          labels: {
            formatter: function () {
              return Moment(this.value, "x").format("MMM");
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4",
            },
          },
        },
        yAxis: {
          ...barConfigDefault.yAxis,
          labels: {
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4",
            },
          },
        },
        plotOptions: {
          series: {
            pointWidth: 7,
          },
        },
        series: [
          {
            type: "column",
            name: "Spent",
            marker: {
              enabled: false,
            },
            data: [],
          },
        ],
      },
    };
  },

  created() {
    this.drawPoints();
  },

  // TODO: delete and use v-if instead ( use loading flag ) - v-if will push re render chart
  watch: {
    "chartData.length": function (/* newLength, oldLength */) {
      if (this.chartData.length > 0) {
        this.drawPoints();
      }
    },
  },

  methods: {
    drawPoints() {
      const data = JSON.parse(JSON.stringify(this.chartData)).reverse();
      const periodKey = this.getPeriodKey(data[0]);
      for (let dataItem of data) {
        this.addPoint(dataItem, periodKey);
      }
    },

    addPoint(dataItem, periodKey) {
      this.config.series[0].data.push({
        x: parseInt(Moment(dataItem[periodKey]).format("x")),
        y: parseFloat(dataItem.amount),
      });
    },

    getPeriodKey(sampleItem) {
      for (let key in sampleItem) {
        if (key != "amount") {
          return key;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.bar-chart {
  .highcharts-point {
    fill: $grey50;
    rx: 5px;
    ry: 5px;
  }

  .highcharts-root {
    color: #6d6e8d !important;
  }
}
</style>
