var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-component',{staticClass:"details-table",attrs:{"columns":_vm.columns,"data":_vm.list,"gridTemplate":_vm.gridTemplate},on:{"sort-by":_vm.onSort},scopedSlots:_vm._u([{key:"row-type",fn:function(ref){
var item = ref.item;
return [_c(item.type === 'spend' ? 'spending-icon' : 'deposit-icon',{tag:"component"})]}},{key:"row-spent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.spent ? parseFloat(item.spent).toFixed(2) + "$" : "-"))])]}},{key:"row-deposit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deposit ? parseFloat(item.deposit).toFixed(2) + "$" : "-"))])]}},{key:"row-tarif",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTarifRowContent(item)))]}},{key:"row-timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.timestamp)))]}},{key:"row-uid",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(item.uid))])]}},{key:"row-status",fn:function(){return [_c('span',{staticStyle:{"color":"#37cb95"}},[_vm._v("Completed")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }