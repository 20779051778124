<template>
  <div class="gateway-group">
    <gateway-group-stats />

    <moe-block :loading="loading" overflowless>
      <template #heading>
        <segmented-control :segments="segments" @input="handle" v-model="selectedSegment">
          <template #segment="{ segment }">
            <router-link :to="{ name: segment.route }" class="segment-link">{{ segment.name }}</router-link>
          </template>
        </segmented-control>
      </template>

      <template #content>
        <router-view />
      </template>
    </moe-block>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GatewayGroupStats from "./GatewayGroupStats";

export default {
  name: "gateways-view",

  props: {
    id: {
      type: String,
      default: null
    }
  },

  components: { GatewayGroupStats },

  data() {
    return {
      segments: [
        {
          id: 0,
          name: "Gateways",
          route: "gateway-group-gateways",
          btnTitle: "Add gateway"
        },
        {
          id: 1,
          name: "Settings",
          route: "gateway-group-settings",
          btnTitle: ""
        }
      ],
      selectedSegment: null
    };
  },

  computed: {
    ...mapGetters({
      gatewayGroup: "gatewayGroup/item",
      gatewayGroupLoading: "gatewayGroup/loading",
      gatewayListLoading: "gateways/loading",
      gatewayLoading: "gateway/loading"
    }),

    gatewayGroupUid() {
      return this.id;
    },

    loading() {
      return (
        this.gatewayGroupLoading ||
        this.gatewayListLoading ||
        this.gatewayLoading
      );
    }
  },

  async created() {
    try {
      await this.getGatewayGroup(this.gatewayGroupUid);
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000
      });
    }

    const thisRoute = this.segments.find(
      segment => segment.route == this.$route.name
    );
    this.selectedSegment = thisRoute ? thisRoute : this.segments[0];
    this.handle();
  },

  beforeRouteLeave(to, from, next) {
    // this.setDeviceGroup(null);
    next();
  },

  methods: {
    ...mapActions({
      getGatewayGroup: "gatewayGroup/getItem"
    }),
    addBtnClicked() {
      if (this.selectedSegment.route === "gateway-group-gateways") {
        this.$refs.importGateways.$refs.uploadFileInput.click();
      }
    },
    handle() {
      if (this.selectedSegment) {
        if (this.$route.name !== this.selectedSegment.route) {
          this.$router.replace({ name: this.selectedSegment.route });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gateway-group {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 8px;
  align-items: flex-start;

  .segment-link {
    padding: 0 32px 0 32px;
    text-decoration: none;
    color: currentColor;
  }
}
</style>
