import GatewaysApi from "@/api/gateways";
import GatewayModel from "@/store/models/gateway";
// import ResponseHandler from "@/helpers/responseHandler";

export default {
  namespaced: true,

  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async createItem(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        await GatewaysApi.createItem(obj);
      } catch (error) {
        ctx.dispatch("errors/pushError", error, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    async updateItem(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        const newItem = new GatewayModel(obj);
        await GatewaysApi.updateItem(obj.blockchain_uid, newItem.dto);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "gateway", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data;
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item
  }
};
