<template>
  <div class="data-view">
    <moe-block :loading="loading">
      <template slot="heading">
        <div class="heading">
          <h1>Transfers</h1>
          <h1>{{ title }}</h1>
        </div>
      </template>

      <template slot="actions">
        <button-input @click.native="openExport">Export</button-input>
      </template>

      <template slot="content">
        <div class="data-container">
          <div class="fixed-container">
            <data-view-heading />
            <highcharts
              :options="lineConfig"
              class="linear-chart"
              ref="chart"
            />
          </div>

          <table-component
            ref="table"
            class="data-table"
            v-bind="table"
            :data="lazyList"
            @sort-by="onSort"
            @hit-bottom="hitBottom"
          >
            <template #row-blockchain_id="{ item }">
              <span class="cell-inner">{{ item.blockchain_id }}</span>
              <!-- /* | cutnumber(8, 4, 3) */ -->
            </template>

            <template #row-confirmed="{ item }">
              <tag-wrapper v-bind="item" />
            </template>

            <template #row-device="{ item }">
              <span class="cell-inner">
                {{ item.device ? item.device.hash : "empty" }}
              </span>
            </template>

            <template #row-device_group="{ item }">
              <span class="cell-inner">{{
                item.device && item.device.device_group
                  ? item.device.device_group.name
                  : ""
              }}</span>
            </template>

            <template #row-created_at="{ item }">
              <span class="cell-inner date-view">
                {{ formatDate(item.created_at) }}
              </span>
            </template>

            <template #row-timestamp="{ item }">
              <span class="cell-inner date-view">
                {{ formatDate(item.timestamp) }}
              </span>
            </template>

            <template #row-gateway="{ item }">
              <span class="cell-inner">
                {{ item.gateway && item.gateway.name }}
              </span>
            </template>

            <template #row-payload="{ item }">
              <div class="cell-inner show-payload-icon">
                <info-icon @click.native="showPayload(item)" />
              </div>
            </template>
          </table-component>
        </div>
      </template>
    </moe-block>

    <popup :opened="payload.opened" @close="closePayload">
      <template slot="content">
        <h1 style="font-weight: 500">Content of transfer:</h1>
        <pre><code>{{ getTxContent(payload.content) }}</code></pre>
      </template>
    </popup>
  </div>
</template>

<script>
import Moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { lineConfigDefault } from "@/helpers/chartConfigs.js";
import DataViewHeading from "./DataViewHeading";
import { VirtualScrollTable as TableComponent } from "@moeco-platform/moeco-components";
// import { Table as TableComponent } from "@moeco-platform/moeco-components";
import InfoIcon from "@/components/Icons/Info";
import TagWrapper from "@/components/TagWrapper";

export default {
  name: "data-view",

  components: {
    DataViewHeading,
    TableComponent,
    InfoIcon,
    TagWrapper,
  },

  watch: {
    loading: function (val) {
      this.blockScroll = val;
      if (!val) {
        this.drawChart();
      }
    },
  },

  methods: {
    ...mapMutations({
      setLazyList: "transactions/setLazyList",
    }),

    ...mapActions({
      getListWithChart: "transactions/getListWithChart",
      getChart: "transactions/getChart",
      changeParams: "transactions/changeParams",
    }),

    formatDate(date) {
      return Moment(date).format("YYYY-MM-DD HH:mm:ss");
    },

    formatDeviceName(name) {
      return name.length > 16 ? name.slice(0, 15) + "..." : name;
    },

    // TODO: export function go to one place
    openExport(e) {
      e.preventDefault();

      const a = document.createElement("a");

      const file = new Blob([JSON.stringify(this.lazyList)], {
        type: "text/plain",
      });
      a.href = URL.createObjectURL(file);

      a.download = `export-transactions-list-${Moment().format(
        "YYYY_DD_MM_HH_mm_ss"
      )}.json`;

      a.click();
    },

    closeExport() {
      this.exportOpened = false;
    },

    showPayload(tx) {
      this.payload.opened = true;
      this.payload.content = tx.payload;
    },

    closePayload() {
      this.payload.opened = false;
      this.payload.content = "";
    },

    drawChart() {
      this.lineConfig.series[0].data = [];

      for (let p of this.chart) {
        this.lineConfig.series[0].data.push({
          x: parseInt(Moment(p.interval_start).format("x")),
          y: parseInt(p.total),
        });
      }
    },

    hitBottom() {
      // if (scrollTop == 0 && !this.blockScroll) {
      //   if (this.params.offset > 0) {
      //     const count = this.params.limit.count;
      //     const offset = this.params.limit.offset - 100;
      //     this.changeParams({ limit: { count, offset } });
      //     this.getListWithChart();
      //   }
      // }

      if (this.meta.total != this.lazyList.length && this.meta.count != 0) {
        const count = this.params.limit.count;
        const offset = this.params.limit.offset + 100;
        this.changeParams({ limit: { count, offset } });
        this.getListWithChart();
      }
    },

    onSort(value) {
      this.changeParams({
        orderBy: { value: this.prepareOrderBy(value) },
        limit: { offset: 0, count: 100 },
      });
      this.setLazyList([]);
      this.getListWithChart();
    },

    prepareOrderBy(value) {
      const fields = {
        device: "device__hash",
        device_group: "device__device_group_uid",
        gateway: "gateway_uid",
      };
      return fields[value] ? fields[value] : value;
    },

    getTxContent(content) {
      let parsedContent = "";

      try {
        parsedContent = JSON.stringify(JSON.parse(content));
      } catch (error) {
        parsedContent = content;
      }

      return parsedContent;
    },
  },

  computed: {
    ...mapGetters({
      list: "transactions/list",
      lazyList: "transactions/lazyList",
      chart: "transactions/chart",
      error: "transactions/error",
      params: "transactions/params",
      title: "transactions/title",
      meta: "transactions/meta",
      loading: "transactions/loading",
    }),

    table() {
      return {
        gridTemplate: [
          "minmax(50px, 100px)",
          "minmax(100px, 250px)",
          "minmax(100px, 100px)",
          "minmax(100px, 250px)",
          "minmax(150px, 200px)",
          "minmax(150px, 200px)",
          "minmax(150px, 200px)",
          "minmax(100px, 50px)",
          "minmax(40px, 50px)",
        ],
        columns: [
          { key: "id", title: "ID", sortable: true },
          { key: "blockchain_id", title: "Hash" },
          { key: "confirmed", title: "Confirmed" },
          { key: "device", title: "Sensor", sortable: true },
          { key: "device_group", title: "Sensor group", sortable: true },
          { key: "created_at", title: "Created date", sortable: true },
          { key: "timestamp", title: "Txs date", sortable: true },
          { key: "gateway", title: "Gateway", sortable: true },
          { key: "payload", title: "", sortable: false },
        ],
      };
    },
  },

  data() {
    return {
      blockScroll: false,

      lineConfig: {
        ...lineConfigDefault,
        tooltip: {
          formatter: function () {
            const template = `
              <b>Txs count</b>: ${this.y}<br>
              <b>Date</b>: ${Moment(this.x, "x").format("DD MMM HH:mm:ss")}
            `;
            return template;
          },
        },
        xAxis: {
          tickWidth: 0,
          tickInterval: 24 * 3600 * 1000,
          labels: {
            formatter: function () {
              return Moment(this.value, "x").format("DD MMM");
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4",
            },
          },
        },
        yAxis: {
          title: { enabled: false },
          labels: {
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);
              return label.toUpperCase();
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4",
            },
          },
        },
        series: [
          {
            type: "spline",
            name: "Transfers",
            data: [],
          },
        ],
      },

      exportOpened: false,

      payload: {
        content: "",
        opened: false,
      },
    };
  },
};
</script>

<style lang="scss">
.fixed-container {
  .linear-chart {
    .highcharts-container {
      position: absolute !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.data-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .heading {
    display: flex;
    flex-direction: column;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .fixed-container {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      .linear-chart {
        flex: 1;
        margin-top: 30px;
        position: relative;
      }
    }

    .data-table {
      flex-basis: 50%;

      .date-view {
        white-space: normal;
        min-width: 80px;
      }
      .cell-inner {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .show-payload-icon {
    max-width: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
}
</style>
