import Request from "@/helpers/request";

export default class GatewaysApi {
  static getList(query) {
    return Request.client.get("/gateway", {
      params: GatewaysApi.prepareParams(query)
    });
  }

  static getItem(id) {
    return Request.client.get(`/gateway/${id}`);
  }

  static updateItem(id, data) {
    return Request.client.put(`/gateway/${id}`, data);
  }

  static prepareParams(params) {
    return {
      term: params.term,
      period_end: params.period_end,
      period_start: params.period_start,
      gateway_group__blockchain_uid: params.gateway_group__blockchain_uid,
      __order: `${params.orderBy.value} ${params.orderBy.desc ? "desc" : "asc"}`,
      __count: 100
    };
  }
}
