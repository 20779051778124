<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33334 9.33342V13.3334H8.00001C6.57554 13.3334 5.33334 14.3686 5.33334 15.7779V26.889C5.33334 28.2982 6.57554 29.3334 8.00001 29.3334H24C25.4245 29.3334 26.6667 28.2982 26.6667 26.889V15.7779C26.6667 14.3686 25.4245 13.3334 24 13.3334H22.6667V9.33342C22.6667 5.65152 19.6819 2.66675 16 2.66675C12.3181 2.66675 9.33334 5.65152 9.33334 9.33342ZM20 9.33342V13.3334H12V9.33342C12 7.12428 13.7909 5.33341 16 5.33341C18.2091 5.33341 20 7.12428 20 9.33342ZM8.00001 26.6667V16.0001H24V26.6667H8.00001ZM17.3333 21.3334C17.3333 22.0698 16.7364 22.6667 16 22.6667C15.2636 22.6667 14.6667 22.0698 14.6667 21.3334C14.6667 20.597 15.2636 20.0001 16 20.0001C16.7364 20.0001 17.3333 20.597 17.3333 21.3334Z" fill="#555FB9"/>
  </svg>
</template>

<script>
export default {
  name: "security-icon",
  props: {
    fill: {
      default: "#474B6D",
      type: String
    }
  }
};
</script>
