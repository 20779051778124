<template>
  <div class="auth-block">
    <div class="auth-block-container">
      <div class="left-panel">
        <slot />
      </div>
      <div class="right-panel">
        <logo class="logo" />
        <span class="copyright">Moeco IoT, Inc. © 2020. All rights reserved</span>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Icons/LogoFull";

export default {
  name: "auth-block",
  components: { Logo }
};
</script>

<style lang="scss" scoped>

$blockHeight: 650px;

.auth-block {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;

  .auth-block-container {
    display: flex;
  }

  .left-panel {
    width: 600px;
    height: $blockHeight;
    background: $white;
    border-radius: 6px 0px 0px 6px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: $blockHeight;
    background: $accentBlue;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 0px 6px 6px 0px;

    .logo {
      margin: auto;
    }

    .copyright {
      @include text-tiny;
      color: $grey50;
    }
  }
}
</style>
