<template>
  <div class="app">
    <portal-target name="popup" :multiple="true" />
    <portal-target name="preloader" />
    <template v-if="loading">
      <preloader />
    </template>
    <component :is="layout" v-else>
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SimpleLayout from "@/layouts/Simple.vue";
import DefaultLayout from "@/layouts/Default.vue";
import MasternodeRequest from "@/helpers/request";

const defaultLayout = "default";

export default {
  name: "app",

  components: {
    SimpleLayout,
    DefaultLayout,
  },

  computed: {
    ...mapGetters({
      loading: "auth/loading",
      token: "auth/authToken",
    }),

    layout() {
      const key = this.$route.meta.layout || defaultLayout;
      return `${key}-layout`;
    },
  },

  created() {
    this.getSettings();
  },

  watch: {
    token() {
      if (this.token) {
        MasternodeRequest.setToken(this.token);
      }
    },
  },

  methods: {
    ...mapActions({
      getSettings: "constants/getConstants",
    }),
  },
};
</script>


<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100vh;
  background: $white;
}
</style>
