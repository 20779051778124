<template>
  <div class="gateways-segment">
    <div class="gateway-groups-table-container">
      <gateways-table-heading
        :showInteraction="hasInteraction"
        class="actions"
      />
      <table-component
        class="gateway-groups-table"
        v-bind="table"
        @sort-by="onSort"
      >
        <template #header-chosen>
          <checkbox-input v-model="allRows" @input="updateRows" />
        </template>
        <template #row-chosen="props">
          <checkbox-input
            v-model="props.item.chosen"
            @input="updateAllRowsCheckbox"
          />
        </template>
        <template #row-status="props">
          <switch-input
            v-model="props.item.status"
            @input="onGatewayStatusChanged(props.item)"
          />
        </template>
        <template #row-name="props">
          <span>{{ props.item.name }}</span>
        </template>
        <template #row-updated_at="props">
          <span style="color: #9c9eb1">{{
            formatDate(props.item.updated_at)
          }}</span>
        </template>
        <template #row-created_at="props">
          <span style="color: #9c9eb1">{{
            formatDate(props.item.created_at)
          }}</span>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import { mapActions, mapGetters } from "vuex";
import GatewaysTableHeading from "./GatewaysTableHeading";
import { Table as TableComponent } from "@moeco-platform/moeco-components";

export default {
  components: {
    GatewaysTableHeading,
    TableComponent,
  },

  data() {
    return {
      table: {
        gridTemplate: [".5fr", "3fr", "2fr", "2fr", ".5fr"],
        columns: [
          { key: "chosen", type: "checkbox", sortable: false },
          { key: "name", title: "Name", sortable: true },
          {
            key: "updated_at",
            title: "Update date",
            type: "datetime",
            sortable: true,
          },
          {
            key: "created_at",
            title: "Add date",
            type: "datetime",
            sortable: true,
          },
          { key: "status", title: "Active", type: "switch", sortable: true },
        ],
        data: [],
      },
      allRows: false,
    };
  },

  watch: {
    list: {
      immediate: true,
      handler() {
        this.table.data = this.normalizeData(this.list);
      },
    },
  },

  computed: {
    ...mapGetters({
      list: "gateways/list",
      params: "gateways/params",
      loading: "gateways/loading",
    }),

    gatewayGroupUid() {
      return this.$route.params.id;
    },

    showTable() {
      return this.table.data.length != 0 && this.loading == false;
    },

    hasInteraction() {
      return this.table.data.some((row) => row.chosen);
    },
  },

  created() {
    this.changeParams({
      gateway_group__blockchain_uid: this.gatewayGroupUid,
    });
    this.fetch();
  },

  methods: {
    ...mapActions({
      getList: "gateways/getList",
      updateGateway: "gateway/updateItem",
      changeParams: "gateways/changeParams",
    }),

    async fetch() {
      try {
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    async update(gateway) {
      try {
        await this.updateGateway(gateway);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onGatewayStatusChanged(row) {
      let gateway = JSON.parse(JSON.stringify(row));
      gateway.status = gateway.status ? 1 : 0;
      this.update(gateway);
    },

    onSort(value) {
      this.changeParams({ orderBy: { value } });
      this.fetch();
    },

    normalizeData(list) {
      return list.map((item) => ({
        ...item,
        status: item.status == 1,
        chosen: false,
      }));
    },

    updateRows() {
      this.table.data.forEach((row) => (row.chosen = this.allRows));
    },

    updateAllRowsCheckbox() {
      this.allRows = Object.values(this.table.data).every(
        (row) => row.chosen == true
      );
    },

    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.gateways-segment {
  position: relative;
  height: 100%;

  .gateway-groups-table-container {
    height: 100%;
    .gateway-groups-table {
      max-height: 90%;
      padding-right: 20px;
    }
  }

  .actions {
    position: sticky;
    top: -16px;
    background: $white;
    padding: 16px 16px 22px 16px;
    margin: -16px -16px 0 -16px;
    margin-bottom: 0px;
    z-index: 10;
  }
}
</style>
