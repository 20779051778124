<template>
  <div class="blocks-grid" v-if="!ifEmpty">
    <info-block borderless>
      <template #title>{{ formatDate(deviceGroupItem.created_at) }}</template>
      <template #descr>Group added</template>
    </info-block>

    <info-block borderless>
      <template #title>{{ formatDate(deviceGroupItem.updated_at) }}</template>
      <template #descr>Last modified</template>
    </info-block>

    <info-block borderless>
      <template #title>{{ getTarifName(deviceGroupItem.tarif_str) }}</template>
      <template #descr>Tarif type</template>
    </info-block>

    <info-block borderless>
      <template #title>
        <div class="group-type-title">
          <div class="static-title">
            {{ getGroupTypeName(deviceGroupItem.group_type) }}
          </div>
        </div>
      </template>
      <template #descr>Group type</template>
    </info-block>
  </div>
</template>

<script>
import Moment from "moment";
import { mapGetters } from "vuex";

const tarifTypes = {
  TARIF_PER_TRX: "Per transition",
  TARIF_PER_SENSOR: "Per sensor",
  TARIF_PER_SENSOR_SMART: "Per sensor smart",
};

export default {
  computed: {
    ...mapGetters({
      settings: "constants/settings",
      loadingDeviceGroup: "deviceGroup/loading",
      deviceGroupItem: "deviceGroup/item",
    }),

    ifEmpty() {
      return this.$_.isEmpty(JSON.parse(JSON.stringify(this.deviceGroupItem)));
    },
  },
  methods: {
    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY");
    },
    getGroupTypeName(deviceTypeId) {
      const { types } = this.settings.deviceGroup;
      const arr = Object.values(types);

      return arr.find((el) => el.id == deviceTypeId).name;
    },
    getTarifName(tarifStr) {
      return tarifTypes[tarifStr];
    },
  },
};
</script>

<style lang="scss">
.group-type-title {
  .static-title {
    display: flex;
    align-items: center;
  }

  .type-selector {
    display: flex;
    align-items: center;
    .input {
      min-width: 150px;
      max-width: 150px;
    }

    .cancel-button {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>
