<template>
  <div class="payment-container">
    <div class="label">
      <h3>Payment successful</h3>
      <h4>{{ status }}</h4>
      <button-input class="button-input" @click.native="ReturnToPage">
        <template>Return to Finances Page</template>
      </button-input>
    </div>
  </div>
</template>

<script>
import FinancesApi from "@/api/finances";

export default {
  name: "PaymentSuccessView",
  data () {
    return {
      status: ''
    }
  },
  created () {
    const sessionId = localStorage.getItem('stripe_session_id')
    FinancesApi.paymentStatus(sessionId).then(r => {
      this.status = 'Your payment has status: ' + r.data[0].statusName.name
    }).catch(() => {
      this.status = 'Cannot retrive payment information: request error'
    })
  },
  methods: {
    ReturnToPage () {
      this.$router.push('/finances')
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-container {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  .label {
    box-shadow: $defaultShadow;
    border-radius: 6px;
    width: 300px;
    height: 200px;
    display: flex;
    background: $white;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 16px;
    h3 {
      @include h3;
      color: $notBlack;
    }
    h4 {
      color: $notBlack;
    }
  }
}
</style>
