<template>
  <div class="settings-segment" v-if="gatewayGroup">
    <div class="form">
      <div class="inputs">
        <label>
          Gateway group name
          <text-input v-model="form.name" :disabled="isDisabled" />
        </label>
      </div>

      <div class="inputs">
        <label>
          Gateway group type
          <select-input
            id="group_type"
            v-model="gateGroupsType"
            :options="gateGroupsTypes"
            disabled
          >
            <template #single-label>{{ gateGroupsType.name }}</template>
            <template #option="{ option }">{{ option.name }}</template>
          </select-input>
        </label>
      </div>

      <div class="inputs">
        Use encryption
        <checkbox-input v-model="form.useEncryption" :disabled="isDisabled" />
      </div>
      <div class="inputs">
        Use signature
        <checkbox-input v-model="form.useSignature" :disabled="isDisabled" />
      </div>
    </div>

    <div class="actions">
      <button-input
        @click.native="update"
        :disabled="isDisabled"
        class="apply-button"
      >
        Apply
      </button-input>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        name: null,
        useEncryption: false,
        useSignature: false,
        groupType: null,
        groupTypeName: null,
      },

      gateGroupsType: null,
    };
  },

  watch: {
    loading: {
      immediate: true,
      handler: function () {
        if (this.loading == false) {
          this.form.name = this.gatewayGroup.name;
          this.form.useEncryption = this.gatewayGroup.use_encryption == 1;
          this.form.useSignature = this.gatewayGroup.use_signature == 1;
          this.form.groupType = this.gatewayGroup.group_type;

          this.gateGroupsType = this.gateGroupsTypes.find((item) => {
            return item.value === parseInt(this.gatewayGroup.group_type);
          });

          this.form.groupTypeName = this.gateGroupsType.name;
        }
      },
    },

    gateGroupsType() {
      if (!this.gateGroupsType) return;
      this.form.groupType = this.gateGroupsType.value;

      this.form.groupTypeName = this.gateGroupsType.name;
    },
  },

  computed: {
    ...mapGetters({
      settings: "constants/settings",
      gatewayGroup: "gatewayGroup/item",
      loading: "gatewayGroup/loading",
    }),

    isDisabled() {
      // Disabled if not confirmed
      return this.gatewayGroup.confirmed != 1;
    },

    gateGroupsTypes() {
      if (!this.settings || !this.settings.gatewayGroups) return [];

      const gateTypes = Object.entries(this.settings.gatewayGroups.types);

      const formatted = gateTypes.map((item) => {
        return {
          name: item[1].name,
          value: item[1].id,
        };
      });

      return formatted;
    },

    gatewayGroupId() {
      return this.$route.params.id;
    },
  },

  methods: {
    ...mapActions({
      updateGatewayGroup: "gatewayGroup/updateItem",
    }),
    async update() {
      const data = {
        blockchain_uid: this.gatewayGroupId,
        name: this.form.name,
        group_type: this.form.groupTypeName,
        use_encryption: this.form.useEncryption ? 1 : 0,
        use_signature: this.form.useSignature ? 1 : 0,
      };

      try {
        await this.updateGatewayGroup(data);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-segment {
  height: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    flex-direction: column;

    margin-left: 64px;
    margin-top: 64px;
    width: 430px;
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 22px;
      margin-bottom: 14px;

      &.wide {
        grid-template-columns: 1fr;
      }

      label {
        @include text-body;
        color: $secondaryText;
      }

      & ::v-deep .multiselect[disabled] .multiselect__single {
        background: transparent;
      }
    }
  }

  .actions {
    .apply-button {
      margin-left: auto;
    }
  }
}
</style>
