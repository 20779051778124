<template>
  <div class="st">
    <transition :name="error ? 'fade-in' : 'fade-in-out'" @afterEnter="endAnimation">
      <div v-if="animating && success" class="status" :class="{ 'success': success }">
        <checkmark-icon fill="currentColor" class="icon" />
      </div>
      <div v-else-if="error" class="status" :class="{ 'error': error }" @click="retry">
        <cross-icon fill="currentColor" class="icon icon-error" />
        <move-tag-icon fill="currentColor" class="icon icon-retry" />
      </div>
    </transition>
  </div>
</template>

<script>
import CheckmarkIcon from "@/components/Icons/Checkmark";
import CrossIcon from "@/components/Icons/Cross";
import MoveTagIcon from "@/components/Icons/MoveTag";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CheckmarkIcon,
    CrossIcon,
    MoveTagIcon
  },

  watch: {
    success() {
      this.animating = true;
    },

    error() {
      this.animating = true;
    }
  },

  data() {
    return {
      animating: false
    };
  },

  methods: {
    endAnimation() {
      this.animating = false;
    },

    retry() {
      this.$emit("retry");
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-in-out-enter-active {
  animation: fadeInOut 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.fade-in-enter-active {
  animation: fadeIn 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  30%,
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.status {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: $defaultShadow;
  transition: ease-out 0.2s;

  &.success {
    background: $accentGreen;
  }

  &.error {
    background: $accentRed;
    cursor: pointer;
    
    &:hover {
      background: $accentBlue;
      .icon-error,
      .status:active .icon-error {
        opacity: 0;
      }

      .icon-retry,
      .status:active .icon-retry {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }

  .icon {
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    width: 70%;
    height: 70%;
    color: $white;

    &.icon-error {
      transition: 0.2s ease-in;
    }

    &.icon-retry {
      opacity: 0;
      transition: 0.4s ease-out;
    }
  }
}

.st {
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    margin-top: 6px;
    background: white;
    border-radius: 6px;
  }
}
</style>