var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.deviceGroup)?_c('div',{staticClass:"devices-segment"},[(!_vm.ifTableVisible)?_c('welcome-screen',{attrs:{"title":"Add files","importType":"json","disabled":_vm.isDisabled},on:{"import":_vm.onImport}}):_c('div',{staticClass:"devices-table-container"},[_c('devices-table-heading',{staticClass:"actions",attrs:{"showInteraction":_vm.hasInteraction},on:{"move":_vm.onMoveToDeviceGroup,"update":_vm.onUpdateToDevice}}),_c('table-component',_vm._b({staticClass:"devices-table",on:{"sort-by":_vm.onSort,"hit-bottom":_vm.onHitBottom},scopedSlots:_vm._u([{key:"header-chosen",fn:function(){return [_c('checkbox-input',{on:{"input":_vm.updateRows},model:{value:(_vm.allRows),callback:function ($$v) {_vm.allRows=$$v},expression:"allRows"}})]},proxy:true},{key:"row-chosen",fn:function(ref){
var item = ref.item;
return [_c('checkbox-input',{attrs:{"disabled":_vm.isDisabled},on:{"input":_vm.updateAllRowsCheckbox},model:{value:(item.chosen),callback:function ($$v) {_vm.$set(item, "chosen", $$v)},expression:"item.chosen"}})]}},{key:"row-status",fn:function(ref){
var item = ref.item;
return [_c('switch-input',{attrs:{"disabled":_vm.isDisabled},on:{"input":function($event){return _vm.deviceStatusChanged(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"row-hash",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.hash))])]}},{key:"row-updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#9c9eb1"}},[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]}},{key:"row-created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#9c9eb1"}},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"row-battery_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#9c9eb1"}},[_vm._v(_vm._s(_vm.formatBattery(item.battery_status)))])]}}],null,false,3227272904)},'table-component',_vm.table,false))],1),_c('import-devices-popup',_vm._b({on:{"close":_vm.closeImportPopUp}},'import-devices-popup',_vm.importData,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }