export const mixin = {
  data() {
    return {
      table: {
        // gridTemplate: [
        //   ".5fr",
        //   "1fr",
        //   "1fr",
        //   "1fr",
        //   "1fr",
        //   ".3fr",
        //   ".3fr",
        //   ".3fr",
        //   "1fr",
        // ],
        columns: [
          { key: "status", title: "On/Off", sortable: true },
          { key: "name", title: "Group name", type: "bold", sortable: true },
          { key: "confirmed", title: "Confirmed", sortable: true },
          { key: "data", title: "Data" },
          { key: "group_type", title: "Group type", sortable: true },
          { key: "active_gateways", title: "Active gateways", sortable: true },
          { key: "inactive_gateways", title: "Inactive gateways", sortable: true },
          { key: "data_month", title: "Data /mo", sortable: true },
          { key: "earned_month", title: "Earned /mo", sortable: true },
          { key: "updated_at", title: "Last modified", sortable: true },
          { key: "action" }
        ],
        data: []
      }
    }
  }
}
