<template>
  <popup v-bind="$attrs" disableFooterPadding @close="closeFilter">
    <template slot="header">
      <div class="header-inner-container">
        <div class="header-title">
          <slot name="header-title"></slot>
        </div>
        <search-input @search="onSearch" />
      </div>
    </template>

    <div slot="content" class="content">
      <slot name="content">
        <div class="device-group-item" v-for="group in filteredOptions" :key="group.blockchain_uid">
          <radio-input
            @input="onRadioInput(group)"
            class="item"
            :selected="group.selected"
            :value="group.value"
          />
          <span class="item device-group-label">{{ group.name }}</span>
        </div>
      </slot>
    </div>

    <template slot="actions">
      <slot name="actions">
        <div class="actions-buttons">
          <button-input class="button" @click.native="closeFilter" :primary="false">
            <span>Cancel</span>
          </button-input>

          <button-input class="button" @click.native="moveDevices">
            <span>Move</span>
          </button-input>
        </div>
      </slot>
    </template>
  </popup>
</template>

<script>
import Fuse from "fuse.js";

export default {
  name: "move-devices-popup",

  props: {
    options: {
      type: Array,
      required: true
    },

    applied: {
      type: Array,
      required: true
    },

    deviceGroups: {
      type: Array,
      required: true
    }
  },

  computed: {
    deviceGroupsLength: function() {
      return this.deviceGroups.length;
    },

    filteredOptions() {
      if (!this.query) {
        return this.localDeviceGroups;
      }

      const fuse = new Fuse(this.localDeviceGroups, {
        keys: ["name"]
      });
      return fuse.search(this.query);
    }
  },

  watch: {
    deviceGroupsLength: function() {
      this.initDeviceGroups();
    }
  },

  created() {
    this.initDeviceGroups();
  },

  methods: {
    initDeviceGroups() {
      if (this.deviceGroups.length > 0) {
        let copy = JSON.parse(JSON.stringify(this.deviceGroups));
        this.localDeviceGroups = copy.map((deviceGroup, index) => {
          this.$set(deviceGroup, "selected", index == 0 ? true : false);
          this.$set(deviceGroup, "value", index == 0 ? true : false);
          return deviceGroup;
        });
      }
    },

    closeFilter() {
      this.$emit("close");
    },

    onSearch(val) {
      this.query = val;
    },

    onRadioInput(deviceGroup) {
      for (let dg of this.localDeviceGroups) {
        dg.value = false;
        dg.selected = false;
      }

      deviceGroup.value = true;
      deviceGroup.selected = true;
    },

    moveDevices() {
      for (let dg of this.localDeviceGroups) {
        if (dg.value && dg.selected) {
          this.$emit("move", dg);
          break;
        }
      }
    },

    toggleFilter(filter) {
      this.$emit("toggle-filter", filter);
    }
  },

  data() {
    return {
      query: "",
      localDeviceGroups: []
    };
  }
};
</script>

<style lang="scss" scoped>
.header-inner-container {
  display: flex;
  flex-direction: column;
  .header-title {
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.content {
  max-height: 320px;

  .device-group-item {
    display: flex;
    padding: 5px;
    

    .item:first-child {
      margin-right: 10px;
    }

    .device-group-label {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.actions-buttons {
  display: flex;
  padding: 10px;
  .button {
    &:first-child {
      margin-right: 5px;
    }
  }
}
</style>
