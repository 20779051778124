<template>
  <div class="gateways-view">
    <moe-block heading="Gateway groups" :loading="genericLoading">
      <template #actions>
        <router-link :to="{ name: 'gateway-group-create' }">
          <button-input :primary="false">
            <plus-icon slot="icon" fill="currentColor" />
            <template>Add gateway group</template>
          </button-input>
        </router-link>
      </template>

      <template #content>
        <div class="gateways-container">
          <div class="fixed-container">
            <mapbox
              class="map"
              access-token="pk.eyJ1IjoibWF4aXNtIiwiYSI6ImNqbDg0bHg4dzBkNjczdm84ZjRqZnFpcWMifQ.lekJSHeqixAGad36DXbEWg"
              :map-options="{
                style: 'mapbox://styles/amidnikmal/ck666t6v4350a1iqlbqldmkfz',
                center,
                zoom: 10,
              }"
              :nav-control="{ show: true, position: 'bottom-right' }"
              @map-load="mapLoaded"
            />
          </div>

          <table-component
            class="gateway-groups-table"
            v-bind="table"
            @sort-by="onSort"
          >
            <template #row-status="props">
              <switch-input
                v-model="props.item.status"
                @input="onGatewaGroupSwitch(props.item)"
              />

              <!--                 :disabled="props.confirmed != 1" -->
            </template>

            <template #row-confirmed="{ item }">
              <tag-wrapper v-bind="item" />
            </template>

            <template #row-data="props">
              <inline-chart
                v-if="!listLoading"
                :gatewayGroupUid="props.item.blockchain_uid"
              />
            </template>

            <template #row-updated_at="props">
              <span>{{ formatDate(props.item.updated_at) }}</span>
            </template>

            <!-- 
            <template #row-earned_month="props">{{
              props.item.earned_month ? props.item.earned_month : 0
            }}</template> -->

            <template #row-group_type="props">
              {{
                props.item.group_type
                  ? getGateGroupType(props.item.group_type)
                  : "Wrong type"
              }}
            </template>

            <template #row-action="props">
              <button-input
                class="stretched"
                :primary="true"
                @click.native="onConfigureClick(props)"
              >
                <!--          :disabled="props.confirmed != 1" -->

                Configure
              </button-input>
            </template>
          </table-component>
        </div>
      </template>
    </moe-block>
  </div>
</template>

<script>
import Moment from "moment";
import PlusIcon from "@/components/Icons/Plus";
import { Table as TableComponent } from "@moeco-platform/moeco-components";

import Mapbox from "mapbox-gl-vue";
import mapboxgl from "mapbox-gl";

import * as Turf from "@turf/turf";

import InlineChart from "./GatewayGroup/InlineChart";
import { mapActions, mapGetters } from "vuex";
import { mixin as tableMixin } from "./mixins/table";
import TagWrapper from "@/components/TagWrapper";

export default {
  name: "gateway-groups-view",

  mixins: [tableMixin],

  components: {
    PlusIcon,
    TableComponent,
    Mapbox,
    InlineChart,
    TagWrapper,
  },

  beforeMount() {
    this.fetch();
  },

  data() {
    return {
      map: null,
      center: [37.18139, 55.9825],
    };
  },

  watch: {
    list: {
      immediate: true,
      handler() {
        this.table.data = this.normalizeData(this.list);
      },
    },
  },

  computed: {
    ...mapGetters({
      list: "gatewayGroups/list",
      listLoading: "gatewayGroups/loading",
      itemLoading: "gatewayGroup/loading",
      settings: "constants/settings",
    }),

    genericLoading: function () {
      return this.listLoading || this.itemLoading;
    },
  },

  methods: {
    ...mapActions({
      getList: "gatewayGroups/getList",
      updateGatewayGroup: "gatewayGroup/updateItem",
      changeParams: "gatewayGroups/changeParams",
    }),

    async fetch() {
      try {
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onConfigureClick(props) {
      this.$router.push({
        name: "gateway-group",
        params: { id: props.item.blockchain_uid },
      });
    },

    async onGatewaGroupSwitch(gatewayGroup) {
      try {
        const copy = JSON.parse(JSON.stringify(gatewayGroup));

        const groupType = copy.group_type;
        const { typesById } = this.settings.gatewayGroups;
        const groupTypeName = typesById[groupType].name;
        copy.group_type = groupTypeName;

        await this.updateGatewayGroup(copy);
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onSort(value) {
      this.changeParams({ orderBy: { value } });
      this.fetch();
    },

    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY");
    },

    normalizeData(list) {
      return list.map((item) => ({
        ...item,
        status: item.status ? true : false,
      }));
    },

    getGateGroupType(gateGroupId) {
      if (!gateGroupId) return "Wrong type";

      const { types } = this.settings.gatewayGroups;

      let gateGroupType = Object.entries(types).find(
        (item) => item[1].id == gateGroupId
      );

      return gateGroupType
        ? Object.entries(gateGroupType)[1][1].name
        : "Wrong type";
    },

    mapLoaded(map) {
      this.generateFakeData(map);
    },

    drawPolygon(id, center, radius, map) {
      const options = {
        steps: 32,
        units: "kilometers",
        properties: { color: "red" },
      };
      const circle = Turf.circle(center, radius, options);

      map.addSource("test-points" + id, {
        type: "geojson",
        data: circle,
      });

      map.addLayer({
        id: "test-points-line" + id,
        type: "line",
        source: "test-points" + id,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#088",
          "line-width": 3,
        },
      });

      map.addLayer({
        id: "test-points-polygon" + id,
        type: "fill",
        source: "test-points" + id,
        layout: {},
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.5,
        },
      });
    },

    generateFakeData(map) {
      const point = Turf.point(this.center);
      const distance = 300;
      const bearing = 45;
      const options = { units: "kilometers" };
      const destination = Turf.destination(point, distance, bearing, options);
      const destination2 = Turf.destination(point, -distance, bearing, options);
      const points = Turf.randomPoint(30, {
        bbox: [
          ...destination2.geometry.coordinates,
          ...destination.geometry.coordinates,
        ],
      });

      const bbox = Turf.bbox(points);
      map.fitBounds(mapboxgl.LngLatBounds.convert(bbox), { padding: 50 });

      // TODO: temporarily commented

      /*    
      let index = 0;
      
      for (let point of points.features) {
        const coords = point.geometry.coordinates;
        // let el = document.createElement("div");
        // const altType = Math.random() >= 0.5;
        // el.innerHTML = `
        //   <div class="mapboxgl-custom-marker ${altType ? "alt-type" : null}">
        //     <div class="transparent-body"></div>
        //     <div class="border"></div>
        //   </div>
        // `;
        // new mapboxgl.Marker(el).setLngLat(coords).addTo(map);

        let radius  = Faker.random.number({min:5, max:30});
        this.drawPolygon(index, coords, radius, map);

        index++;
      }

      map.addSource("test-points", {
        type: "geojson",
        data: points
      });

      map.addLayer({
        id: "test",
        type: "circle",
        source: "test-points",
        // "source-layer": "sf2010",
        paint: {
          // make circles larger as the user zooms from z12 to z22
          "circle-radius": {
            base: 200,
            stops: [
              [12, 2],
              [22, 180]
            ]
          }
        }
      });

      */
    },
  },
};
</script>

<style lang="scss" scoped>
.gateways-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .map {
    margin-top: 10px;
    width: 100%;
    height: 100%;
  }

  .gateways-container {
    display: flex;
    flex-direction: column;
    // flex: 1;
    height: 100%;

    .fixed-container {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
    }

    .gateway-groups-table {
      // width: min-content;
      margin-top: 20px;
      padding-right: 15px;
      flex-basis: 60%;

      .flex {
        display: flex;
        flex: 1;
        text-decoration: none;

        .stretched {
          flex: 1;
        }
      }
    }
  }
}
</style>
