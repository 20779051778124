import GatewayGroupsStatApi from "@/api/gateway_groups_stat";
// import Faker from "faker";
// import Moment from "moment";

export default {
  namespaced: true,
  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {
      period: {
        from: null,
        to: null,
      },
      detalisation: "day"
    }
  },

  actions: {
    async getItem(ctx, params) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);

      try {
        const id = params.id;
        const result = await GatewayGroupsStatApi.getTxChart({ id, ...ctx.state.params });
        ctx.commit("setItem", result.data);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "gateway_group_stat_chart", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    changeParams(context, params) {
      context.commit('changeParams', params);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data)
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    params: state => state.params,
    item: state => state.item
  }
};
