<template>
  <popup v-bind="$attrs" @close="closeFilter">
    <template slot="header">
      <div class="header-inner-container">
        <div class="header-title">
          <slot name="header-title"></slot>
        </div>
        <search-input @search="onSearch" />
      </div>
    </template>

    <div slot="content" class="content">
      <applied-filters :data="applied" v-on:disable-filter="disableFilter" />
      <filter-options :data="filteredOptions" v-on:toggle-filter="toggleFilter" />
    </div>

    <template slot="actions">
      <slot name="actions">
        <button-input class="apply-button" @click.native="applyFilters">
          <span>Apply filters</span>
        </button-input>
      </slot>
    </template>
  </popup>
</template>

<script>
import Fuse from "fuse.js";
import FilterOptions from "./FilterOptions";
import AppliedFilters from "./AppliedFilters";

export default {
  name: "filters-popup",

  components: {
    FilterOptions,
    AppliedFilters
  },

  props: {
    options: {
      type: Array,
      required: true
    },

    applied: {
      type: Array,
      required: true
    }
  },

  computed: {
    filteredOptions() {
      const optionsIds = this.options.map(o => o.id);
      const appliedIds = this.applied.map(o => o.id);

      const filteredOptionsIds = optionsIds.filter(id => {
        return !appliedIds.includes(id);
      });

      const options = this.options.filter(o => {
        return filteredOptionsIds.includes(o.id);
      });

      if (!this.query) {
        return options;
      }

      const fuse = new Fuse(options, {
        keys: ["name"]
      });

      return fuse.search(this.query);
    }
  },

  methods: {
    closeFilter() {
      this.$emit("close");
    },

    onSearch(val) {
      this.query = val;
    },

    applyFilters() {
      this.$emit("apply-filters");
    },

    disableFilter(filter) {
      this.$emit("disable-filter", filter);
    },

    toggleFilter(filter) {
      this.$emit("toggle-filter", filter);
    }
  },

  data() {
    return {
      query: "",
      appliedFilters: []
    };
  }
};
</script>

<style lang="scss" scoped>

.header-inner-container {
  display:flex;
  flex-direction: column;
  .header-title {
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.apply-button {
  width: 100%;
}

.content {
  max-height: 320px;
}
</style>
