<template>
  <div class="tag-container" :style="{ ...colorStyle }">
    <div class="tag-value">
      {{ value }}
    </div>
  </div>
</template>

<script>
import Color from "color";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
      default: "#FF0000",
    },
  },

  computed: {
    colorStyle() {
      return {
        color: this.color,
        backgroundColor: Color(this.color).alpha(0.1),
        border: "1px solid " + Color(this.color).alpha(0.2),
      };
    },
  },
};
</script>

<style>
.tag-container {
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  height: 40px;
  display: flex;
  align-items: center;
}
</style>