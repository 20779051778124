<template>
  <highcharts :options="config" ref="chart" class="chart" />
</template>

<script>
import Moment from "moment";
import { lineConfigDefault } from "@/helpers/chartConfigs.js";

export default {
  props: ["chartData"],
  data() {
    return {
      config: {
        ...lineConfigDefault,
        chart: {
          margin: [20, 10, 20, 62]
        },
        tooltip: {
          formatter: function() {
            const template = `
              <b>Txs count</b>: ${this.y}<br>
              <b>Date</b>: ${Moment(this.x, "x").format("DD MMM HH:mm:ss")}
            `;
            return template;
          }
        },
        xAxis: {
          tickWidth: 0,
          tickInterval: 2 * 24 * 3600 * 1000,
          labels: {
            formatter: function() {
              return Moment(this.value, "x").format("DD MMM");
            },
            paddings: 0,
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4"
            }
          }
        },
        yAxis: {
          title: { enabled: false },
          labels: {
            formatter: function() {
              const label = this.axis.defaultLabelFormatter.call(this);
              return label.toUpperCase();
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4"
            }
          }
        },
        
        series: [
          {
            type: "spline",
            name: "Transmissions",
            data: []
          }
        ]
      }
    };
  },

  created() {
    this.drawPoints();
  },

  methods: {
    drawPoints() {
      this.$nextTick(() => {
        if (!this.chartData) return false;

        for (let chartItem of this.chartData) {
          this.$refs.chart.chart.series[0].addPoint({
            x: parseInt(Moment(chartItem.from).format("x")),
            y: parseInt(chartItem.count)
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.chart {
  width: 100%;
}
</style>
