<template>
  <div>
    <popup v-if="this.default" class="calendar-popup" v-bind="$attrs" :width="480" @close="$emit('close')">
      <div slot="header" class="header">
        <span class="title">Time period</span>
      </div>
      <div slot="content">
        <div class="content">
          <calendar-shortcuts :shortcuts="shortcuts" v-model="shortcut"/>
          <calendar v-model="range" :now="now" @changeNow="changeNow" />
        </div>

        <div class="apply-button-container">
          <button-input class="apply-button" @click.native="onApplyClick">Apply</button-input>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import CalendarShortcuts from "./CalendarShortcuts";
import Calendar from "./Calendar";

import Moment from "moment";

export default {
  components: {
    CalendarShortcuts,
    Calendar
  },

  props: {
    shortcuts: {
      type: Array,
      default: () => []
    },
    default: {
      type: Object
    }
  },

  created() {
    Moment.locale("en-gb");
    this.now = Moment();
  },

  data() {
    return {
      shortcut: null,
      now: null,
      range: {
        start: null,
        end: null
      }
    };
  },

  watch: {
    default: function(val) {
      if (val) {
        this.range.start = val.range.start;
        this.range.end = val.range.end;
      }
    },

    shortcut: function(shortcut) {
      this.range = {
        start: shortcut.range.start,
        end: shortcut.range.end
      };
    }
  },

  methods: {
    changeNow(date) {
      this.now = Moment(date);
    },

    onApplyClick() {
      this.$emit('range', {
        ...{ label: (this.shortcut) ? this.shortcut.label : null },
        ...{ start: this.range.start, end: this.range.end }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  background: $grey10;
  height: 294px;
}

.apply-button-container {
  display: flex;
  width: 100%;
  .apply-button {
    margin-left: auto;
  }
}
</style>
