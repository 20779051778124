import FinancesApi from "@/api/finances";
import TransactionsApi from "@/api/transactions";
import Moment from "moment";

export default {
  namespaced: true,

  state: {
    list: [],
    meta: {},
    loading: false,
    error: null,

    period: TransactionsApi.allowedPeriods[1],
    range: null,

    params: {
      orderBy: {
        value: 'uid',
        desc: false
      },

      filter: 'all',
      timestamp__gte: null,
      timestamp__lte: null
    }
  },

  actions: {
    async getList(ctx) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);

      try {
        const {
          data,
          meta
        } = await FinancesApi.getDetailedList(ctx.getters.params);

        ctx.commit("setList", data);
        ctx.commit("setMeta", meta);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "finances_details", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    changeParams(context, params) {
      if (params.orderBy) {
        if (context.state.params.orderBy.value === params.orderBy.value) {
          params.orderBy.desc = !context.state.params.orderBy.desc;
        } else {
          params.orderBy.desc = true;
        }
      }

      context.commit('changeParams', params);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      state.list = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data);
    }
  },

  getters: {
    loading: state => state.loading,
    error: state => state.error,
    meta: state => state.meta,
    list: state => (state.list ? state.list : []),
    params: state => state.params,
    title: state => {
      if (!state.params.timestamp__gte && !state.params.timestamp__lte) {
        return "";
      }

      const start = Moment(state.params.timestamp__gte);
      const end = Moment(state.params.timestamp__lte);

      if (start.diff(end, 'days') == 0) {
        return start.format("D MMMM, YYYY");
      }

      return `${start.format("D MMMM, YYYY")}-${end.format("D MMMM, YYYY")}`;
    }
  }
};
