<template>
  <div class="payment-card" :class="item.cardType">
    <div class="payment-content">
      <div class="card-type-wrapper">
        <visa v-if="isVisa" />
        <visa v-else-if="isMastercard" />
        <span v-else class="card-type-text">{{ item.cardType }}</span>
      </div>
      <dots class="card-menu" @click="openMenu" />
      <div v-if="hasExpiration" class="expiration-wrapper">
        <span class="expires-at-label">Expires</span>
        <span class="expires-at">{{ expireDate }}</span>
      </div>
      <div v-if="hasHolder" class="holder-info">
        <span class="card-holder-label">Card holder</span>
        <span class="card-holder">{{ item.holderName }}</span>
      </div>
      <div class="card-number">
        <template v-if="isVisa || isMastercard">
          <span class="digit-group">****</span>
          <span class="digit-group">****</span>
          <span class="digit-group">****</span>
        </template>
        <template v-else>
          <span class="digit-group">**************</span>
        </template>
        <span class="digit-group">{{ lastDigits }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Visa from "@/components/Icons/Visa";
import Dots from "@/components/Icons/Dots";

export default {
  components: {
    Visa,
    Dots
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    expireDate() {
      const formattedMonth = `0${this.item.expMonth}`.slice(-2);
      const formattedYear = `${this.item.expYear}`.slice(-2);

      return `${formattedMonth}/${formattedYear}`;
    },

    lastDigits() {
      return `${this.item.number}`.slice(-4);
    },

    isVisa() {
      return this.item.cardType == "visa";
    },

    isMastercard() {
      return this.item.cardType == "mastercard";
    },

    hasExpiration() {
      return !!this.item.expMonth && !!this.item.expYear;
    },

    hasHolder() {
      return !!this.item.holderName;
    },

    classes() {
      return {
        visa: this.isVisa,
        mastercard: this.isMastercard
      }
    }
  },

  methods: {
    openMenu() {}
  }
};
</script>

<style lang="scss" scoped>
.payment-card {
  width: 295px;
  height: 174px;
  border-radius: 6px;
  padding: 6px 20px 13px 20px;
  background: linear-gradient(121.05deg, $notBlack 0%, $notBlack 100%);
  color: $white;

  &.visa {
    background: linear-gradient(121.05deg, $notBlack 0%, $accentBlue 100%);
  }

  .payment-content {
    position: relative;
    width: 100%;
    height: 100%;

    .card-type-wrapper {
      display: flex;
      align-items: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;

      .card-type-text {
        @include h2;
        letter-spacing: 0.1px;
        line-height: 40px;
      }
    }

    .card-menu {
      position: absolute;
      top: 8px;
      right: 0;
      width: 24px;
      height: 24px;
      transition: opacity $defaultDuration ease-out;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    .expiration-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      right: 0;

      .expires-at-label {
        @include text-small;
        text-align: right;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        opacity: 0.8;
      }

      .expires-at {
        @include h2;
        text-align: right;
        letter-spacing: 0.1px;
      }
    }

    .holder-info {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      left: 0;

      .card-holder-label {
        @include text-small;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        opacity: 0.8;
      }

      .card-holder {
        @include h2;
        letter-spacing: 0.1px;
      }
    }

    .card-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 61px;
      width: 100%;

      .digit-group {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 2px;

        &:not(:last-child) {
          font-weight: normal;
          font-size: 20px;
          letter-spacing: 5px;
        }
      }
    }
  }
}
</style>