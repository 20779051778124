<template>
  <div class="data-view-heading">
    <calendar-popup
      v-bind="calendar"
      :shortcuts="periodOptions"
      :default="periodOptions[1]"
      @close="closeCalendar"
      @range="rangeChanged"
    />

    <search-input class="search-input" @search="filterByString" />

    <select-input
      class="device-groups-filter"
      placeholder="Select device groups"
      :value="deviceGroupFilter"
      :options="deviceGroups.options"
      track-by="name"
      label="name"
      @input="deviceGroupSelected"
      :allow-empty="false"
    />

    <button-input
      :primary="false"
      linear
      class="period-filter"
      @click.native="openCalendar"
    >
      <calendar-icon slot="icon" fill="currentColor" class="calendar" />
      <template #default>
        <span>{{ rangeLabel }}</span>
      </template>
    </button-input>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CalendarIcon from "@/components/Icons/Calendar";
// import FaucetFilter from "@/components/Popups/Filters/FaucetFilter";
import CalendarPopup from "@/components/Popups/Calendar/CalendarPopup";

export default {
  name: "data-view-heading",

  components: {
    CalendarIcon,
    // FaucetFilter,
    CalendarPopup,
  },

  data() {
    return {
      deviceGroupFilter: { name: "All sensor groups" },

      rangeLabel: "",

      deviceGroups: {
        options: [],
        value: { name: "All sensor groups" },
      },

      calendar: {
        width: 480,
        opened: false,
        arrowUp: true,
        assignTo: null,
      },
    };
  },

  methods: {
    ...mapMutations({
      setLazyList: "transactions/setLazyList",
    }),

    ...mapActions({
      getListWithChart: "transactions/getListWithChart",
      getDeviceGroups: "deviceGroups/getList",
      changeParams: "transactions/changeParams",
    }),

    filterByString(value) {
      this.stringFilter = value;
    },

    deviceGroupSelected(dg) {
      if (!this.deviceGroupFilter) {
        this.deviceGroupFilter = dg;
        return;
      }

      if (dg.blockchain_uid !== this.deviceGroupFilter.blockchain_uid) {
        this.deviceGroupFilter = dg;
      }
    },

    rangeChanged(range) {
      this.rangeLabel = range.label ? range.label : "Some period";
      this.rangeFilter = range;
      this.calendar.opened = false;
    },

    openCalendar(ev) {
      Object.assign(this.calendar, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    closeCalendar() {
      this.calendar.opened = false;
    },

    resetFilters() {
      this.changeParams({
        range: null,
        term: null,
        device__device_group_uid: null,
        limit: { offset: 0, count: 100 },
      });
    },
  },

  computed: {
    ...mapGetters({
      periodOptions: "transactions/periods",
      params: "transactions/params",
      deviceGroupsList: "deviceGroups/list",
    }),

    stringFilter: {
      get() {
        return this.params.term;
      },

      set(term) {
        this.changeParams({ term, limit: { offset: 0, count: 100 } });
        this.setLazyList([]);
        this.getListWithChart();
      },
    },

    /*
    deviceGroupFilter: {
      get() {
        return this.deviceGroups.value;
      },
      set(deviceGroup) {
        // console.log("SET", JSON.parse(JSON.stringify(deviceGroup)));

        this.deviceGroups.value = deviceGroup;
        this.changeParams({
          device__device_group_uid: deviceGroup.blockchain_uid,
          limit: { offset: 0, count: 100 },
        });
        this.setLazyList([]);
        this.getListWithChart();
      },
    },
    */

    rangeFilter: {
      get() {
        return this.params.range;
      },
      set(range) {
        // TODO: firstly data loaded here; not good;
        this.changeParams({ range, limit: { offset: 0, count: 100 } });
        this.setLazyList([]);
        this.getListWithChart();
      },
    },
  },

  watch: {
    deviceGroupFilter(_, dg) {
      console.log("dg", this.deviceGroupFilter);

      if (!this.deviceGroupFilter) {
        return;
      }

      if (!dg || dg.blockchain_uid !== this.deviceGroupFilter.blockchain_uid) {
        this.deviceGroups.value = this.deviceGroupFilter;

        this.changeParams({
          device__device_group_uid: this.deviceGroupFilter.blockchain_uid,
          limit: { offset: 0, count: 100 },
        });

        this.setLazyList([]);

        this.getListWithChart();
      }
    },
  },

  beforeMount() {
    this.getDeviceGroups().then(() => {
      this.deviceGroups.options = JSON.parse(
        JSON.stringify(this.deviceGroupsList)
      );
      this.deviceGroups.options.unshift({ name: "All sensor groups" });
    });

    this.rangeFilter = this.periodOptions[1].range;
    this.rangeLabel = this.periodOptions[1].label;
  },

  beforeDestroy() {
    this.resetFilters();
  },
};
</script>

<style lang="scss" scoped>
.data-view-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    max-width: 300px;
    margin-left: 0;
    margin-right: auto;
  }

  .filter-button {
    min-width: 40px;
    max-width: 64px;
    margin-right: 16px;
  }

  .device-groups-filter {
    max-width: 200px;
    margin-right: 16px;
  }

  .period-filter {
    max-width: 200px;

    .option__desc {
      display: flex;
      align-items: center;

      .calendar {
        margin-right: 8px;
      }
    }
  }
}
</style>
