const STRIPE_CHECKOUT_URL = 'https://js.stripe.com';

export const injectStripe = (version = 'v3') => {
  if (window.Stripe) return;

  let e = document.createElement('script');
  e.src = `${STRIPE_CHECKOUT_URL}/${version}`;
  e.type='text/javascript';
  document.getElementsByTagName('head')[0].appendChild(e);
  return new Promise((resolve) => {
    e.addEventListener('load', () => {
      resolve();
    });
  })
}
