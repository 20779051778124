<template>
  <div class="devices-view">
    <moe-block heading="Sensor groups" :loading="loading || updateLoading">
      <template #heading>
        <search-input class="search" @search="onSearch" />
      </template>

      <template #actions>
        <router-link :to="{ name: 'device-group-create' }">
          <button-input v-if="table.data.length" :primary="false">
            <plus-icon slot="icon" fill="currentColor" />
            <template>Add sensors group</template>
          </button-input>
        </router-link>
      </template>

      <template #content>
        <div class="empty" v-if="!ifTableVisible">
          <div class="image" />
          <h1 class="heading">You do not have sensors groups</h1>
          <router-link :to="{ name: 'device-group-create' }">
            <button-input>
              <plus-icon slot="icon" fill="currentColor" />
              <span>Add sensors group</span>
            </button-input>
          </router-link>
        </div>

        <table-component
          v-else
          class="device-groups-table"
          v-bind="table"
          @sort-by="onSort"
        >
          <template #header-inactive_devices="{ column }">
            <span>{{ column.title }}</span>
          </template>

          <template #header-low_battery="{ column }">
            <battery-low-icon v-if="column.icon == 'battery'" />
          </template>

          <template #row-status="{ item }">
            <switch-input
              v-model="item.status"
              @input="onDeviceGroupSwitch(item)"
            />
            <!-- :disabled="item.confirmed != 1" -->
          </template>
          <template #row-name="{ item }">
            <span
              class="device-group-title"
              :class="{ inactive: !item.status }"
              >{{ item.name }}</span
            >
          </template>

          <template #row-confirmed="{ item }">
            <tag-wrapper v-bind="item" />
          </template>

          <template #row-data="{ item }">
            <inline-chart
              v-if="!loading"
              :deviceGroupUid="item.blockchain_uid"
            />
          </template>
          <template #row-group_type="{ item }">
            <span>{{ getGroupTypeName(item.group_type) }}</span>
          </template>
          <template #row-inactive_devices="{ item }">
            <span>{{ item.inactive_devices }}</span>
          </template>
          <template #row-low_battery="{ item }">
            <BatteryStatus
              v-if="!loading"
              :deviceGroupUid="item.blockchain_uid"
            />
          </template>
          <template #row-updated_at="{ item }">
            <span>{{ formatDate(item.updated_at) }}</span>
          </template>

          <template #row-action="{ item }">
            <button-input
              class="stretched"
              :primary="true"
              @click.native="onConfigureClick(item)"
            >
              <!-- :disabled="item.confirmed != 1" -->
              Configure
            </button-input>
          </template>
        </table-component>
      </template>
    </moe-block>
  </div>
</template>

<script>
import PlusIcon from "@/components/Icons/Plus";
import BatteryLowIcon from "@/components/Icons/BatteryLow";
import { Table as TableComponent } from "@moeco-platform/moeco-components";
import InlineChart from "@/components/Charts/InlineChart";
import TagWrapper from "@/components/TagWrapper";
import BatteryStatus from "./BatteryStatus";
import Moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { mixin as tableMixin } from "./mixins/table";

export default {
  name: "devices-view",

  mixins: [tableMixin],

  components: {
    PlusIcon,
    BatteryLowIcon,
    TableComponent,
    InlineChart,
    TagWrapper,
    BatteryStatus,
  },

  watch: {
    list: {
      immediate: true,
      handler() {
        this.table.data = this.normalizeData(this.list);
      },
    },

    sortBy: function (sort_by) {
      this.changeParams({ sort_by });
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      list: "deviceGroups/list",
      params: "deviceGroups/params",
      loading: "deviceGroups/loading",
      updateLoading: "deviceGroup/loading",
      settings: "constants/settings",
    }),

    ifTableVisible() {
      const paramsIsSetted = !!this.params.term;
      return !(this.table.data.length == 0 && !this.loading) || paramsIsSetted;
    },
  },

  async beforeMount() {
    this.changeParams({ term: null });
    this.fetch();
  },

  methods: {
    ...mapActions({
      getList: "deviceGroups/getList",
      changeParams: "deviceGroups/changeParams",
      update: "deviceGroup/updateItem",
    }),

    async fetch() {
      try {
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onConfigureClick(item) {
      this.$router.push({
        name: "device-group",
        params: { id: item.blockchain_uid },
      });
    },

    async onDeviceGroupSwitch(deviceGroup) {
      try {
        await this.update(deviceGroup);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }

      this.fetch();
    },

    async onSearch(term) {
      this.changeParams({ term });
      this.fetch();
    },

    async onSort(value) {
      const allowed = ["status", "name", "group_type", "updated_at"];
      if (allowed.includes(value)) {
        this.changeParams({ orderBy: { value } });
        this.fetch();
      }
    },

    normalizeData(list) {
      return list.map((item) => ({
        ...item,
        status: item.status ? true : false,
        synced: item.confirmed == 1,
      }));
    },

    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY");
    },

    getGroupTypeName(deviceTypeId) {
      const arr = Object.values(this.settings.deviceGroup.types);
      const deviceType = arr.find((el) => el.id == deviceTypeId);
      return deviceType ? deviceType.name : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.devices-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  .search {
    min-width: 150px;
    max-width: 450px;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .sort-wrapper {
      display: flex;
      align-items: center;

      > span {
        margin: 0 16px;
      }
    }
  }

  .device-groups-table {
    padding-right: 15px;
    max-height: 100%;
    // flex: 1;

    .flex {
      display: flex;
      flex: 1;
      text-decoration: none;

      // .stretched {
      //   flex: 1;
      // }
    }

    .device-group-title {
      font-weight: 500;
      font-size: 18px;
      color: $notBlack;
      &.inactive {
        color: $grey50;
      }
    }
  }

  .red {
    color: $accentRed;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .image {
      width: 132px;
      height: 132px;
      border-radius: 50%;
      background: $grey30;
    }

    .heading {
      @include h0;
      color: $notBlack;
      margin: 32px 0 40px;
    }
  }
}
</style>
