<template>
  <moe-block class="gateway-group-stats">
    <template #heading>
      <router-link to="/gateway_groups">
        <back-button />
      </router-link>

      <div class="header">
        <h1 v-if="gatewayGroup">
          <div class="header-label">{{ gatewayGroup.name }}</div>
        </h1>
      </div>
    </template>

    <template #content>
      <heading-block title="Gateways" bordered>
        <gateways />
      </heading-block>

      <heading-block title="Data" bordered>
        <gateways-data />
      </heading-block>

      <heading-block title="Earnigns" bordered>
        <earnings />
      </heading-block>

      <heading-block title="Group info" bordered>
        <info />
      </heading-block>

      <heading-block title="Delete gateway group" bordered>
        <div class="delete-wrapper">
          <button-input :primary="false" @click.native="deleteGroup">
            <span class="red">Delete</span>
          </button-input>
          <span class="caption"
            >Deleted group can be recovered in 24 hours after deletion</span
          >
        </div>
      </heading-block>
    </template>
  </moe-block>
</template>

<script>
// TODO: Make generic component for all parts in `/component`
import { mapGetters, mapActions } from "vuex";

import Gateways from "./components/Gateways";
import GatewaysData from "./components/Data";
import Earnings from "./components/Earnings";
import Info from "./components/Info";

export default {
  components: {
    Gateways,
    GatewaysData,
    Earnings,
    Info,
  },

  computed: {
    ...mapGetters({
      loading: "gatewayGroup/loading",
      gatewayGroup: "gatewayGroup/item",
    }),
  },

  methods: {
    ...mapActions({
      deleteGatewayGroup: "gatewayGroup/deleteItem",
    }),

    async deleteGroup() {
      const uid = this.$route.params.id
      try {
        await this.deleteGatewayGroup(uid)
        const message = 'Gateway group deleted successfully'
        this.$toast.success(message, {
          position: "top",
          duration: 6000
        });
        this.$router.push("/gateway_groups")
      } catch (error) {
        console.warn(error)
        const message = 'Gateway group is not empty!'
        this.$toast.error(message, {
          position: "top",
          duration: 6000
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gateway-group-stats {
  .header {
    flex: 1;
    .header-label {
      display: flex;
      align-items: center;

      .edit-icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .header-name-input-container {
      display: flex;
      flex-direction: row;
      .item {
        margin-right: 5px;
      }
    }
  }

  .blocks-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 24px;
    grid-column-gap: 4px;
    margin-bottom: 14px;

    .chart-block {
      grid-row: 1 / span 2;
      grid-column: 2 / span 3;
    }
  }

  .delete-wrapper {
    display: flex;
    align-items: center;

    .red {
      color: $accentRed;
    }

    .caption {
      max-width: 220px;
      margin-left: 16px;
      color: $grey70;
    }
  }
}
</style>
