import Vue from "vue";
import app from "@/App.vue";
import store from "@/store";
import router from "@/router";

import "@/styles/base.scss";
import "@moeco-platform/moeco-components/dist/lib/moeco-components.min.css";
import "@moeco-platform/moeco-components/src/assets/fonts/stylesheet.css";
import 'vue-multiselect/dist/vue-multiselect.min.css';

import _ from 'lodash';
Vue.prototype.$_ = _;

import VueWaypoint from "vue-waypoint";
Vue.use(VueWaypoint);

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
Vue.use(VueVirtualScroller);

import VueToast from "vue-toast-notification";
Vue.use(VueToast, {
  position: "top"
});

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue);

import 'v-title/lib/element-ui';
import VTitle from 'v-title';
Vue.use(VTitle);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import { plugins, filters } from "@moeco-platform/clients";

// Retrieve authorization token 
import Cookie from "cookie_js";
const apiKey = (process.env.VUE_APP_DEBUG_AUTH_TOKEN) ? process.env.VUE_APP_DEBUG_AUTH_TOKEN : Cookie.get("token");

import Request from "@/helpers/request";

Request.setToken(apiKey);

Vue.use(
  plugins.auth,
  {
    apiKey,
    baseUrl: process.env.VUE_APP_AUTH_API,
    store
  }
);

Vue.use(
  plugins.notifications,
  {
    apiKey,
    baseUrl: process.env.VUE_APP_NOTIFICATIONS_SERVICE_API_URL,
    store
  }
);

Vue.use(plugins.checkPermission, { store });

Vue.use(plugins.dragndropPrevent);

Vue.filter("cutnumber", filters.cutnumber);

import {
  MoeBlock,
  HeadingBlock,
  InfoBlock,
  ValuesBlock
} from "@/components/Blocks";

Vue.component("moe-block", MoeBlock);
Vue.component("info-block", InfoBlock);
Vue.component("heading-block", HeadingBlock);
Vue.component("values-block", ValuesBlock);

import Popup from "@/components/Popups/Popup";
import Preloader from "@/components/Preloader";

import {
  TextInput, SelectInput,
  ButtonInput, CheckboxInput,
  LabeledInput, RadioInput,
  SwitchInput, SegmentedControl,
  SearchInput, PasswordInput
} from "@moeco-platform/moeco-components";

import BackButton from "@/components/Inputs/BackButton";

Vue.component("popup", Popup);
Vue.component("preloader", Preloader);
Vue.component("text-input", TextInput);
Vue.component("select-input", SelectInput);
Vue.component("button-input", ButtonInput);
Vue.component("search-input", SearchInput);
Vue.component("switch-input", SwitchInput);
Vue.component("checkbox-input", CheckboxInput);
Vue.component("segmented-control", SegmentedControl);
Vue.component("radio-input", RadioInput);
Vue.component("back-button", BackButton);
Vue.component("labeled-input", LabeledInput);
Vue.component("password-input", PasswordInput);


import Uploader from "@/components/Inputs/Uploader/Uploader";
Vue.component("uploader", Uploader);


import { Chart } from "highcharts-vue";
Vue.component("highcharts", Chart);

new Vue({
  store,
  router,
  render: h => h(app)
}).$mount("#app");

