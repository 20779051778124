<template>
  <div class="item rounded">
    <input :disabled="!edit" v-model="localName" placeholder="Name service" ref="headerInput" />
    <i class="button edit" v-if="!edit && !disableEditing" @click="toogleEdit">
      <pencil />
    </i>
  </div>
</template>

<script>
import Pencil from "@/components/Icons/Pencil";

export default {
  components: {
    Pencil
  },

  props: {
    value: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    disableEditing: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      localName: this.value
    };
  },

  watch: {
    value: function() {
      this.localName = this.value;
    },
    localName: function() {
      this.$emit("input", this.localName)
    }
  },

  methods: {
    toogleEdit() {
      this.$emit("edit");

      // TODO: does not work ?
      this.$refs.headerInput.focus();
      this.$refs.headerInput.select();
    },

    // TODO: Probably not needed
    onESCKeyDown() {
      window.getSelection().removeAllRanges();
    },
  }
};
</script>

<style lang="scss" scoped>
.item {
  padding-left: 13px;
  padding-right: 16px;
  border-radius: 6px 6px 0 0;
  background: $grey10;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.rounded {
    border-radius: 6px;
  }

  input {
    width: 100%;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: inherit;
    @include text-body;

    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
  }

  input:focus {
    outline: none;
  }

  .button {
    cursor: pointer;
  }
}
</style>
