<template>
  <div class="payment-container">
    <div class="label">
      <h3>Payment canceled</h3>
      <h4>You payment has been canceled</h4>
      <button-input class="button-input" @click.native="ReturnToPage">
        <template>Return to Finances Page</template>
      </button-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCancelView",
  methods: {
    ReturnToPage () {
      this.$router.push('/finances')
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-container {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  .label {
    box-shadow: $defaultShadow;
    border-radius: 6px;
    width: 300px;
    height: 200px;
    display: flex;
    background: $white;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    h3 {
      @include h3;
      color: $notBlack;
    }
    h4 {
      color: $notBlack;
    }
  }
}
</style>
