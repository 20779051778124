<template>
  <div class="payment-methods-container">
    <div class="saved-cards-container">
      <add-card-button @click="createPayment" />
      <div class="cards">
        <!-- <VueFriendlyIframe
          ref="cardsIframe"
          :src="src"
        />-->
        <iframe ref="cardsIframe" :src="src"></iframe>
      </div>
    </div>
    <add-card-popup :opened="addCardOpened" @close="closeAddCard" />
  </div>
</template>

<script>
// import VueFriendlyIframe from "vue-friendly-iframe";
import AddCardButton from "./components/AddCardButton";
import AddCardPopup from "@/components/Popups/AddCard/AddCardPopupNew";

import { mapGetters } from "vuex";

export default {
  components: { /* VueFriendlyIframe, */ AddCardPopup, AddCardButton },

  data() {
    return {
      showCards: true,
      addCardOpened: false
    };
  },

  computed: {
    ...mapGetters({
      userData: "auth/data"
    }),
    src() {
      let eosName = this.userData.eos_name;
      return `${process.env.VUE_APP_PAYMENT_GATE_URL}/saved_cards?eos_name=${eosName}`;
    }
  },

  methods: {
    createPayment() {
      this.addCardOpened = true;
    },
    
    closeAddCard() {
      /*
      TODO: CHeck this out. What is it ?
      let iframe = this.$refs.cardsIframe.$el.children[0];
      iframe.src = this.src;
      */

      this.addCardOpened = false;
    }
  }
};
</script>

<style lang="scss">
.saved-cards-container {
  display: flex;
  flex-direction: row;

  .cards {
    width: 100%;
    iframe {
      width: 100%;
      height: 174px;
    }
  }
}
</style>
