<template>
  <div class="devices-section">
    <div class="stats">
      <info-block class="stats-block">
        <template #title>{{ activeDevices }}</template>
        <template #descr>Active</template>
      </info-block>

      <info-block class="stats-block" tint="red">
        <template #title>{{ inactiveDevices }}</template>
        <template #descr>Down</template>
      </info-block>

      <info-block class="stats-block" tint="green">
        <template #title>{{ lowBatteryDevices }}</template>
        <template #descr>Low Battery</template>
      </info-block>
    </div>

    <heading-block title="Sensors groups">
      <div class="device-groups">
        <device-group-block
          v-for="deviceGroup in deviceGroupsList"
          :title="deviceGroup.name"
          :deviceGroupUid="deviceGroup.blockchain_uid"
          :value="getTotalDevices(deviceGroup)"
          :disabled="deviceGroup.status == 0"
          :key="deviceGroup.blockchain_uid"
          @click.native="deviceGroupClick(deviceGroup)"
        />
      </div>
    </heading-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeviceGroupBlock from "./DeviceGroupBlock";

export default {
  components: {
    DeviceGroupBlock,
  },

  async created() {
    try {
      await Promise.all([this.fetchDevices(), this.fetchDeviceGroups()]);
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000,
      });
    }
  },

  computed: {
    ...mapGetters({
      data: "dashboard/devices/data",
      loading: "dashboard/devices/loading",
      deviceGroupsList: "deviceGroups/list",
    }),

    // TODO : Move to devices vuex module
    activeDevices() {
      try {
        return this.data[0].active;
      } catch (err) {
        return 0;
      }
    },

    // TODO : Move to devices vuex module
    inactiveDevices() {
      try {
        return this.data[0].down;
      } catch (err) {
        return 0;
      }
    },

    // TODO : Move to devices vuex module
    lowBatteryDevices() {
      try {
        return this.data[0].lowBattery;
      } catch (err) {
        return 0;
      }
    },

    // TODO : Move to devices vuex module
    deviceGroups() {
      try {
        return this.data[0].devices.device_groups;
      } catch (err) {
        return [];
      }
    },
  },

  methods: {
    ...mapActions({
      fetchDevices: "dashboard/devices/fetch",
      fetchDeviceGroups: "deviceGroups/getList",
    }),

    deviceGroupClick(deviceGroup) {
      this.$router.push({
        name: "device-group",
        params: {
          id: deviceGroup.blockchain_uid,
        },
      });
    },

    getTotalDevices(deviceGroup) {
      const { active_devices, inactive_devices } = deviceGroup;
      return parseInt(active_devices) + parseInt(inactive_devices);
    },
  },
};
</script>

<style lang="scss" scoped>
.devices-section {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  overflow-y: hidden;

  .stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 24px;

    .stats-block {
      height: 72px;
    }
  }

  .device-groups {
    > * {
      margin-bottom: 8px;
    }
  }
}
</style>
