<template>
  <div class="device-group-create">
    <moe-block heading="Sensor groups" :loading="loading">
      <template #content>
        <div class="form">
          <div class="input-wrapper name">
            <span>Group name</span>
            <text-input
              placeholder="Please enter group name"
              v-model="deviceGroupName"
            />
          </div>

          <div class="input-wrapper fixed-width">
            <span>Sensor type</span>
            <select-input
              :options="deviceTypes.options"
              v-model="deviceTypes.value"
            >
              <template #single-label>{{ deviceTypes.value.name }}</template>
              <template #option="option">{{ option.option.name }}</template>
            </select-input>
          </div>

          <div class="input-wrapper fixed-width">
            <span>Tariff</span>
            <select-input
              :options="tariffsTypes.options"
              v-model="tariffsTypes.value"
            >
              <template #single-label>{{ tariffsTypes.value.name }}</template>

              <template #option="option">{{ option.option.name }}</template>
            </select-input>
          </div>

          <div class="actions">
            <button-input :primary="false" @click.native="cancel">
              Cancel
            </button-input>

            <button-input @click.native="addGroup">Add group</button-input>
          </div>
        </div>
      </template>
    </moe-block>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      deviceGroupName: null,

      deviceTypes: {
        value: null,
        options: [
          { name: "BLE", id: 1 },
          { name: "Lora", id: 2 },
          { name: "Virtual", id: 3 },
          { name: "iBeacon", id: 4 },
          { name: "GSM", id: 5 },
          { name: "WiFi", id: 6 },
        ],
      },

      tariffsTypes: {
        value: null,
        options: [
          { name: "Per transition", value: "TARIF_PER_TRX" },
          { name: "Per sensor", value: "TARIF_PER_SENSOR" },
          { name: "Per sensor smart", value: "TARIF_PER_SENSOR_SMART" },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      loading: "deviceGroup/loading",
      deviceGroup: "deviceGroup/item",
    }),
  },

  methods: {
    ...mapActions({
      create: "deviceGroup/createItem",
    }),

    async addGroup() {
      try {
        await this.create({
          name: this.deviceGroupName,
          group_type: this.deviceTypes.value.id,
          tarif_str: this.tariffsTypes.value.value,
        });

        this.$router.push({
          name: "device-group",
          params: {
            id: this.deviceGroup.blockchain_uid,
          },
        });
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    cancel() {
      this.$router.push({ name: "device-groups" });
    },
  },
};
</script>

<style lang="scss" scoped>
.device-group-create {
  flex: 1;

  .form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border: 1px solid $grey50;
    border-radius: 4px;
    padding: 14px 24px 18px 24px;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      &.fixed-width {
        min-width: 150px;
        max-width: 150px;
      }

      span {
        @include text-body;
        color: $grey70;
        line-height: 24px;
        margin-bottom: 8px;
      }

      &.name {
        width: 360px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      > * {
        margin-right: 8px;
      }
    }
  }
}
</style>
