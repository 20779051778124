<template>
  <popup
    v-bind="$attrs"
    :width="500"
    @close="closePopup"
    disable-padding
    :loading="loading"
  >
    <div slot="header" class="header">
      <span class="title">Deposit funds</span>
      <span class="close-icon"><cross-icon @click.native="closePopup" /></span>
    </div>

    <div slot="content" class="content">
      <div class="text">
        Indicate the desired amount of replenishment. After entering you will be
        redirected to the payment page.
      </div>

      <labeled-input
        placeholder="$ Amount"
        :value="value"
        @input="handleInput"
        @keyup.enter.native="onEnterClick"
      />

      <button-input class="button-input" @click.native="onDepositClick">
        Deposit
      </button-input>
    </div>
  </popup>
</template>

<script>
import { injectStripe } from "@/helpers/stripe";
import FinancesApi from "@/api/finances";
import CrossIcon from "@/components/Icons/Cross";
import { mapGetters } from "vuex";

export default {
  name: "deposit-funds-popup",

  components: {
    CrossIcon,
  },

  data() {
    return {
      value: "",
      stripeKey: process.env.VUE_APP_STRIPE_PK,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      userData: "auth/data",
    }),
  },

  methods: {
    handleInput(value) {
      this.value = Math.abs(value).toString();
    },

    onEnterClick() {
      this.onDepositClick();
    },

    async onDepositClick() {
      if (this.value !== "" && this.value !== "0") {
        try {
          this.loading = true;

          const { data } = await FinancesApi.deposit(parseInt(this.value));

          const sessionId = data[0].session_id;

          // Used at "success payment" page
          localStorage.setItem("stripe_session_id", sessionId);

          await injectStripe();

          const stripe = window.Stripe(this.stripeKey);

          stripe.redirectToCheckout({ sessionId });
        } catch (error) {
          this.$toast.error(error.message);
        } finally {
          this.loading = false;
        }
      } else {
        this.$toast.error("Amount must be larger than 0");
      }
    },

    closePopup() {
      this.$emit("close");
    },
  },
};
</script>


<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }

  .close-icon {
    cursor: pointer;
  }
}

.content {
  @include text-body;
  padding: 8px;
  color: $notBlack;

  & > * {
    margin-bottom: 8px;
  }

  .text {
    padding-bottom: 16px;
  }
}

.button-input {
  margin-left: auto;
}
</style>
