import DataView from "@/views/Data";

export default [
  {
    path: "/data",
    name: "data",
    meta: {
      title: "Data"
    },
    component: DataView
  }
];
