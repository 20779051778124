<template>
  <div class="blocks-grid" v-if="gatewaysStat">
    <info-block borderless>
      <template #title>{{ gatewaysStat.active }}</template>
      <template #descr>Active</template>
    </info-block>

    <info-block borderless>
      <template #title>{{ gatewaysStat.inactive }}</template>
      <template #descr>Down</template>
    </info-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},

  async created() {
    try {
      await this.getGatewaysStat({ id: this.gatewayGroupUid });
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000
      });
    }
  },

  computed: {
    ...mapGetters({
      gatewaysStat: "gatewayGroupStat/activity/item"
    }),
    gatewayGroupUid() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions({
      getGatewaysStat: "gatewayGroupStat/activity/getItem"
    })
  }
};
</script>

<style scoped lang="scss">
.inline-icon {
  margin-right: 4px;
}
</style>
