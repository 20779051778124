<template>
  <button-input class="back-button" @click.native="onClick">
    <arrow-left slot="icon" fill="white" />
  </button-input>
</template>

<script>
import ArrowLeft from "@/components/Icons/ArrowLeft";

export default {
  name: "back-button",

  components: {
    ArrowLeft
  },

  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.back-button {
  width: 40px;
  height: 40px;
  margin-right: 24px;
}
</style>