<template>
  <div class="data-section">
    <heading-block title="Transfers" class="data-block">
      <highcharts :options="lineConfig" class="linear-chart" ref="txsChart" />

      <div class="info-blocks">
        <info-block
          v-for="block in infoBlocks"
          :key="block.descr"
          orientation="horizontal"
          :tint="block.tint"
        >
          <template #title>{{ block.title }}</template>
          <template #descr>{{ block.descr }}</template>
        </info-block>
      </div>
    </heading-block>

    <heading-block title="Delivery speed">
      <div class="delivery-speed-wrapper">
        <info-block class="fast-block" align="center">
          <template #title>
            <span class="fast-title">{{ percentage.fast }}%</span>
          </template>
          <template #descr>&lt; 1 min</template>
        </info-block>

        <highcharts :options="pieConfig" ref="pieChart" class="pie-chart" />

        <div class="right-block">
          <info-block orientation="horizontal" align="center" class="small-block">
            <template #title>
              <span class="warn-title">{{ percentage.warn }}%</span>
            </template>
            <template #descr>&lt; 3 min</template>
          </info-block>

          <info-block orientation="horizontal" align="center" class="small-block">
            <template #title>
              <span class="caution-title">{{ percentage.slow }}%</span>
            </template>
            <template #descr>&gt; 3 min</template>
          </info-block>
        </div>
      </div>
    </heading-block>
  </div>
</template>

<script>
import { lineConfigDefault, pieConfigDefault } from "@/helpers/chartConfigs.js";
import { mapGetters, mapActions } from "vuex";
import Moment from "moment";
import { dataMixin } from "./mixins/data.js";

export default {
  name: "data-section",
  mixins: [dataMixin],
  data() {
    return {
      loading: false,
      timer: null,
      tint: null,

      transimitionData: {
        lastMinute: 0,
        lastDay: 0,
        lastWeek: 0,
        lastMonth: 0
      },

      postfixes: ["K", "M", "B"],

      percentage: {
        fast: null,
        warn: null,
        slow: null
      },

      lineConfig: {
        ...lineConfigDefault,

        tooltip: {
          formatter: function() {
            const template = `
              <b>Txs count</b>: ${this.y}<br>
              <b>Date</b>: ${Moment(this.x, "x").format("DD MMM HH:mm:ss")}
            `;
            return template;
          }
        },
        xAxis: {
          tickWidth: 0,
          tickInterval: 24 * 3600 * 1000,
          labels: {
            formatter: function() {
              return Moment(this.value, "x").format("DD MMM");
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4"
            }
          }
        },
        yAxis: {
          title: { enabled: false },
          labels: {
            formatter: function() {
              const label = this.axis.defaultLabelFormatter.call(this);
              return label.toUpperCase();
            },
            style: {
              fontFamily: "'Rubik'",
              color: "#CED0E4"
            }
          }
        },
        series: [
          {
            type: "spline",
            name: "Transmissions",
            marker: {
              enabled: false
            },
            data: []
          }
        ]
      },

      pieConfig: {
        ...pieConfigDefault,
        series: [
          {
            name: "Txs speed",
            innerSize: "60%",
            data: [
              { y: 0, color: "#CED0E4" },
              { y: 0, color: "#EEA620" },
              { y: 0, color: "#DE3C63" }
            ]
          }
        ]
      }
    };
  },

  watch: {
    loading: function(newVal) {
      if (!newVal) {
        this.transmissionsSpeedInit();
        this.pieChartInit();
        this.transimitionDataInit();

        const txsChartLength = this.lineConfig.series[0].data.length;

        if (txsChartLength == 0) {
          this.drawChart();
        }

        const lastElm = this.lineConfig.series[0].data[txsChartLength - 1];
        const dataLastElm = this.chartData[this.chartData.length - 1];

        if (lastElm && lastElm.x != dataLastElm.hour) {
          this.updateChart();
        }
      }
    },

    currentPieTitle: function() {
      if (!this.$refs.pieChart) return;
      this.pieConfig.title.text = this.currentPieTitle;
    }
  },

  computed: {
    ...mapGetters({
      chartData: "dashboard/data/chart/data",
      metainfoData: "dashboard/data/metainfo/data"
    }),

    currentPieTitle() {
      const vals = Object.entries(this.percentage).sort((a, b) => b[1] - a[1]);
      return vals[0][0].toUpperCase();
    }
  },

  methods: {
    ...mapActions({
      fetchChart: "dashboard/data/chart/fetch",
      fetchMetainfo: "dashboard/data/metainfo/fetch"
    }),

    fetch: async function() {
      try {
        this.loading = true;
        await Promise.all([this.fetchChart(), this.fetchMetainfo()]);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000
        });
      } finally {
        this.loading = false;
      }
    },

    // TODO: Wouldn't it better to move all init logic to vuex ?
    transmissionsSpeedInit() {
      let fast = this.metainfoData[0].delivery["1MinLess"];
      let warn = this.metainfoData[0].delivery["3MinLess"];
      let slow = this.metainfoData[0].delivery["3MinMore"];

      const total = fast + warn + slow;

      fast = total ? Math.round(fast / (total / 100)) : 0;
      warn = total ? Math.round(warn / (total / 100)) : 0;
      slow = total ? Math.round(slow / (total / 100)) : 0;

      if (fast + warn + slow != 100) {
        const diff = fast + warn + slow - 100;
        if (slow - diff > 0 && slow > 0) {
          slow -= diff;
        } else if (warn - diff > 0 && warn > 0) {
          warn -= diff;
        }
      }

      this.$data.percentage = { fast, warn, slow };
    },

    pieChartInit() {
      const fast = this.metainfoData[0].delivery["1MinLess"];
      const warn = this.metainfoData[0].delivery["3MinLess"];
      const slow = this.metainfoData[0].delivery["3MinMore"];

      this.$data.pieConfig.series[0].data[0].y = fast ? fast : 0;
      this.$data.pieConfig.series[0].data[1].y = warn ? warn : 0;
      this.$data.pieConfig.series[0].data[2].y = slow ? slow : 0;
    },

    transimitionDataInit() {
      this.$data.transimitionData = {
        lastMinute: this.metainfoData[0].tpm,
        lastDay: this.metainfoData[0].today,
        lastWeek: this.metainfoData[0].week,
        lastMonth: this.metainfoData[0].month
      };
    },

    drawChart() {
      for (let chartItem of this.chartData) {
        this.lineConfig.series[0].data.push({
          x: parseInt(Moment(chartItem.hour).format("x")),
          y: parseInt(chartItem.count)
        });
      }
    },

    updateChart() {
      this.lineConfig.series[0].data.shift();
      const dataItem = this.chartData[this.chartData.length - 1];
      this.lineConfig.series[0].data.push({
        x: dataItem.hour,
        y: parseInt(dataItem.count)
      });
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  created() {
    this.fetch();
    this.timer = setInterval(() => {
      this.fetch();
    }, 60 * 60 * 1000);
  }
};
</script>

<style lang="scss" scoped>
.data-section {
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  .data-block {
    margin-bottom: 24px;
  }

  .linear-chart {
    height: 160px;
  }

  .info-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

  .delivery-speed-wrapper {
    display: flex;
    justify-content: space-between;

    .fast-block {
      min-width: 120px;
      margin: 10px 0;

      .fast-title {
        @include h1;
      }
    }

    .pie-chart {
      width: 100%;
      // display: flex;
    }

    .right-block {
      min-width: 120px;
      margin: 10px 0;

      flex: 1;
      display: flex;
      flex-direction: column;

      & > .small-block ~ .small-block {
        margin-top: 5px;
      }

      .small-block {
        .warn-title {
          color: $accentYellow;
        }
        .caution-title {
          color: $accentRed;
        }
      }
    }
  }

  // .delivery-speed-wrapper {
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   grid-row-gap: 8px;
  //   grid-column-gap: 16px;
  //   justify-content: space-between;
  //   height: 104px;
  //
  //   .fast-block {
  //     // margin: 30px 0;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     grid-row: 1 / 3;
  //     min-width: 120px;
  //
  //     .fast-title {
  //       @include h1;
  //     }
  //   }
  //
  //   .pie-chart {
  //
  //     grid-row: 1 / 3;
  //   }
  //
  //   .small-block {
  //     min-width: 120px;
  //     // margin: 30px 0;
  //
  //     .warn-title {
  //       color: $accentYellow;
  //     }
  //
  //     .caution-title {
  //       color: $accentRed;
  //     }
  //   }
  // }
}
</style>
