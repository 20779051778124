<template>
  <div class="devices-table-heading">
    <search-input class="search-input" @search="onSearch" />

    <div class="default-actions">
      <select-input
        class="period-filter"
        placeholder="Select period"
        v-model="period.value"
        :options="period.options"
        :searchable="false"
        @input="onPeriodChanged"
      >
        <template slot="single-label" slot-scope="props">
          <calendar-icon class="calendar" />
          <span class="option__desc">
            <span class="option__title">{{ props.option }}</span>
          </span>
        </template>
      </select-input>
    </div>
  </div>
</template>

<script>
import CalendarIcon from "@/components/Icons/Calendar";
import { mapActions, mapGetters } from "vuex";
import Moment from "moment";

export default {
  name: "gateways-table-heading",

  components: {
    CalendarIcon
  },

  props: {
    showInteraction: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      params: "gateways/params"
    })
  },

  methods: {
    ...mapActions({
      getList: "gateways/getList",
      changeParams: "gateways/changeParams"
    }),

    async fetch() {
      try {
        await this.getList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000
        });
      }
    },

    onSearch(val) {
      this.changeParams({ term: val });
      this.fetch();
    },

    onPeriodChanged(periodValue) {
      let periodStart;
      let periodEnd;
      switch (periodValue) {
        case this.period.options[0]: {
          periodStart = Moment()
            .subtract(1, "days")
            .startOf("day")
            .format();
          break;
        }

        case this.period.options[1]: {
          periodStart = Moment()
            .subtract(7, "days")
            .startOf("day")
            .format();
          break;
        }

        case this.period.options[2]: {
          periodStart = Moment()
            .subtract(14, "days")
            .startOf("day")
            .format();
          break;
        }

        case this.period.options[3]: {
          periodStart = Moment()
            .subtract(1, "months")
            .startOf("day")
            .format();
          break;
        }

        case this.period.options[4]: {
          periodStart = Moment()
            .subtract(1, "year")
            .startOf("day")
            .format();
          break;
        }
      }

      if (periodStart) {
        periodEnd = Moment().format();
        this.changeParams({
          period_start: periodStart,
          period_end: periodEnd
        });
        this.fetch();
      }
    }
  },

  data() {
    return {
      searchInput: null,
      period: {
        options: [
          "Last day",
          "Last 7 days",
          "Last 2 weeks",
          "Last month",
          "Last year"
        ],
        value: "Last year"
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.devices-table-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    max-width: 300px;
    margin-left: 0;
    margin-right: 20px;
    flex-shrink: 1;
  }

  .interaction-actions,
  .default-actions {
    display: flex;
    align-items: center;

    > * {
      margin: 0 4px;
    }
  }

  .interaction-actions {
    flex: 1;
  }

  .filter-button {
    min-width: 40px;
    max-width: 64px;
  }

  .period-filter {
    max-width: 200px;

    .calendar {
      margin-right: 8px;
    }
  }
}
</style>
