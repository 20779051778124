import SettingsView from "@/views/Settings";
import SettingsPersonalView from "@/views/Settings/views/Personal";
import SettingsPaymentView from "@/views/Settings/views/Payment";
import SettingsSecurityView from "@/views/Settings/views/Security";
import SettingsApiView from "@/views/Settings/views/Api";
import SettingsNotificationsView from "@/views/Settings/views/Notifications";

export default [{
  path: "/settings",
  redirect: "/settings/personal",
  name: "settings",
  meta: {
    title: "Account settings"
  },
  component: SettingsView,
  children: [{
      path: "personal/:mode?",
      name: "settings-personal",
      meta: {
        title: "Account settings"
      },
      component: SettingsPersonalView,
      props: true
    },
    {
      path: "payment",
      name: "settings-payment",
      meta: {
        title: "Account settings"
      },
      component: SettingsPaymentView
    },
    {
      path: "security",
      name: "settings-security",
      meta: {
        title: "Account settings"
      },
      component: SettingsSecurityView
    },
    {
      path: "api",
      name: "settings-api",
      meta: {
        title: "Account settings"
      },
      component: SettingsApiView
    },
    {
      path: "notifications",
      name: "settings-notifications",
      meta: {
        title: "Account settings"
      },
      component: SettingsNotificationsView
    }
  ]
}]
