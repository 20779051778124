<template>
  <ul class="options">
    <li v-for="opt in data" :key="opt.id">
      <checkbox-input @input="toggleFilter(opt)" :value="opt.selected" :id="opt.name" />
      <span>{{ opt.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "filter-options",

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    toggleFilter(option) {
      this.$emit("toggle-filter", option);
    }
  }
};
</script>

<style lang="scss" scoped>
.options {
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      @include text-body;
      margin-left: 16px;
      color: $justBlack;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>