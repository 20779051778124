import DevicesView from "@/views/Devices";
import DeviceGroup from "@/views/Devices/DeviceGroup/DeviceGroup";
import DeviceGroupCreate from "@/views/Devices/DeviceGroup/DeviceGroupCreate";
import DeviceGroupServices from "@/views/Devices/DeviceGroup/segments/ServicesSegment";
import DeviceGroupDevices from "@/views/Devices/DeviceGroup/segments/DevicesSegment";
import DeviceGroupSettings from "@/views/Devices/DeviceGroup/segments/SettingsSegment";

export default [
  {
    path: "/device_groups",
    name: "device-groups",
    meta: {
      title: "Sensors"
    },
    component: DevicesView
  },
  {
    path: "/devices/new",
    name: "device-group-create",
    meta: {
      title: "Sensors"
    },
    component: DeviceGroupCreate
  },
  {
    path: "/devices/:id",
    name: "device-group",
    meta: {
      title: "Sensors"
    },
    component: DeviceGroup,
    props: true,
    children: [
      {
        path: "services",
        name: "device-group-services",
        meta: {
          title: "Sensors"
        },
        component: DeviceGroupServices
      },
      {
        path: "devices",
        name: "device-group-devices",
        meta: {
          title: "Sensors"
        },
        component: DeviceGroupDevices
      },
      {
        path: "settings",
        name: "device-group-settings",
        meta: {
          title: "Sensors"
        },
        component: DeviceGroupSettings
      }
    ]
  }
];
