import Request from "@/helpers/request";

export default class GatewayGroupsStatApi {
  static getChart(params) {
    const detalisation = params.detalisation;
    const period = params.period;
    return Request.client.get(`/gateway_group/${params.id}/graph`, {
      params: { ...period, detalisation }
    });
  }

  static getActivity(params) {
    return Request.client.get(`/gateway_group/${params.id}/activity`);
  }

  static getTxStat(params) {
    const period = params.period;
    
    return Request.client.get(`/gateway_group/${params.id}/stat`, {
      params: period
    });
  }

  static getTxChart(params) {
    const detalisation = params.detalisation;
    const period = params.period;
    return Request.client.get(`/gateway_group/${params.id}/graph`, {
      params: { ...period, detalisation }
    });
  }
}
