var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-view"},[_c('moe-block',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"heading"},[_c('div',{staticClass:"heading"},[_c('h1',[_vm._v("Transfers")]),_c('h1',[_vm._v(_vm._s(_vm.title))])])]),_c('template',{slot:"actions"},[_c('button-input',{nativeOn:{"click":function($event){return _vm.openExport($event)}}},[_vm._v("Export")])],1),_c('template',{slot:"content"},[_c('div',{staticClass:"data-container"},[_c('div',{staticClass:"fixed-container"},[_c('data-view-heading'),_c('highcharts',{ref:"chart",staticClass:"linear-chart",attrs:{"options":_vm.lineConfig}})],1),_c('table-component',_vm._b({ref:"table",staticClass:"data-table",attrs:{"data":_vm.lazyList},on:{"sort-by":_vm.onSort,"hit-bottom":_vm.hitBottom},scopedSlots:_vm._u([{key:"row-blockchain_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner"},[_vm._v(_vm._s(item.blockchain_id))])]}},{key:"row-confirmed",fn:function(ref){
var item = ref.item;
return [_c('tag-wrapper',_vm._b({},'tag-wrapper',item,false))]}},{key:"row-device",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner"},[_vm._v(" "+_vm._s(item.device ? item.device.hash : "empty")+" ")])]}},{key:"row-device_group",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner"},[_vm._v(_vm._s(item.device && item.device.device_group ? item.device.device_group.name : ""))])]}},{key:"row-created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner date-view"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"row-timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner date-view"},[_vm._v(" "+_vm._s(_vm.formatDate(item.timestamp))+" ")])]}},{key:"row-gateway",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cell-inner"},[_vm._v(" "+_vm._s(item.gateway && item.gateway.name)+" ")])]}},{key:"row-payload",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-inner show-payload-icon"},[_c('info-icon',{nativeOn:{"click":function($event){return _vm.showPayload(item)}}})],1)]}}])},'table-component',_vm.table,false))],1)])],2),_c('popup',{attrs:{"opened":_vm.payload.opened},on:{"close":_vm.closePayload}},[_c('template',{slot:"content"},[_c('h1',{staticStyle:{"font-weight":"500"}},[_vm._v("Content of transfer:")]),_c('pre',[_c('code',[_vm._v(_vm._s(_vm.getTxContent(_vm.payload.content)))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }