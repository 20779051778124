<template>
  <moe-block class="device-group-stats">
    <template #heading>
      <router-link to="/device_groups">
        <back-button />
      </router-link>
      <div class="header">
        <h1 v-if="deviceGroup">
          <div class="header-label">{{ deviceGroup.name }}</div>
        </h1>
      </div>
    </template>

    <template #content>
      <heading-block title="Sensors" bordered>
        <Sensors />
      </heading-block>
      <heading-block title="Battery life" bordered>
        <Battery />
      </heading-block>
      <heading-block title="Data" bordered>
        <Data />
      </heading-block>
      <heading-block title="Spending" bordered>
        <Spendings />
      </heading-block>
      <heading-block title="Group info" bordered>
        <Info />
      </heading-block>
      <heading-block title="Delete device group" bordered>
        <div class="delete-wrapper">
          <button-input :primary="false" @click.native="deleteGroup">
            <span class="red">Delete</span>
          </button-input>
          <span class="caption">Deleted group can be recovered in 24 hours after deletion</span>
        </div>
      </heading-block>
    </template>
  </moe-block>
</template>

<script>
// TODO: Make generic component for all parts in `/component`
import { mapGetters, mapActions } from "vuex";
import Sensors from "./components/Sensors";
import Battery from "./components/Battery";
import Data from "./components/Data";
import Spendings from "./components/Spendings";
import Info from "./components/Info";

export default {
  components: {
    Sensors,
    Battery,
    Data,
    Spendings,
    Info
  },

  computed: {
    ...mapGetters({
      loading: "deviceGroup/loading",
      deviceGroup: "deviceGroup/item"
    })
  },
  methods: {
    ...mapActions({
      deleteDeviceGroup: "deviceGroup/deleteItem"
    }),

    async deleteGroup() {
      const uid = this.$route.params.id;
      try {
        await this.deleteDeviceGroup(uid);
        const message = 'Device group deleted successfully'
        this.$toast.success(message, {
          position: "top",
          duration: 6000
        });
        this.$router.push({ name: "device-groups" });
      } catch (error) {
        console.warn(error)
        const message = 'Sensor group is not empty!'
        this.$toast.error(message, {
          position: "top",
          duration: 6000
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.device-group-stats {
  .header {
    flex: 1;
    .header-label {
      display: flex;
      align-items: center;

      .edit-icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .header-name-input-container {
      display: flex;
      flex-direction: row;
      .item {
        margin-right: 5px;
      }
    }
  }

  .blocks-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 24px;
    grid-column-gap: 4px;
    margin-bottom: 14px;

    .chart-block {
      grid-row: 1 / span 2;
      grid-column: 2 / span 3;
    }
  }

  .delete-wrapper {
    display: flex;
    align-items: center;

    .red {
      color: $accentRed;
    }

    .caption {
      max-width: 220px;
      margin-left: 16px;
      color: $grey70;
    }
  }
}
</style>
