var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"devices-view"},[_c('moe-block',{attrs:{"heading":"Sensor groups","loading":_vm.loading || _vm.updateLoading},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('search-input',{staticClass:"search",on:{"search":_vm.onSearch}})]},proxy:true},{key:"actions",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'device-group-create' }}},[(_vm.table.data.length)?_c('button-input',{attrs:{"primary":false}},[_c('plus-icon',{attrs:{"slot":"icon","fill":"currentColor"},slot:"icon"}),[_vm._v("Add sensors group")]],2):_vm._e()],1)]},proxy:true},{key:"content",fn:function(){return [(!_vm.ifTableVisible)?_c('div',{staticClass:"empty"},[_c('div',{staticClass:"image"}),_c('h1',{staticClass:"heading"},[_vm._v("You do not have sensors groups")]),_c('router-link',{attrs:{"to":{ name: 'device-group-create' }}},[_c('button-input',[_c('plus-icon',{attrs:{"slot":"icon","fill":"currentColor"},slot:"icon"}),_c('span',[_vm._v("Add sensors group")])],1)],1)],1):_c('table-component',_vm._b({staticClass:"device-groups-table",on:{"sort-by":_vm.onSort},scopedSlots:_vm._u([{key:"header-inactive_devices",fn:function(ref){
var column = ref.column;
return [_c('span',[_vm._v(_vm._s(column.title))])]}},{key:"header-low_battery",fn:function(ref){
var column = ref.column;
return [(column.icon == 'battery')?_c('battery-low-icon'):_vm._e()]}},{key:"row-status",fn:function(ref){
var item = ref.item;
return [_c('switch-input',{on:{"input":function($event){return _vm.onDeviceGroupSwitch(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"row-name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"device-group-title",class:{ inactive: !item.status }},[_vm._v(_vm._s(item.name))])]}},{key:"row-confirmed",fn:function(ref){
var item = ref.item;
return [_c('tag-wrapper',_vm._b({},'tag-wrapper',item,false))]}},{key:"row-data",fn:function(ref){
var item = ref.item;
return [(!_vm.loading)?_c('inline-chart',{attrs:{"deviceGroupUid":item.blockchain_uid}}):_vm._e()]}},{key:"row-group_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getGroupTypeName(item.group_type)))])]}},{key:"row-inactive_devices",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.inactive_devices))])]}},{key:"row-low_battery",fn:function(ref){
var item = ref.item;
return [(!_vm.loading)?_c('BatteryStatus',{attrs:{"deviceGroupUid":item.blockchain_uid}}):_vm._e()]}},{key:"row-updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]}},{key:"row-action",fn:function(ref){
var item = ref.item;
return [_c('button-input',{staticClass:"stretched",attrs:{"primary":true},nativeOn:{"click":function($event){return _vm.onConfigureClick(item)}}},[_vm._v(" Configure ")])]}}])},'table-component',_vm.table,false))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }