import Request from "@/helpers/request";

export default class DevicesApi {
  static getList(query) {
    return Request.client.get("/device", {
      params: DevicesApi.prepareParams(query)
    });
  }

  static getItem(id) {
    return Request.client.get(`/device/${id}`);
  }

  static updateItem(id, data) {
    return Request.client.put(`/device/${id}`, data);
  }

  static createItem(data) {
    return Request.client.post(`/device`, data);
  }

  static moveDevice(data) {
    const device_group_uid = data.deviceGroup.blockchain_uid;
    return Request.client.put(
      `/device/${data.device.blockchain_uid}/move`,
      { device_group_uid }
    );
  }

  static updateDownlink(data) {
    return Request.client.post('/downlinks/batch', data);
  }

  static importList(formData, deviceGroupUid) {
    return Request.client.post(
      `/device/csv_import/${deviceGroupUid}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  }

  static prepareParams(params) {
    return {
      period_end: params.period_end,
      period_start: params.period_start,
      hash: params.hash,
      device_group__blockchain_uid: params.device_group__blockchain_uid,
      __order: (params.orderBy) ? `${params.orderBy.value} ${params.orderBy.desc ? "desc" : "asc"}` : null,
      __count: (params.limit) ? `${params.limit.count}` : null,
      __offset: (params.limit) ? `${params.limit.offset}` : null,
    };
  }
}
