<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.29571 0.758911L10.9069 1.51023L4.56765 15.1048L2.95644 14.3535L9.29571 0.758911ZM13.2267 6.13595V5.3333C13.2267 4.59692 12.6345 3.99997 11.904 3.99997H11.0626L10.4409 5.3333H11.015V10.6666H7.95391L7.33217 12H11.904C12.6345 12 13.2267 11.403 13.2267 10.6666V9.86413C13.816 9.647 14.2216 9.19238 14.2216 8.66671V7.33337C14.2216 6.8077 13.816 6.35308 13.2267 6.13595ZM1.32267 3.99997H6.37902L5.75728 5.3333H1.77767V10.6666H3.27031L2.64856 12H1.32267C0.592178 12 0 11.403 0 10.6666V5.3333C0 4.59692 0.592178 3.99997 1.32267 3.99997Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "battery-empty-icon",
  props: {
    fill: {
      default: "#6B6E8D",
      type: String
    }
  }
};
</script>
