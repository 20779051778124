<template>
  <div class="settings-api">
  </div>
</template>

<script>
export default {
  name: "settings-api"
};
</script>

<style lang="scss" scoped>
.settings-api {
}
</style>
