<template>
  <popup class="add-card-popup" v-bind="$attrs" :width="480" @close="close">
    <div slot="header" class="header">
      <span class="title">Add new credit card</span>
      <cross-icon @click.native="close" />
    </div>
    <div slot="content">
      <div class="form">
        <text-input placeholder="Enter card number" v-model="card.number" />
        <text-input placeholder="Enter cardholder name" v-model="card.holderName" />
        <div class="credentials">
          <span class="expires">Expires</span>
          <text-input placeholder="Month" class="small-input" v-model.number="card.expMonth" />
          <span>/</span>
          <text-input placeholder="Year" class="small-input" v-model.number="card.expYear" />
          <span class="security">Security code</span>
          <text-input placeholder="CVC" class="cvc" />
        </div>
      </div>
      <div class="billing">
        <h2 class="billing-header">Billing address</h2>
        <div class="checkbox-wrapper">
          <checkbox-input v-model="billing.sameAsCompany" />
          <span class="label">Same as company address</span>
        </div>
        <text-input placeholder="Address line 1" v-model="billing.addressOne" class="billing-row" />
        <text-input placeholder="Address line 2" v-model="billing.addressTwo" class="billing-row" />
        <div class="grid">
          <text-input placeholder="City" v-model="billing.city" />
          <text-input placeholder="State" v-model="billing.state" />
          <text-input placeholder="Country" v-model="billing.country" />
          <text-input placeholder="ZIP Code" v-model="billing.zip" />
        </div>
      </div>
    </div>
    <template slot="actions">
      <button-input class="add-button" @click.native="addCard">Add card</button-input>
    </template>
  </popup>
</template>

<script>
import CrossIcon from "@/components/Icons/Cross";
export default {
  name: "add-card-popup",

  components: {
    CrossIcon
  },

  data() {
    return {
      card: {
        cardType: null,
        expMonth: null,
        expYear: null,
        holderName: null,
        number: null
      },
      billing: {
        sameAsCompany: false,
        addressOne: null,
        addressTwo: null,
        city: null,
        state: null,
        country: null,
        zip: null
      }
    };
  },

  methods: {
    addCard() {
      this.$emit("add", this.card);
    },

    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }
}

.form {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .credentials {
    @include text-body;
    display: flex;
    align-items: center;
    color: $grey70;

    .expires {
      margin-right: 8px;
    }

    .small-input {
      width: 75px;
      margin: 0 8px;
      flex-shrink: 0;
    }

    .security {
      margin-left: 16px;
      margin-right: 10px;
      flex-shrink: 0;
      flex-grow: 1;
      text-align: right;
    }

    .cvc {
      width: 60px;
    }
  }
}

.billing {
  display: flex;
  flex-direction: column;

  .billing-header {
    @include h2;
    color: $notBlack;
    margin: 24px 0 16px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      @include text-body;
      margin-left: 16px;
      color: $grey70;
    }
  }

  .billing-row {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 8px;
  }
}
</style>