import { toFixed } from "@/helpers/toFixed.js";

export const dataMixin = {
  computed: {
    infoBlocks() {
      return [
        {
          title: this.formatAmount(this.transimitionData.lastMinute),
          descr: "/ min",
          tint: this.tint
        },
        {
          title: this.formatAmount(this.transimitionData.lastDay),
          descr: "Last day"
        },
        {
          title: this.formatAmount(this.transimitionData.lastWeek),
          descr: "Last week"
        },
        {
          title: this.formatAmount(this.transimitionData.lastMonth),
          descr: "Last month"
        }
      ];
    }
  },

  methods: {
    formatAmount(amount) {
      if (amount > 1) {
        const number = this.getReadableNumber(amount);
        const formattedValue = Number(number.value).toLocaleString("en");
        return formattedValue + number.letter;
      }

      if (amount == 0) {
        return amount;
      }

      return parseFloat(amount).toFixed(2);
    },

    getReadableNumber(number) {
      const postfixes = ["", ...this.postfixes];

      for (const postfix of postfixes.reverse()) {
        const index = postfixes.indexOf(postfix);
        const numberOfZeros = postfixes.length - index - 1;
        const divided = Number(number) / Number("1.0e" + numberOfZeros * 3);

        if (Math.floor(divided) > 0) {
          return {
            value: this.formatToFixed(divided),
            letter: postfix
          };
        }
      }
    },

    formatToFixed(number, maxDigits = 3) {
      const digitsBeforePoint = (Math.floor(number) + "").length;
      return toFixed(number, maxDigits - digitsBeforePoint);
    }
  }
}
