<template>
  <input style="display: none" type="file" ref="uploadFileInput" @input="onInputChanged" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },

  methods: {
    onInputChanged() {
      if (this.type == "csv") {
        const file = event.target.files[0];
        let formData = new FormData();
        formData.append("csv_file", file);
        this.$emit("import", formData);
        this.$refs.uploadFileInput.value = "";
      }

      if (this.type == "json") {
        event.target.files[0]
          .text()
          .then(result => {
            this.$emit("import", JSON.parse(result));
          })
          .catch(() => {
            this.$toast.error("File format is wrong!");
          });
      }
    }
  }
};
</script>
