import Request from "@/helpers/request";
// import Moment from "moment";

export default class GatewayGroupsApi {
  static getList(query) {
    return Request.client.get("/gateway_group", {
      params: GatewayGroupsApi.prepareParams(query)
    });
  }


  static getChart(params, CancelTokenSource) {
    let deviceGroupUid = params.id;

    delete params.id;

    let props = { params };

    if (CancelTokenSource) {
      props.cancelToken = CancelTokenSource.token;
    }

    return Request.client.get(`/gateway_group/${deviceGroupUid}/graph`, props);
  }

  static getItem(id) {
    return Request.client.get(`/gateway_group/${id}`);
  }

  static updateItem(id, data) {
    return Request.client.put(`/gateway_group/${id}`, data);
  }

  static createItem(data) {
    return Request.client.post(`/gateway_group`, data);
  }

  static deleteItem(uid) {
    return Request.client.delete(`/gateway_group/${uid}`);
  }

  static prepareParams(params) {
    return {
      term: params.term,
      ...{
        __order: `${params.orderBy.value} ${params.orderBy.desc ? 'desc' : 'asc'}`,
        __count: 100, // `${params.limit.count}`,
      },
    };
  }
}
