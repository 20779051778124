<template>
  <transition name="fade">
    <div v-show="progress && progress !== 100" class="progress-bar">
      <div class="progress-tint" :style="barStyle"></div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    progress: {
      type: Number,
      default: 0
    }
  },

  computed: {
    barStyle() {
      return {
        width: Math.round(this.progress) + "%"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 6px;
  overflow: hidden;

  .progress-tint {
    background: green;
    height: 100%;
    width: 0%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    transition-delay: 1s;
    opacity: 0;
  }
}
</style>