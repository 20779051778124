import Vue from "vue";
import Vuex from "vuex";

// TODO : Make autoloading of that staff
// TODO : Make boilerplate for all vuex modules and inherit all from that

import { store } from "@moeco-platform/moeco-components";

const { buildAuthStoreModule, notifications, user, errors } = store;

import transactions from "./modules/transactions";
import deviceGroups from "./modules/device_groups/device_groups";
import deviceGroup from "./modules/device_groups/device_group";
import deviceGroupStat from "./modules/device_groups/device_group_stat";
import devices from "./modules/devices/devices";
import device from "./modules/devices/device";
import gatewayGroups from "./modules/gateway_groups/gateway_groups";
import gatewayGroup from "./modules/gateway_groups/gateway_group";
import gatewayGroupStat from "./modules/gateway_groups/gateway_group_stat";
import gateways from "./modules/gateways/gateways";
import gateway from "./modules/gateways/gateway";
import dashboard from "./modules/dashboard";
import account from "./modules/account";
import finances from "./modules/finances";
import financesDetails from "./modules/finances_details";
import constants from "./modules/sys/constants";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: debug,
  namespaced: true,
  modules: {
    auth: buildAuthStoreModule(null, process.env.VUE_APP_APP_NAME),
    transactions,
    notifications,
    deviceGroups,
    deviceGroup,
    deviceGroupStat,
    devices,
    device,
    gatewayGroups,
    gatewayGroup,
    gatewayGroupStat,
    gateways,
    gateway,
    dashboard,
    account,
    finances,
    financesDetails,
    errors,

    constants,
    user
  }
});
