import Request from "@/helpers/request";

export default class FinancesApi {
  static getList(query) {
    return Request.client.get("/finances", {
      params: { ...query }
    });
  }

  static getDetailedList(params) {
    return Request.client.get("/finances/history", { params: FinancesApi.prepareParams(params) });
  }

  static getFilters() {
    return Request.client.get("/finances/years");
  }

  static getFinancesByPlan() {
    return Request.client.get("/finances/byplane");
  }

  static requestStripeLink() {
    return Request.client.get("/finances/payment/link");
  }

  static deposit(amount) {
    // TODO: Need to rename endpoint: payout -> deposit
    return Request.client.post('/stripe/payout', { amount })
  }

  static paymentStatus(sessionId) {
    return Request.client.get(`/stripe/status/${sessionId}`)
  }

  static prepareParams(params) {
    return {
      filter: params.filter,
      tarif: params.tarif,
      timestamp__gte: params.timestamp__gte,
      timestamp__lte: params.timestamp__lte,
      __order: `${params.orderBy.value}+${params.orderBy.desc ? 'desc' : 'asc'}`,
      // __count: `${params.limit.count}`,
      // __offset: `${params.limit.offset}`
    };
  }


}
