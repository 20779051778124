<template>
  <div class="container">
    <popover :config="{ trigger: 'mouseenter', placement: 'top' }">
      <template #trigger><tag v-bind="{ value, color }" /> </template>

      <template #content>
        <div class="popover-content">{{ message }}</div>
      </template>
    </popover>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import { Popover } from "@moeco-platform/moeco-components";

const MESSAGES = {
  confirmed: {
    default: "Entity is confirmed",
  },

  unconfirmed: {
    default: "Entity is unconfirmed",
  },
};

export default {
  components: {
    Tag,
    Popover,
  },

  props: {
    confirmed: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isConfirmed() {
      return this.confirmed == 1;
    },

    message() {
      const key = this.isConfirmed ? "confirmed" : "unconfirmed";
      return MESSAGES[key].default;
    },

    value() {
      return this.isConfirmed ? "Confirmed" : "Unconfirmed";
    },

    color() {
      return this.isConfirmed ? `#37CB95` : `#DE3C63`;
    },
  },
};
</script>

<style lang="scss">
.container {
  position: relative;
  .popover-content {
    padding: 10px 16px;
  }
}
</style>