<template>
  <div class="blocks-grid" v-if="activity">
    <info-block borderless>
      <template #title>{{ activity.active }}</template>
      <template #descr>Active</template>
    </info-block>
    <info-block borderless tint="red">
      <template #title>{{ activity.inactive }}</template>
      <template #descr>Down</template>
    </info-block>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    const deviceGroupUid = this.$route.params.id;

    try {
      await this.getActivity({ id: deviceGroupUid });
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000
      });
    }
    
  },
  computed: {
    ...mapGetters({
      activity: "deviceGroupStat/activity/item"
    })
  },
  methods: {
    ...mapActions({
      getActivity: "deviceGroupStat/activity/getItem"
    })
  }
};
</script>

<style lang="scss">
</style>
