import GatewaysView from "@/views/Gateways";
import GatewayGroup from "@/views/Gateways/GatewayGroup/GatewayGroup";
import GatewayGroupCreate from "@/views/Gateways/GatewayGroup/GatewayGroupCreate";
import GatewayGroupGateways from "@/views/Gateways/GatewayGroup/segments/GatewaysSegment";
import GatewayGroupSettings from "@/views/Gateways/GatewayGroup/segments/SettingsSegment";

export default [
  {
    path: "/gateway_groups",
    name: "gateway-groups",
    meta: {
      title: "Gateways"
    },
    component: GatewaysView
  },
  {
    path: "/gateways/new",
    name: "gateway-group-create",
    meta: {
      title: "Gateways"
    },
    component: GatewayGroupCreate
  },
  {
    path: "/gateways/:id",
    name: "gateway-group",
    meta: {
      title: "Gateways"
    },
    component: GatewayGroup,
    props: true,
    children: [
      {
        path: "gateway",
        name: "gateway-group-gateways",
        meta: {
          title: "Gateways"
        },
        component: GatewayGroupGateways
      },
      {
        path: "settings",
        name: "gateway-group-settings",
        meta: {
          title: "Gateways"
        },
        component: GatewayGroupSettings
      }
    ]
  }
];
