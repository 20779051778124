<template>
  <values-block
    class="device-group-block"
    :title="title"
    :value="formattedValue"
    :tint="tint"
    :disabled="disabled"
  >
    <inline-chart class="inline-graph" v-bind="{deviceGroupUid}"/>
  </values-block>
</template>

<script>

import InlineChart from "@/components/Charts/InlineChart";

export default {
  components: {
    InlineChart
  },

  props: {
    deviceGroupUid: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    value: {
      type: Number,
      required: true
    },

    tint: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formattedValue() {
      return this.value.toLocaleString("en");
    }
  }
};
</script>

<style lang="scss" scoped>
.device-group-block {
  cursor: pointer;
  .inline-graph {
    width: 90px;
    height: 30px;
    position: absolute;
    right: 80px;
  }
}
</style>
