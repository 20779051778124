import GatewayGroupsApi from "@/api/gateway_groups";

export default {
  namespaced: true,

  state: {
    list: [],
    meta: {},
    loading: false,
    error: null,
    params: {
      orderBy: {
        value: 'name',
        desc: false
      }
    }
  },

  actions: {
    async getList(ctx) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);

      try {
        const { data, meta } = await GatewayGroupsApi.getList(
          ctx.getters.params
        );

        ctx.commit("setList", data);
        ctx.commit("setMeta", meta);
      } catch (error) {

        ctx.dispatch("errors/pushError", { id: "gateway_groups", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    changeParams(context, params) {
      if (params.orderBy) {
        if (context.state.params.orderBy.value === params.orderBy.value) {
          params.orderBy.desc = !context.state.params.orderBy.desc;
        } else {
          params.orderBy.desc = true;
        }
      }
      context.commit('changeParams', params);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      state.list = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data);
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    list: state => (state.list ? state.list : []),
    params: state => state.params
  }
};
