<template>
  <div class="gateways-section">
    <div class="stats">
      <info-block class="stats-block" orientation="horizontal">
        <template #title>{{ active }}</template>
        <template #descr>Active</template>
      </info-block>

      <info-block class="stats-block" orientation="horizontal">
        <template #title>{{ down }}</template>
        <template #descr>Down</template>
      </info-block>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    try {
      await this.fetchGateways();
    } catch (error) {
      this.$toast.error(error, {
        position: "top-left",
        duration: 6000
      });
    }
  },

  computed: {
    ...mapGetters({
      active: "dashboard/gateways/active",
      down: "dashboard/gateways/down",
      loading: "dashboard/gateways/loading"
    })
  },

  methods: {
    ...mapActions({
      fetchGateways: "dashboard/gateways/fetch"
    })
  }
};
</script>

<style lang="scss" scoped>
.gateways-section {
  display: flex;
  flex-direction: column;
  .stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }
}
</style>
