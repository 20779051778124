<template>
  <div class="dashboard-view">
    <moe-block class="item" compressed heading="Data">
      <data-section slot="content" />
      <router-link to="/data" slot="footer" class="more-button">
        <button-input :primary="false">View analitycs</button-input>
      </router-link>
    </moe-block>

    <moe-block class="item" compressed heading="Sensors">
      <devices-section slot="content" />
      <router-link to="/device_groups" slot="footer" class="more-button">
        <button-input :primary="false">Manage sensors</button-input>
      </router-link>
    </moe-block>

    <moe-block class="item" compressed heading="Finances">
      <finances-section slot="content" />
      <router-link to="/finances" slot="footer" class="more-button">
        <button-input :primary="false">View transactions</button-input>
      </router-link>
    </moe-block>

    <moe-block class="item" compressed paddingless heading="Gateways">
      <gateways-section slot="content" />
      <router-link to="/gateway_groups" slot="footer" class="more-button">
        <button-input :primary="false">Manage gateways</button-input>
      </router-link>
    </moe-block>
  </div>
</template>

<script>
import DataSection from "./views/DataSection";
import DevicesSection from "./views/DevicesSection";
import GatewaysSection from "./views/GatewaysSection";
import FinancesSection from "./views/FinancesSection";
// import { mapGetters } from "vuex";

export default {
  name: "dashboard-view",

  components: {
    DataSection,
    DevicesSection,
    GatewaysSection,
    FinancesSection
  }
};
</script>

<style lang="scss" scoped>
.dashboard-view {
  display: grid;
  grid-template: repeat(9, 1fr) / repeat(3, 1fr);
  grid-gap: 16px;
  height: 100%;

  .item {
    &:nth-child(1) {
      grid-row: 1 / 10;
    }

    &:nth-child(2) {
      grid-row: 1 / 10;
    }

    &:nth-child(3) {
      grid-row: 1 / 7;
    }

    &:nth-child(4) {
      grid-row: 7 / 10;
    }
  }

  .more-button {
    display: flex;
    flex: 1;
    text-decoration: none;

    > button {
      flex: 1;
    }
  }
}
</style>
