<template>
  <div
    class="calendar-day"
    :class="classes"
    @click.stop="select"
    @mouseenter="hover"
    @mouseleave="hoverOut"
  >
    <span
      :class="['day', date.format('dddd') === 'Sunday' ? 'sunday' : '']"
    >
      {{ date.date() }}
    </span>
  </div>
</template>

<script>
import Moment from "moment";

export default {
  props: {
    week: {
      type: Object,
      required: true
    },

    date: {
      type: Object,
      required: true
    },

    selected: {
      type: Object,
      default: null
    },

    highlighted: {
      type: Object,
      default: null
    },

    hidden: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isBothSelected() {
      return this.selected.start && this.selected.end;
    },

    isInRange() {
      return this.date.isBetween(this.selected.start, this.selected.end, "day");
    },

    isSelected() {
      return (
        this.selected &&
        (this.selected.start || this.selected.end) &&
        (this.date.isSame(this.selected.start, "day") ||
          this.date.isSame(this.selected.end, "day"))
      );
    },

    isHighlighted() {
      return (
        this.highlighted &&
        this.isSelected &&
        this.isBothSelected &&
        this.highlighted.isSame(this.date)
      );
    },

    isStart() {
      return this.date.isSame(this.selected.start, "day");
    },

    isEnd() {
      return this.date.isSame(this.selected.end, "day");
    },

    isFirstWeekday() {
      return this.date.weekday() === 0;
    },

    isLastWeekday() {
      return this.date.weekday() === 6;
    },

    isFirstDayOfMonth() {
      return this.date.isSame(Moment(this.date).startOf("month"), "day");
    },

    isLastDayOfMonth() {
      return this.date.isSame(Moment(this.date).endOf("month"), "day");
    },

    classes() {
      return {
        selected: this.isSelected,
        highlighted: this.isHighlighted,
        hasRange: this.isBothSelected,
        range: this.isInRange,
        hidden: this.hidden,
        start: this.isStart,
        end: this.isEnd,
        firstDay: this.isFirstDayOfMonth,
        lastDay: this.isLastDayOfMonth,
        firstWeekday: this.isFirstWeekday,
        lastWeekday: this.isLastWeekday
      };
    }
  },

  methods: {
    select() {
      this.$emit("select", this.date);
    },

    hover() {
      if (this.isBothSelected && this.isInRange) {
        this.$emit("hover", this.date);
      }
    },

    hoverOut() {
      if (this.highlighted && this.isInRange) {
        this.$emit("hoverOut", this.date);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$halfLeftGradient: linear-gradient(
  to right,
  transparent 45%,
  $grey50 50%,
  $grey50 100%
);

$halfRightGradient: linear-gradient(
  to left,
  transparent 45%,
  $grey50 50%,
  $grey50 100%
);

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 32px;
  height: 32px;

  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    &.sunday {
      color: $accentRed;
    }
  }

  &.selected {
    .day {
      background: $accentBlue;
      border-radius: 50%;
      color: $white;
      transition: $defaultTransition;
    }

    &.hasRange {
      &.start:not(.lastWeekday) {
        background: $halfLeftGradient;
      }

      &.end:not(.firstWeekday) {
        background: $halfRightGradient;
      }
      &.start.end {
        background: none;
      }
    }
  }

  &.range {
    background: $grey50;
  }

  &.highlighted {
    .day {
      background: lighten($color: $accentBlue, $amount: 10);
    }
  }

  &.firstWeekday,
  &.firstDay {
    &.range {
      background: $halfLeftGradient;

      .day {
        background: $grey50;
        border-radius: 50%;
      }
    }
  }

  &.lastWeekday,
  &.lastDay {
    &.range {
      background: $halfRightGradient;

      .day {
        background: $grey50;
        border-radius: 50%;
      }
    }
  }

  &.firstWeekday,
  &.lastWeekday {
    &.start {
      background: none;
    }

    &.end {
      background: none;
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
