<template>
  <div class="actions-container" v-if="edit">
    <div class="actions-group left">
      <plus-circle-icon class="add-button" @click.native="$emit('add')" />
      <span style="color: #555FB9;" @click="$emit('add')">Add characteristic</span>
    </div>

    <div class="actions-group right">
      <button-input class="action" :primary="false" @click.native="$emit('cancel')">
        <span>Cancel</span>
      </button-input>

      <button-input
        class="action"
        primary
        :disabled="!service.name"
        @click.native="$emit('update')"
      >
        <span>{{ (service.new) ? 'Create' : 'Save changes' }}</span>
      </button-input>
    </div>
  </div>
</template>

<script>
import PlusCircleIcon from "@/components/Icons/PlusCircle";
export default {
  components: { PlusCircleIcon },

  props: {
    service: {
      type: Object
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-container {
  height: 40px;
  margin-top: 16px;
  display: flex;

  .actions-group {
    margin-right: auto;

    .action:nth-of-type(2n) {
      margin-left: 5px;
    }

    &.right {
      margin-right: 0;
      display: flex;
    }

    &.left {
      display: flex;
      align-items: center;

      .add-button {
        cursor: pointer;
      }

      :nth-child(2) {
        margin-left: 5px;
      }
    }
  }
}
</style>