<template>
  <div class="devices-segment" v-if="deviceGroup">
    <welcome-screen
      v-if="!ifTableVisible"
      title="Add files"
      importType="json"
      :disabled="isDisabled"
      @import="onImport"
    />

    <div v-else class="devices-table-container">
      <devices-table-heading
        :showInteraction="hasInteraction"
        class="actions"
        @move="onMoveToDeviceGroup"
        @update="onUpdateToDevice"
      />

      <table-component
        class="devices-table"
        v-bind="table"
        @sort-by="onSort"
        @hit-bottom="onHitBottom"
      >
        <template #header-chosen>
          <checkbox-input v-model="allRows" @input="updateRows" />
        </template>
        <template #row-chosen="{ item }">
          <checkbox-input
            v-model="item.chosen"
            @input="updateAllRowsCheckbox"
            :disabled="isDisabled"
          />
        </template>
        <template #row-status="{ item }">
          <switch-input
            v-model="item.status"
            :disabled="isDisabled"
            @input="deviceStatusChanged(item)"
          />
        </template>
        <template #row-hash="{ item }">
          <span>{{ item.hash }}</span>
        </template>
        <template #row-updated_at="{ item }">
          <span style="color: #9c9eb1">{{ formatDate(item.updated_at) }}</span>
        </template>
        <template #row-created_at="{ item }">
          <span style="color: #9c9eb1">{{ formatDate(item.created_at) }}</span>
        </template>
        <template #row-battery_status="{ item }">
          <span style="color: #9c9eb1">{{
            formatBattery(item.battery_status)
          }}</span>
        </template>
      </table-component>
    </div>

    <!-- Opens when there are no devices  and we click "add sensors" at WelcomeScreen button-->
    <import-devices-popup v-bind="importData" @close="closeImportPopUp" />
  </div>
</template>

<script>
import Moment from "moment";
import { mapActions, mapGetters } from "vuex";
import DevicesTableHeading from "./DevicesTableHeading";
import { Table as TableComponent } from "@moeco-platform/moeco-components";
import WelcomeScreen from "@/components/Inputs/WelcomeScreen";
import ImportDevicesPopup from "@/components/Popups/ImportDevicesPopup";

import Joi from "joi-browser";

const devicesJoiSchema = Joi.array().items(
  Joi.object().keys({
    hash: Joi.string(),
    manufacturer: Joi.string(),
    cert: Joi.string(),
    status: Joi.number(),
    tarif: Joi.number(),
    type: Joi.number(),
  })
);

export default {
  components: {
    DevicesTableHeading,
    TableComponent,
    WelcomeScreen,
    ImportDevicesPopup,
  },

  data() {
    return {
      importData: {
        opened: false,
        devices: [],
      },

      table: {
        gridTemplate: ["40px", "3fr", "2fr", "2fr", "1fr", "40px"],
        columns: [
          { key: "chosen", type: "checkbox", sortable: false },
          {
            key: "hash",
            title: "Hash",
            type: "capitalized",
            sortable: true,
          },
          {
            key: "updated_at",
            title: "Update date",
            type: "datetime",
            sortable: true,
          },
          {
            key: "created_at",
            title: "Add date",
            type: "datetime",
            sortable: true,
          },
          {
            key: "battery_status",
            title: "Battery",
            type: "capitalized",
            sortable: true,
          },
          { key: "status", title: "Active", type: "switch", sortable: true },
        ],
        data: [],
      },
      allRows: false,
    };
  },

  watch: {
    list: {
      immediate: true,
      handler() {
        this.table.data = this.normalizeData(this.list);
      },
    },
  },

  computed: {
    ...mapGetters({
      list: "devices/list",
      params: "devices/params",
      loading: "devices/loading",
      deviceGroup: "deviceGroup/item",
      importPopUpShow: "devices/importPopUpShow",
    }),

    deviceGroupId() {
      return this.$route.params.id;
    },

    isDisabled() {
      // Disabled if not confirmed

      if (!this.deviceGroup) {
        return false;
      }

      return this.deviceGroup.confirmed;
    },

    ifTableVisible() {
      const paramsIsSetted =
        !!this.params.hash ||
        !!this.params.period_end ||
        !!this.params.period_start;

      return !(this.table.data.length == 0 && !this.loading) || paramsIsSetted;
    },

    hasInteraction() {
      return this.table.data.some((row) => row.chosen);
    },
  },

  async mounted() {
    this.changeParams({
      device_group__blockchain_uid: this.deviceGroupId,
    });
    this.resetLazyList();
    this.fetch();
  },

  methods: {
    ...mapActions({
      getList: "devices/getList",
      getLazyList: "devices/getLazyList",
      resetLazyList: "devices/resetLazyList",
      changeParams: "devices/changeParams",
      updateDevice: "device/updateItem",
      createDevice: "device/createItem",
      moveToDeviceGroup: "device/moveToDeviceGroup",
      updateDeviceDownlink: "device/updateDeviceDownlink",
    }),

    async fetch() {
      try {
        await this.getLazyList();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    async move({ devices, deviceGroup }) {
      try {
        await this.moveToDeviceGroup({ devices, deviceGroup });
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    async update(device) {
      try {
        await this.updateDevice(device);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    async updateDownlink({ devices, data }) {
      try {
        await this.updateDeviceDownlink({ devices, data });
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    normalizeData(list) {
      return list.map((item) => ({
        ...item,
        status: item.status == 1,
        chosen: false,
      }));
    },

    addBtnClicked() {
      this.$refs.importDevices.$refs.uploadFileInput.click();
    },

    onSort(value) {
      this.changeParams({ orderBy: { value } });
      this.resetLazyList();
      this.fetch();
    },

    deviceStatusChanged(row) {
      let device = JSON.parse(JSON.stringify(row));
      device.status = device.status ? 1 : 0;
      this.update(device);
    },

    // TODO: probably need to embed that functionality to table component
    updateRows() {
      this.table.data.forEach((row) => (row.chosen = this.allRows));
    },

    updateAllRowsCheckbox() {
      this.allRows = Object.values(this.table.data).every(
        (row) => row.chosen == true
      );
    },

    async onMoveToDeviceGroup(deviceGroup) {
      const devices = this.table.data.filter((item) => item.chosen);
      await this.move({ devices, deviceGroup });

      this.resetLazyList(); // <--- ?? need to test

      await this.fetch();
    },

    async onUpdateToDevice(data) {
      const devices = this.table.data
        .filter((item) => item.chosen)
        .map((item) => item.hash);

      await this.updateDownlink({ devices, data });

      this.resetLazyList(); // <--- ?? need to test

      await this.fetch();
    },

    onImport(devices) {
      Joi.validate(devices, devicesJoiSchema)
        .then((checkedDevices) => {
          this.importData.devices = checkedDevices;
          this.importData.opened = true;
        })
        .catch(() => {
          this.$toast.error("File format is wrong!");
        });
    },

    closeImportPopUp() {
      this.importData.devices = [];
      this.importData.opened = false;
      this.resetLazyList();
      this.fetch();
    },

    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    formatBattery(battery) {
      return battery ? `${battery}%` : "";
    },

    onHitBottom() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.devices-segment {
  position: relative;
  height: 100%;

  .devices-table-container {
    height: 100%;
    .devices-table {
      max-height: 90%;
      padding-right: 20px;
    }
  }

  .actions {
    position: sticky;
    top: -16px;
    background: $white;
    padding: 16px 16px 22px 16px;
    margin: -16px -16px 0 -16px;
    margin-bottom: 0px;
    z-index: 10;
  }
}
</style>
