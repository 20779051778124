<template>
  <div class="tags" v-if="characteristic">
    <multiselect
      class="characteristic-multiselect"
      v-if="edit"
      @input="tagsChanged"
      :value="characteristic.tags"
      :options="options"
      placeholder="Add properties"
      taggable
      multiple
    />

    <div v-else class="not-editable-tags">
      <div v-for="tag in characteristic.tags" :key="tag" class="tag">{{ tag }}</div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },

  props: {
    value: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      characteristic: JSON.parse(JSON.stringify(this.value)),

      options: [
        // "readable",
        // "writable",
        "notifiable",
        "mac"
        // "location",
        // "advdata",
        // "cypher_gps"
      ]
    };
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.characteristic = JSON.parse(JSON.stringify(this.value));
      }
    }
  },

  methods: {
    tagsChanged(tags) {
      this.$emit("input", tags);
    }
  }
};
</script>

<style lang="scss" scoped>
.tags {
  margin-top: 3px;
  background: $grey10;
  padding: 10px 16px 10px 13px;
  border-radius: 0 0 6px 6px;
  background: $grey10;
  display: flex;
  .not-editable-tags {
    display: flex;
    flex-wrap: wrap;

    .tag {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 36px;
      color: $white;
      background: $accentBlue;
      border-radius: 100px;
      margin-right: 8px;
    }
  }
}
</style>

<style lang="scss">
.characteristic {
  &-multiselect {
    max-height: fit-content;

    .multiselect__select {
    }

    .multiselect__tags {
      // max-height: 55px;
      // min-height: 55px;

      min-height: initial;
      max-height: initial;

      .multiselect__tag {
        vertical-align: center;
        height: 36px;
        padding-top: 10px;

        color: #474b6d;
        background: $white;
        border: 1px solid #dddff1;
        border-radius: 100px;

        .multiselect__tag-icon {
          line-height: 35px;
          border-radius: 100px;
          &::after {
            background: $white;
            color: #474b6d;
          }

          &:hover {
            background: $white;
            color: #474b6d;
          }
        }
      }
    }
  }
}
</style>
