import DevicesApi from "@/api/devices";
import DeviceModel from "@/store/models/device";
// import ResponseHandler from "@/helpers/responseHandler";

export default {
  namespaced: true,

  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async createItem(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        await DevicesApi.createItem(obj);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "device", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    async updateItem(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        const newItem = new DeviceModel(obj);
        await DevicesApi.updateItem(newItem.blockchain_uid, newItem.dto);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "device", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    async moveToDeviceGroup(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        const { devices, deviceGroup } = obj;
        for (let device of devices) {
          await DevicesApi.moveDevice({ device, deviceGroup });
        }
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "device", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    async updateDeviceDownlink(ctx, obj) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);
      try {
        const { devices, data } = obj;
        const payload =  {
          device_hashes: devices,
          command_type: "SEND_NEW_SETTINGS",
          ...data.freeCommand && { free_command: data.freeCommand },
          ...!data.freeCommand && { 
            params: {
              ...data.t1 && { t1: data.t1 },
              ...data.t2 && { t2: data.t2 },
            }
          }
        };
        await DevicesApi.updateDownlink(payload);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "device", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data;
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item
  }
};
