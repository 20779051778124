<template>
  <auth-block class="not-enough-permissions-view">
    <div class="hint">
      <h1>We're deeply sorry!</h1>
      <p>
        You are not authorised to view this resource.<br />
        We'll redirect you after 5 seconds.
      </p>
    </div>
  </auth-block>
</template>

<script>
import AuthBlock from "@/components/Blocks/AuthBlock";
import {mapGetters} from "vuex";
const REDIRECT_TIMEOUT = 5000;

export default {
  name: "not-enough-permissions-view",
  components: {
    AuthBlock,
  },
  mounted() {
    this.timeoutId = setTimeout(() => {
      window.location.href = `${process.env.VUE_APP_AUTH}?token=${this.token}`;
    }, REDIRECT_TIMEOUT)
  },
  methods: {
    onLogoutButtonClick: function() {
      clearTimeout(this.timeoutId);
      this.$router.push("logout");
    }
  },
  computed: {
    ...mapGetters({
      token: "auth/accessToken",
    }),
  },
  data() {
    return {
      timeoutId: null,
    };
  },
};
</script>

<style lang="scss" scoped>
  @mixin default-layout {
    width: 60%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

.not-enough-permissions-view {
  .hint {
    @include default-layout;
    align-items: center;
    text-align: center;

    h1 {
      @include h1;
      color: $notBlack;
      margin-top: 8px;
    }

    p {
      @include text-body;
      margin-top: 8px;
    }
  }
}
</style>
