<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 13.3333C25.3333 7.92102 22.3488 4.16459 18.4872 3.03687C18.3473 2.67507 18.1264 2.33872 17.826 2.05649C16.7996 1.09217 15.2004 1.09217 14.174 2.05649C13.8743 2.3381 13.6536 2.67359 13.5137 3.03448C9.64713 4.15729 6.66666 7.90551 6.66666 13.3333C6.66666 16.8451 5.95863 18.8129 4.64793 20.239C4.4288 20.4774 3.83511 21.0236 3.43292 21.3936L3.43286 21.3936L3.43285 21.3937L3.43283 21.3937C3.24257 21.5687 3.09518 21.7043 3.05061 21.7492L2.66666 22.1385V26.6854H10.784C11.0584 28.1014 11.8268 29.0488 13.2163 29.8967C14.9255 30.9398 17.0745 30.9398 18.7837 29.8967C20.01 29.1484 20.8322 27.9984 21.1658 26.6854H29.3333V22.1385L28.9494 21.7492C28.909 21.7082 28.7675 21.5777 28.5827 21.4072C28.1817 21.0373 27.5763 20.4789 27.3538 20.2358C26.0422 18.8031 25.3333 16.8328 25.3333 13.3333ZM18.3156 26.6854H13.5715C13.7599 27.0358 14.0809 27.3004 14.6053 27.6204C15.4617 28.143 16.5383 28.143 17.3946 27.6204C17.7912 27.3784 18.101 27.0582 18.3156 26.6854ZM25.3869 22.0365C25.6675 22.343 26.2688 22.9032 26.6667 23.2692V24.0187H5.33332V23.2697C5.72809 22.9081 6.33124 22.3483 6.6113 22.0435C8.39229 20.1058 9.33332 17.4903 9.33332 13.3333C9.33332 8.29311 12.4437 5.34976 16 5.34976C19.5463 5.34976 22.6667 8.3083 22.6667 13.3333C22.6667 17.477 23.608 20.0933 25.3869 22.0365Z" fill="#555FB9"/>
  </svg>
</template>

<script>
export default {
  name: "notificaitons-icon",
  props: {
    fill: {
      default: "#474B6D",
      type: String
    }
  }
};
</script>
