import Request from "@/helpers/request";
import Moment from "moment";

export default class DeviceGroupsApi {
  static getList(query) {
    return Request.client.get("/device_group", {
      params: DeviceGroupsApi.prepareParams(query)
    });
  }

  static getItem(id) {
    return Request.client.get(`/device_group/${id}`);
  }

  static getChart(id, CancelTokenSource) {
    const period = {
      from: Moment().subtract(1, 'month').startOf('day').toISOString(),
      to: Moment().toISOString()
    };

    let props = {
      params: { ...period, ...{ detalisation: 'week' } }
    };

    if (CancelTokenSource) {
      props.cancelToken = CancelTokenSource.token;
    }

    return Request.client.get(`/device_group/${id}/graph`, props);
  }

  static getChart2(params, CancelTokenSource) {
    let deviceGroupUid = params.id;

    delete params.id;

    let props = { params };

    if (CancelTokenSource) {
      props.cancelToken = CancelTokenSource.token;
    }

    return Request.client.get(`/device_group/${deviceGroupUid}/graph`, props);
  }

  static updateItem(id, data) {
    return Request.client.put(`/device_group/${id}`, data);
  }

  static createItem(data) {
    return Request.client.post(`/device_group`, {...data, status: 1});
  }

  static deleteItem(uid) {
    return Request.client.delete(`/device_group/${uid}`);
  }

  static prepareParams(params) {
    return {
      term: params.term,
      ...{
        // TODO: __order strange behavior; for example api `GET /finances` feeds + char, but here we need to use space
        // that will be url encoded into + sign; whhaat
        __order: `${params.orderBy.value} ${params.orderBy.desc ? 'desc' : 'asc'}`,
        __count: 100, // `${params.limit.count}`,
        // __offset: `${params.limit.offset}`
      },
    };
  }
}
