<template>
  <div class="service" v-if="localService">
    <div class="header">
      <header-actions v-bind="{service: localService, edit}" @remove="$emit('remove')" />
      <service-name v-model="localService.name" v-bind="{ edit, disableEditing }" @edit="onEdit" />
    </div>

    <div class="content">
      <characteristic
        v-for="(char, index) in uniqCharacteristics"
        :value="char.characteristic"
        :edit="edit"
        :key="char.uid"
        @input="characteristicChanged($event, index)"
        @remove="removeCharacteristic(index)"
      />

      <footer-actions
        v-bind="{service: localService, edit}"
        @update="updateService"
        @cancel="resetService"
        @add="addCharacteristic({index})"
      />
    </div>
  </div>
</template>

<script>
import HeaderActions from "./HeaderActions";
import ServiceName from "./ServiceName";
import Characteristic from "./Characteristic";
import FooterActions from "./FooterActions";

export default {
  components: { HeaderActions, ServiceName, Characteristic, FooterActions },

  props: {
    service: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    disableEditing: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      localService: JSON.parse(JSON.stringify(this.service)),
      oldService: JSON.parse(JSON.stringify(this.service)),
      edit: this.service.new ? true : false
    };
  },

  computed: {
    uniqCharacteristics() {
      return this.localService.characteristics.map(characteristic => {
        return {
          ...{
            uid: Math.random()
              .toString(36)
              .substring(7)
          },
          ...{ characteristic }
        };
      });
    }
  },
  
  methods: {
    onEdit() {
      this.edit = true;
      this.$emit("edit");
    },

    updateService() {
      try {
        this.validateCharacteristics();
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000
        });

        this.resetService();

        return;
      }

      if (this.localService.new) {
        delete this.localService.new;
      }

      this.$emit("input", {
        service: this.localService,
        index: this.index
      });

      this.edit = false;
      this.showUpdate = false;
    },

    resetService() {
      if (this.localService.new) {
        this.removeService();
        return;
      }

      this.$set(
        this.$data,
        "localService",
        JSON.parse(JSON.stringify(this.oldService))
      );

      this.edit = false;

      this.$emit("cancel");
    },

    removeService() {
      this.$emit("remove");
    },

    serviceChanged() {
      this.showUpdate = !this.$_.isEqual(this.service, this.oldService);
    },

    validateCharacteristics() {
      const list = this.localService.characteristics;

      for (let item of list) {
        if (!item.name) {
          throw new Error("Characteristic must have name");
        }
      }
    },

    addCharacteristic() {
      this.localService.characteristics.push({
        name: "",
        tags: []
      });
    },

    characteristicChanged(char, charIndex) {
      let characteristic = this.localService.characteristics[charIndex];
      this.$set(characteristic, "name", char.name);
      this.$set(characteristic, "tags", char.tags);
    },

    removeCharacteristic(charIndex) {
      this.localService.characteristics.splice(charIndex, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.service {
  background-color: white;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $grey50;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  .header {
  }

  .content {
    margin-top: 3px;
  }
}
</style>