<template>
  <div class="calendar">

    <div class="navigation">
      <arrow-down
        fill="currentColor"
        class="arrow arrow-left"
        @click.native="switchMonth(-1)"
      />
      <h2>{{ currentMonthName }} {{ currentYear }}</h2>
      <arrow-down
        fill="currentColor"
        class="arrow arrow-right"
        @click.native="switchMonth(1)"
      />
    </div>

    <div class="weekdays">
      <span v-for="day in weekdays" :key="day">{{ day }}</span>
    </div>

    <div class="weeks">
      <div v-for="week in weeks" :key="week.dayOfYear()" class="week">
        <day
          v-for="(day, idx) in daysInWeek(week)"
          :key="idx"
          :week="week"
          :date="getWeekdayDate(week, idx)"
          :selected="selected"
          :highlighted="highlighted"
          :hidden="!dayIsInCurrentMonth(getWeekdayDate(week, idx))"
          class="day"
          @select="daySelected"
          @hover="dayHovered"
          @hoverOut="hoverOut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from "@/components/Icons/ArrowDown";
import Day from "./Day";
import Moment from "moment";

export default {
  components: {
    ArrowDown,
    Day
  },

  props: {
    now: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selected: {
        start: null,
        end: null
      },
      hovered: null,
      highlighted: null
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
      }
    }
  },

  methods: {
    daySelected(date) {
      if (date.isSame(this.selected.start)) {
        this.selected.start = null;
      } else if (date.isSame(this.selected.end)) {
        this.selected.end = null;
      } else if (!this.selected.start) {
        this.selected.start = date;
      } else if (!this.selected.end) {
        this.selected.end = date;
      } else if (date.isBetween(this.selected.start, this.selected.end)) {
        const daysToStart = Math.abs(date.diff(this.selected.start));
        const daysToEnd = Math.abs(this.selected.end.diff(date));

        if (daysToStart > daysToEnd) {
          this.selected.end = date;
        } else {
          this.selected.start = date;
        }
      } else if (date.isBefore(this.selected.start)) {
        this.selected.start = date;
      } else if (date.isAfter(this.selected.end)) {
        this.selected.end = date;
      }

      this.fixDirection()

      this.$emit("input", { ...this.selected })
    },

    fixDirection() {
      if (
        this.selected.start &&
        this.selected.end &&
        this.selected.start.isAfter(this.selected.end)
      ) {
        const newStart = this.selected.end;
        this.selected.end = this.selected.start;
        this.selected.start = newStart;
      }
    },

    dayHovered(date) {
      this.hovered = date;

      if (date.isBetween(this.selected.start, this.selected.end)) {
        const daysToStart = Math.abs(date.diff(this.selected.start));
        const daysToEnd = Math.abs(this.selected.end.diff(date));

        this.highlighted =
          daysToStart <= daysToEnd ? this.selected.start : this.selected.end;
      }
    },

    dayIsInCurrentMonth(day) {
      return day.month() === this.now.month();
    },

    getWeekdayDate(week, dayIdx) {
      return Moment(week.weekday(dayIdx));
    },

    hoverOut() {
      this.highlighted = null;
    },

    switchMonth(value) {
      this.$emit("changeNow", Moment(this.now).add(value, "month"));
    },

    daysInWeek(week) {
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push(Moment(week).add(i, "days"));
      }
      return days;
    }
  },

  computed: {
    weekdays() {
      return Moment.weekdaysShort(true);
    },

    startOfMonth() {
      return Moment(this.now).startOf("month");
    },

    endOfMonth() {
      return Moment(this.now).endOf("month");
    },

    firstWeekMonday() {
      return Moment(this.startOfMonth).startOf("week");
    },

    lastWeekSunday() {
      return Moment(this.endOfMonth).endOf("week");
    },

    weekCount() {
      return Math.round(
        this.lastWeekSunday.diff(this.firstWeekMonday, "weeks", true)
      );
    },

    weeks() {
      let weeks = [];
      for (let i = 0; i < this.weekCount; i++) {
        weeks.push(Moment(this.startOfMonth).add(i, "weeks"));
      }
      return weeks;
    },

    currentMonthName() {
      return this.now.format("MMMM");
    },

    currentYear() {
      return this.now.year();
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  user-select: none;
  flex: 1;
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $notBlack;
    height: 24px;

    .arrow-left {
      transform: rotate(90deg);
    }

    h2 {
      @include h2;
    }

    .arrow-right {
      transform: rotate(-90deg);
    }

    .arrow {
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .weekdays {
    display: flex;
    justify-content: space-between;
    color: $grey70;
    margin: 16px 0px;

    span {
      @include text-tiny;
      flex: 1;
      text-align: center;
    }
  }

  .weeks {
    display: flex;
    flex-direction: column;
    .week {
      display: flex;
      justify-content: space-between;

      .day {
        cursor: pointer;
      }
    }
  }
}
</style>
