<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00008 15.3334C3.94999 15.3334 0.666748 12.0502 0.666748 8.00008C0.666748 3.94999 3.94999 0.666748 8.00008 0.666748C12.0502 0.666748 15.3334 3.94999 15.3334 8.00008C15.3334 12.0502 12.0502 15.3334 8.00008 15.3334ZM8.00008 14.0001C11.3138 14.0001 14.0001 11.3138 14.0001 8.00008C14.0001 4.68637 11.3138 2.00008 8.00008 2.00008C4.68637 2.00008 2.00008 4.68637 2.00008 8.00008C2.00008 11.3138 4.68637 14.0001 8.00008 14.0001ZM5.80479 11.1381L8.00006 8.94287L10.1953 11.1381L11.1381 10.1953L8.94287 8.00006L11.1381 5.80479L10.1953 4.86199L8.00006 7.05725L5.80479 4.86199L4.86199 5.80479L7.05725 8.00006L4.86199 10.1953L5.80479 11.1381Z" fill="#DE3C63"/>
  </svg>
</template>

<script>
export default {
  name: "remove-alt-icon",

  props: {
    fill: {
      type: String,
      default: "#474B6D"
    }
  }
};
</script>
