import Vue from "vue";
import Router from "vue-router";

import authRoutes from "./auth";
import dataRoutes from "./data";
import devicesRoutes from "./devices";
import financesRoutes from "./finances";
import settingsRoutes from "./settings";
import dashboardRoutes from "./dashboard";
import gatewaysRoutes from "./gateways";
// import teamRoutes from "./team";

Vue.use(Router);

const router = new Router({
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard"
    },
    ...authRoutes,
    ...dataRoutes,
    // ...teamRoutes,
    ...devicesRoutes,
    ...financesRoutes,
    ...settingsRoutes,
    ...dashboardRoutes,
    ...gatewaysRoutes,
  ]
});


import { AuthServer } from "@moeco-platform/clients";
import store from "@/store";

const authGuard = AuthServer.middlewares.authGuardBuilder(store);

router.beforeEach(authGuard)

export default router;
