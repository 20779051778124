<template>
  <popup
    class="add-card-popup"
    v-bind="$attrs"
    :width="480"
    @close="close"
    :disable-padding="true"
  >
    <div slot="header" class="header">
      <span class="title">Add new credit card</span>
      <cross-icon @click.native="close" />
    </div>

    <div slot="content">
      <div class="payment-gate-portal">
        <iframe :src="src"/>
      </div>
    </div>
  </popup>
</template>

<script>
import CrossIcon from "@/components/Icons/Cross";
// import VueFriendlyIframe from "vue-friendly-iframe";
import { mapGetters } from "vuex";

export default {
  name: "add-card-popup",
  components: {
    // VueFriendlyIframe,
    CrossIcon
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: "auth/data"
    }),
    src() {
      const gateUrl = process.env.VUE_APP_PAYMENT_GATE_URL;
      const eosName = this.userData.eos_name;
      return `${gateUrl}/card_form?eos_name=${eosName}`;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.payment-gate-portal {
  background: white;

  // .vue-friendly-iframe {
  //   display: flex;
  // }

  iframe {
    width: 100%;
    height: 555px;
  }
}
</style>

<style lang="scss" scoped>

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }
}

.form {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .credentials {
    @include text-body;
    display: flex;
    align-items: center;
    color: $grey70;

    .expires {
      margin-right: 8px;
    }

    .small-input {
      width: 75px;
      margin: 0 8px;
      flex-shrink: 0;
    }

    .security {
      margin-left: 16px;
      margin-right: 10px;
      flex-shrink: 0;
      flex-grow: 1;
      text-align: right;
    }

    .cvc {
      width: 60px;
    }
  }
}

.billing {
  display: flex;
  flex-direction: column;

  .billing-header {
    @include h2;
    color: $notBlack;
    margin: 24px 0 16px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      @include text-body;
      margin-left: 16px;
      color: $grey70;
    }
  }

  .billing-row {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 8px;
  }
}
</style>
