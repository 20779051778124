<template>
  <div class="item">
    <input :disabled="!edit" v-model="localValue" placeholder="Characteristic name" />
    <i class="button edit" @click="$emit('remove')" v-if="edit">
      <remove-alt />
    </i>
  </div>
</template>

<script>
import RemoveAlt from "@/components/Icons/RemoveAlt";

export default {
  components: { RemoveAlt },

  props: {
    value: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      localValue: this.value
    };
  },

  watch: {
    localValue: function() {
      this.$emit("input", this.localValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  padding-left: 13px;
  padding-right: 16px;
  border-radius: 6px 6px 0 0;
  background: $grey10;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.rounded {
    border-radius: 6px;
  }

  input {
    width: 100%;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: inherit;
    @include text-body;

    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
  }

  input:focus {
    outline: none;
  }

  .button {
    cursor: pointer;
  }
}
</style>
