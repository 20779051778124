function toFixed(value, precision = 0) {
  let stringValue = isNaN(+value) ? "0" : String(+value);

  if (
    stringValue.indexOf("e") > -1 ||
    stringValue === "Infinity" ||
    stringValue === "-Infinity"
  ) {
    throw new Error("To large number to be processed");
  }

  let [beforePoint, afterPoint] =
    stringValue.indexOf(".") > -1 ? stringValue.split(".") : [stringValue, ""];

  // Force automatic rounding for some long real numbers that ends with 99X, by converting it to string, cutting off last digit, then adding extra nines and casting it on number again
  // e.g. 2.0199999999999996: +('2.019999999999999' + '9999') will give 2.02
  if (
    stringValue.length >= 17 &&
    afterPoint.length > 2 &&
    +afterPoint.substr(afterPoint.length - 3) > 995
  ) {
    stringValue = String(
      +(
        stringValue.substr(0, afterPoint.length - 1) +
        "9".repeat(stringValue.split(".").shift().length + 4)
      )
    );
    [beforePoint, afterPoint] =
      String(stringValue).indexOf(".") > -1
        ? stringValue.split(".")
        : [stringValue, ""];
  }

  if (precision === 0) {
    return beforePoint;
  } else if (afterPoint.length > precision) {
    return `${beforePoint}.${afterPoint.substr(0, precision)}`;
  } else {
    return `${beforePoint}.${afterPoint}${"0".repeat(
      precision - afterPoint.length
    )}`;
  }
}

export { toFixed };
