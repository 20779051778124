export default class GatewayModel {
  constructor(obj) {
    this.id = null;
    this.name = null;
    this.group_uid = null;
    this.created_at = null;
    this.updated_at = null;
    this.blockchain_uid = null;
    this.pub_key = null;
    this.latitude = null;
    this.longitude = null;
    this.status = null;
    this.gateway_group = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      name: String(this.name),
      // blockchain_uid: String(this.blockchain_uid),
      // pub_key: String(this.pub_key),
      // latitude: Number(this.latitude),
      // longitude: Number(this.longitude),
      status: Number(this.status),
    };
  }
}
