<template>
  <div class="accordion">
    <div
      v-for="(item, indx) in localItems"
      v-bind:key="indx"
      class="accordion-item"
      :class="{expanded: item.expanded}"
    >
      <div class="accordion-header" @click="arrowClick(item, indx)">
        <div
          class="accordion-header-icon"
          :class="{expanded: item.expanded}"
        >
          <component v-bind:is="item.icon"></component>
        </div>

        <div class="accordion-header-text">
          <div class="accordion-header-text-title">
            <slot :name="`title-${item.name}`" :item="item">
              {{ getTitle(item) }}
            </slot>
          </div>

          <div class="accordion-header-text-subtitle">
            <slot :name="`sub-title-${item.name}`" :item="item">
              {{ getSubtitle(item) }}
            </slot>
          </div>
        </div>

        <div class="caret" :class="{expanded: (item.expanded)}">
          <ArrowDown/>
        </div>
      </div>

      <div
        class="accordion-content"
        :class="{
          expanded: item.expanded,
          scrolled: item.showScroll
        }"
        @click.self="arrowClick(item, indx)"
      >
        <div class='accordion-content-inner'>
          <slot :name="`content-${item.name}`" :item="item">
            {{ item.content }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from '@/components/Icons/ArrowDown';

export default {
  components: { ArrowDown },

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      localItems: []
    };
  },

  created() {
    for (let item of  this.items) {
      this.localItems.push({
        ...item,
        ...{ expanded: (item.expanded) ? true : false }
      })
    }
  },

  methods: {
    arrowClick(item/*, indx*/) {
      if (item.expanded) {
        item.expanded = false;
        return;
      }

      this.foldItems();
      item.expanded = true;
    },

    foldItems() {
      for (let item of this.localItems) {
        item.expanded = false;
      }
    },

    getTitle(item) {
      return (item.title) ? item.title : item.name;
    },

    getSubtitle(item) {
      return (item.subtitle) ? item.subtitle : this.getTitle(item);
    }
  }
}
</script>

<style lang="scss" scoped>
  $accordion-padding: 1rem;
  $expaned-accordion-color: white;
  $defaultTransitionTime: 200ms;

  .accordion {
    min-width: 1000px;
    max-width: 1000px;

    &-item {
      cursor: pointer;
      position: relative;

      transition: background-color $defaultTransitionTime ease-in-out;
      &.expanded {
        background-color: $expaned-accordion-color;
        box-shadow: 0px 1px 4px rgba(162, 164, 189, 0.2);
        border-radius: 4px;
      }
    }

    &-header {
      display: flex;
      align-items: center;

      padding: $accordion-padding;
      border-bottom: 1px solid $grey30;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 56px;
        height: 56px;

        box-shadow: 0px 1px 4px rgba(162, 164, 189, 0.2);

        margin-right: 16px;

        border-radius: 1000px;
        background-color: white;

        transition: background-color $defaultTransitionTime ease-in-out;
        &.expanded {
          background-color: $grey10;
          box-shadow: none;
        }
      }

      &-text {
        &-title {
          color: $notBlack;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }

        &-subtitle {
          color: $grey70;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .caret {
        margin-left:auto;
        cursor: pointer;
        transition: transform $defaultTransitionTime;
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    &-content {
      padding: 0 $accordion-padding;
      max-height: 0;
      overflow: hidden;
      transition: max-height $defaultTransitionTime ease-out;
      &.expanded {
        max-height: 500px;
      }

      &.scrolled {
        overflow-y: scroll;
      }

      &-inner {
        margin: 16px;
      }
    }
  }
</style>
