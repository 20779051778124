import DashboardApi from "@/api/dashboard";

export default {
  namespaced: true,

  state: {
    data: {},
    meta: {},
    loading: false,
    error: null,
    params: {
      device_group_uid: null,
      period: 'month'
    }
  },

  actions: {
    async fetch(ctx) {
      ctx.commit("setLoading", true);
      ctx.commit("setError", null);

      try {
        const {
          data,
          meta
        } = await DashboardApi.fetchFinances(ctx.getters.params);

        ctx.commit("setData", data);
        ctx.commit("setMeta", meta);
      } catch (error) {
        ctx.dispatch("errors/pushError", { id: "dashboard_finances", error }, { root: true });
        ctx.commit("setError", error);
        throw error;
      } finally {
        ctx.commit("setLoading", false);
      }
    },

    changeParams(ctx, data) {
      ctx.commit('changeParams', data);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setData(state, data) {
      state.data = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data);
    }
  },

  getters: {
    loading: state => state.loading,
    error: state => state.error,
    meta: state => state.meta,
    data: state => state.data,
    params: state => state.params
  }
};
