<template>
  <div class="balance-changes-container">
    <balance-changes-block class="balance-changes">
      <balance-changes-table />
    </balance-changes-block>
    <deposit-block class="deposit" />
    <month-spending-block class="month-spending" />
  </div>
</template>

<script>
import MonthSpendingBlock from "./blocks/MonthSpending";
import BalanceChangesBlock from "./blocks/BalanceChanges";
import DepositBlock from "./blocks/DepositBlock";

import BalanceChangesTable from "./tables/BalanceChanges";

export default {
  name: "balance-changes",

  components: {
    MonthSpendingBlock,
    BalanceChangesBlock,
    BalanceChangesTable,
    DepositBlock,
  },
};
</script>

<style lang="scss" scoped>
.balance-changes-container {
  height: 100%;

  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  grid-gap: 16px;

  .balance-changes {
    grid-row: 1/4;
    grid-column: 1/3;
  }

  .deposit {
    grid-row: 1/2;
    grid-column: 3/4;
  }

  .month-spending {
    grid-row: 2/4;
    grid-column: 3/4;
  }
}
</style>
