<template>
  <div class="services-segment">
    <welcome-screen
      v-if="services.length == 0 && !loading"
      title="Add files"
      altTitle="Add servcies manually"
      importType="json"
      @import="onImport"
      @alt-button-click="addService"
      :disabled="isDisabled"
    />

    <div v-if="services.length" class="content-wrapper">
      <service
        v-for="(s, index) in uniqServices"
        :disabled="isDisabled"
        :service="s.service"
        :disableEditing="s.service.disableEditing"
        :key="s.uid"
        :index="index"
        @input="onServiceChanged(s, index, $event)"
        @remove="remove(s, index)"
        @edit="onServiceEdit(s, index)"
        @cancel="onServiceCancel(s, index)"
      />
    </div>
  </div>
</template>

<script>
import Service from "./Service";
import WelcomeScreen from "@/components/Inputs/WelcomeScreen";
import { mapGetters, mapActions, mapMutations } from "vuex";

import Joi from "joi-browser";

const servicesSchema = Joi.array().items(
  Joi.object().keys({
    service: Joi.object().keys({
      name: Joi.string(),
      characteristics: Joi.array().items(
        Joi.object().keys({
          name: Joi.string(),
          tags: Joi.array().items(Joi.string()),
        })
      ),
    }),
  })
);

export default {
  components: { Service, WelcomeScreen },

  computed: {
    ...mapGetters({
      loading: "deviceGroup/loading",
      deviceGroup: "deviceGroup/item",
      services: "deviceGroup/alt_services",
    }),

    isDisabled() {
      if (!this.deviceGroup) {
        return false;
      }

      // Disabled if not confirmed
      return this.deviceGroup.confirmed;
    },

    uniqServices() {
      return this.services.map((service) => {
        return {
          ...{
            uid: Math.random().toString(36).substring(7),
          },
          ...{ service },
        };
      });
    },
  },

  methods: {
    ...mapActions({
      update: "deviceGroup/updateItem",
    }),

    ...mapMutations({
      enableServices: "deviceGroup/enableServices",
      disableServices: "deviceGroup/disableServices",
      setService: "deviceGroup/setService",
      addService: "deviceGroup/addService",
      pushService: "deviceGroup/pushService",
      removeService: "deviceGroup/removeService",
    }),

    async onServiceChanged(oldService, index, serviceData) {
      try {
        this.setService(serviceData, index);

        // TODO: convert alt_services into service
        await this.update(this.deviceGroup);
      } catch (error) {
        this.$toast.error(error, {
          position: "top-left",
          duration: 6000,
        });
      }
    },

    onServiceEdit(service, index) {
      this.disableServices({ service, index });
    },

    onServiceCancel(service, index) {
      this.enableServices({ service, index });
    },

    remove(s, index) {
      if (s.service.new == true) {
        this.removeService(index);
      } else {
        const result = confirm("Do you really want delete this service ?");

        if (result) {
          this.removeService(index);
          this.update(this.deviceGroup);
        }
      }

      this.enableServices({ service: s.service, index });
    },

    onImport(services) {
      Joi.validate(services, servicesSchema)
        .then((checkedServices) => {
          for (let serviceItem of checkedServices) {
            this.pushService(serviceItem.service);
          }

          // TODO: convert alt_services into services
          this.update(this.deviceGroup).then(() => {
            this.$toast.success("File has imported");
          });
        })
        .catch(() => {
          this.$toast.error("File format is wrong!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.services-segment {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .add-service {
    margin-bottom: 16px;
  }

  .service-group {
    margin-bottom: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid $grey50;
    }

    .service {
      margin-bottom: 24px;
    }

    .characteristic {
      margin-bottom: 16px;
    }
  }
}
</style>
