<template>
  <div class="finances-section">
    <DepositFunds v-bind="popup" @close="close" />

    <info-block class="balance-block">
      <template #title>$ {{ balance }}</template>
      <template #descr>Account Balance</template>

      <template #container>
        <!-- <button-input :primary="false" @click.native="onDepositClick">
          Deposit
        </button-input> -->

        <button-input
          class="deposit-button"
          :primary="false"
          @click.native="open"
        >
          Deposit
        </button-input>
      </template>
    </info-block>

    <heading-block title="Spending speed">
      <finances-chart :chartData="data[0] ? data[0].chart : []" />

      <div class="stats">
        <info-block class="stats-block">
          <template #title>$ {{ lastMonth }}</template>
          <template #descr>Last 30 days</template>
        </info-block>
        <info-block class="stats-block">
          <template #title>$ {{ lastWeek }}</template>
          <template #descr>Last 7 days</template>
        </info-block>
        <info-block class="stats-block" :tint="dueDays == 0 ? `red` : ``">
          <template #title>{{ dueDays }} days</template>
          <template #descr>Til deposit</template>
        </info-block>
      </div>
    </heading-block>
  </div>
</template>

<script>
import FinancesChart from "@/components/Charts/Finances";
import FinancesApi from "@/api/finances";
import { mapGetters, mapActions } from "vuex";
import DepositFunds from "../../Finances/blocks/DepositFunds";

export default {
  components: {
    FinancesChart,
    DepositFunds,
  },

  data() {
    return {
      popup: {
        opened: false,
      },

      lastMonth: 0,
      lastWeek: 0,
      today: 0,
      dueDays: 0,
      balance: 0,
      popup: {
        width: 800,
        opened: false,
        assignTo: null,
      },
    };
  },

  watch: {
    loading: function (newVal) {
      if (!newVal) {
        this.initData();
      }
    },
  },

  computed: {
    ...mapGetters({
      data: "dashboard/finances/data",
      loading: "dashboard/finances/loading",
    }),
  },

  async created() {
    this.changeParams({ period: "week", device_group_uid: null });

    try {
      await this.fetchFinances();
    } catch (error) {
      //  this.$toast.error(error, {
      //   position: "top-left",
      //   duration: 6000
      // });
    }
  },

  methods: {
    ...mapActions({
      fetchFinances: "dashboard/finances/fetch",
      changeParams: "dashboard/finances/changeParams",
    }),

    open() {
      this.popup.opened = true;
    },

    close() {
      this.popup.opened = false;
    },

    initData() {
      this.lastMonth = this.data[0]["last30Days"];
      this.lastMonth = this.lastMonth ? this.lastMonth : 0;

      this.lastWeek = this.data[0]["last7Days"];
      this.lastWeek = this.lastWeek ? this.lastWeek : 0;

      this.dueDays = this.data[0]["tilDeposit"];
      this.dueDays = this.dueDays ? this.dueDays : 0;

      this.balance = this.data[0].accountBalance;
      this.balance = this.balance ? this.balance : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.finances-section {
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  .balance-block {
    margin-bottom: 24px;
  }

  .bar-chart {
    height: 120px;
    margin-bottom: 24px;
  }

  .stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;

    .stats-block {
      height: 72px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
