<template>
  <ul class="applied-filters" v-if="data.length">
    <li v-for="opt in data" :key="opt.id">
      <span>{{ opt.name }}</span>
      <cross-icon @click.native.stop="disableFilter(opt)" class="cross-icon" />
    </li>
  </ul>
</template>

<script>
import CrossIcon from "@/components/Icons/Cross";

export default {
  name: "applied-filters",

  components: {
    CrossIcon
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    disableFilter(opt) {
      this.$emit("disable-filter", opt);
    }
  }
};
</script>

<style lang="scss" scoped>
.applied-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid $grey30;
    background: $white;
    padding: 6px 8px 6px 16px;
    border-radius: 56px;
    color: $notBlack;
    margin-right: 8px;
    margin-bottom: 8px;

    .cross-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>