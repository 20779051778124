<template>
  <table-component
    class="details-table"
    :columns="columns"
    :data="list"
    :gridTemplate="gridTemplate"
    @sort-by="onSort"
  >
    <template #row-type="{ item }">
      <component
        :is="item.type === 'spend' ? 'spending-icon' : 'deposit-icon'"
      />
    </template>

    <template #row-spent="{ item }">
      <span>{{
        item.spent ? parseFloat(item.spent).toFixed(2) + "$" : "-"
      }}</span>
    </template>

    <template #row-deposit="{ item }">
      <span>{{
        item.deposit ? parseFloat(item.deposit).toFixed(2) + "$" : "-"
      }}</span>
    </template>

    <template #row-tarif="{ item }">{{ getTarifRowContent(item) }}</template>

    <template #row-timestamp="{ item }">{{
      formatDate(item.timestamp)
    }}</template>

    <template #row-uid="{ item }">
      <a href="#">{{ item.uid }}</a>
    </template>

    <template #row-status>
      <span style="color: #37cb95">Completed</span>
    </template>
  </table-component>
</template>

<script>
import Moment from "moment";
import { mapGetters, mapActions } from "vuex";

import { Table as TableComponent } from "@moeco-platform/moeco-components";
import DepositIcon from "@/components/Icons/Deposit";
import SpendingIcon from "@/components/Icons/Spending";

export default {
  name: "balance-changes-table",
  components: {
    DepositIcon,
    SpendingIcon,
    TableComponent,
  },

  computed: {
    ...mapGetters({
      list: "financesDetails/list",
    }),
  },

  methods: {
    ...mapActions({
      getList: "financesDetails/getList",
      changeParams: "financesDetails/changeParams",
    }),

    onSort(value) {
      this.changeParams({
        orderBy: { value: this.prepareOrderBy(value) },
      });
      this.getList();
    },

    prepareOrderBy(value) {
      let fields = {
        timestamp: "timeStamp",
      };
      return fields[value] ? fields[value] : value;
    },

    formatDate(date) {
      return Moment(date).format("YYYY-MM-DD / HH:mm:ss");
    },

    getTariffName(tarif) {
      const plan = this.plans[tarif];
      return plan ? plan.name : "Tariff not specified";
    },

    getTarifRowContent(row) {
      return row.deposit ? "-" : this.getTariffName(row.tarif);
    },
  },

  data() {
    return {
      gridTemplate: ["1fr", "1fr", "1fr", "1fr", "2fr", "3fr", "2fr", "1fr"],
      columns: [
        { key: "type", title: "Type", sortable: true },
        { key: "reason", title: "Reason", sortable: true },
        { key: "spent", title: "Spent", sortable: true },
        { key: "deposit", title: "Deposited", sortable: true },
        { key: "tarif", title: "Plan", sortable: true },
        { key: "timestamp", title: "Time", sortable: true },
        { key: "uid", title: "ID", sortable: true },
        { key: "status", title: "Status", sortable: true },
      ],

      // TODO: move to one VUEX place
      plans: {
        TARIF_PER_TRX: { name: "Per transition" },
        TARIF_PER_SENSOR: { name: "Per sensor" },
        TARIF_PER_SENSOR_SMART: { name: "Per sensor smart" },
      },

      // TODO: move to one VUEX place
      tariffsTypes: {
        value: { name: "All plans", value: null },
        options: [
          { name: "All plans", value: null },
          { name: "Per transition", value: "TARIF_PER_TRX" },
          { name: "Per sensor", value: "TARIF_PER_SENSOR" },
          { name: "Per sensor smart", value: "TARIF_PER_SENSOR_SMART" },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.details-table {
  margin-top: 20px;
  background: $white;
  height: 85%;
  .spent {
    color: $accentRed;
  }

  .deposited {
    color: $accentGreen;
  }
}
</style>
